import _ from 'lodash';
import { notification } from 'antd';
import i18n from '../../../i18n/i18n';

export default class Toast {
    static errorProps = {
        message: i18n.t('toast.error.message'),
        description: i18n.t('toast.error.description')
    };

    static successProps = {
        message: i18n.t('toast.success.message')
    };

    static getPropsByType = (props, defaultProps) => {
        if (_.has(props, 'type')) {
            const message = i18n.t(`toast.${props.type}.message`) || defaultProps.message;
            const description = i18n.t(`toast.${props.type}.description`) || defaultProps.description;
            return { ...props, message, description };
        }
        return { ...defaultProps, ...props };
    };

    static error = props => {
        notification.error(Toast.getPropsByType(props, Toast.errorProps));
    };

    static success = props => {
        notification.success(Toast.getPropsByType(props, Toast.successProps));
    };
}