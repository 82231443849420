/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, colors, css, spacing, fonts, common } from '../../../../../styles';
import { components as Core } from '../../../../core';
import { Percentage } from './Percentage';

export const DashboardCard = ({
    count, prevCount, text, iconName, iconBackground, iconColor, withPercentages, isLoading,
    tooltipText, isReverse, showPrecetageValue, textClassName, renderValue
}) => {
    if (isLoading) {
        return (
            <div className={css(common.flexRowCenter, styles.spinner)}>
                <Core.Spinner />
            </div>
        );
    }
    return (
        <div className={css(styles.container)}>
            <div className={css(styles.card)}>
                <div className={css(styles.iconContainer)} style={{ background: iconBackground }}>
                    <Core.Icon type={iconName} color={iconColor} size={spacing.s3} />
                </div>
                <div className={css(styles.body)}>
                    <div className={css(styles.textContainer)}>
                        <p className={css(styles.text, textClassName)}>
                            {text}
                        </p>
                        {tooltipText && (
                            <Core.HelpTooltip
                                text={tooltipText}
                            />
                        )}
                    </div>
                    <p className={css(styles.count)}>{showPrecetageValue ? `${Math.trunc(count*100)}%` : (renderValue ? renderValue(count) : count)}</p>
                    {withPercentages ? <Percentage value1={count} value2={prevCount} isReverse={isReverse} /> : null}
                </div>
            </div>
        </div>
    );
};

DashboardCard.propTypes = {
    count: PropTypes.number.isRequired,
    prevCount: PropTypes.number,
    text: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    iconBackground: PropTypes.string.isRequired,
    withPercentages: PropTypes.bool,
    isLoading: PropTypes.bool,
    tooltipText: PropTypes.string.isRequired,
    isReverse: PropTypes.bool,
    showPrecetageValue: PropTypes.bool,
    textClassName: PropTypes.object,
    renderValue: PropTypes.func
};

DashboardCard.defaultProps = {
    prevCount: 0,
    withPercentages: false,
    isLoading: false,
    isReverse: false,
    showPrecetageValue: false,
    textClassName: undefined,
    renderValue: undefined
}

const styles = StyleSheet.create({
    container: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: '100%',
        '@media (max-width: 800px)': {
            paddingTop: spacing.s5,
            paddingBottom: spacing.s5
        }
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconContainer: {
        borderRadius: spacing.s9,
        minHeight: spacing.s9,
        minWidth: spacing.s9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: spacing.s1,
        marginBottom: spacing.s0
    },
    count: {
        ...fonts.xlBold,
        color: colors.black
    },
    body: {
        width: '80%'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        ...fonts.xsRegular,
        color: colors.textColor
    },
    spinner: {
        height: '100%'
    }
});