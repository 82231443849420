import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { translate } from "../../../core";
import { fonts, common, css, StyleSheet, spacing, colors } from "../../../../styles";
import Rating from './Rating';

const CommentItem = ({ i18n, comment, currentUser }) => (
    <div className={css(styles.commentInput)} >
        <div className={css(common.flexRow, styles.titleBlock)}>
            <span className={css(styles.title)}>{i18n.t('reviewDate')}</span>
            <span className={css(styles.title)}>{moment(comment.createdDate).format('L')}</span>
        </div>
        <div className={css(common.flexRow, styles.header)}>
            <div>
                <p className={css(common.flexRow, styles.comment)}>{currentUser.companyName}</p>
                <p className={css(common.flexRow, styles.date)}>{moment(comment.createdDate).fromNow()}</p>
            </div>

            <div>
                <Rating rating={comment.rating} />
                <p className={css(common.flexRow, styles.date)}>{currentUser.subscriptionType}</p>
            </div>
        </div>
        { comment.comment !== null && (
            <div className={css(styles.commentSection)}>
                <p className={css(common.flexRow, styles.comment)}>{i18n.t('comment')}</p>
                <p className={css(common.flexRow, styles.blockComment)} >{comment.comment}</p>
            </div>
        )}
        <div />
    </div>

);

export default (translate()(CommentItem));

CommentItem.propTypes = {
    i18n: PropTypes.object.isRequired,
    comment: PropTypes.string,
    currentUser: PropTypes.object
}

CommentItem.defaultProps = {
    comment: null,
    currentUser: null
};

const styles = StyleSheet.create({
    commentInput: {
        ':not(:last-child)': {
            borderBottom: `1px solid ${colors.border}`
        }    
    },
    message: {
        ...fonts.mdBold,
        marginTop: spacing.s2
    },
    header: {
        paddingBottom: spacing.s3
    },
    companyName: {
        ...fonts.smBold
    },
    blockComment: {
        ...fonts.smBold,
        display: 'block',
        backgroundColor: colors.greyLightOpacity,
        border: `1px solid ${colors.border}`,
        marginBottom: spacing.s3,
        padding: spacing.s3,
        width: spacing.s20,
        overflow: 'hidden',
    },
    title: {
        ...fonts.lgBold,
        marginTop: spacing.s3,
        paddingTop: spacing.s3,
        paddingRight: spacing.s1,
    },
    titleBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: spacing.s3,
        marginTop: spacing.s3,
    },
    comment: {
        ...fonts.smBold,
    },
    commentSection: {
        paddingBottom: spacing.s4
    },
    date: {
        marginBottom: spacing.s3,
    },
    passive: {
        color: colors.yellow
    },
    detractor: {
        color: colors.red
    },
    promoter: {
        color: colors.green
    },
});