import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as selectors from '../../selectors';
import { components as Core, useTranslation, COMPANY_THEME } from '../../../core';
import { css, common, StyleSheet, spacing, colors, fonts } from '../../../../styles';
import * as actions from "../../actions";
import PaletteColorPicker from './PaletteColorPicker';

const initialTheme = {
    [COMPANY_THEME.primary]: 'rgb(57, 168, 224)',
    [COMPANY_THEME.error]: 'rgb(255, 97, 55)',
    [COMPANY_THEME.warning]: 'rgb(255, 200, 55)',
    [COMPANY_THEME.success]: 'rgb(105, 231, 160)',
};

const CustomizeUIModal = ({ companyId, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const company = useSelector(state => selectors.getCompany(state, companyId));
    const isGettingCustomization = useSelector(state => selectors.isGettingCustomization(state, companyId));

    const [themeColors, setThemeColors] = useState(initialTheme);
    const [companyCaption, setCompanyCaption] = useState('');
    const [lightLogo, setLightLogo] = useState(null);
    const [darkLogo, setDarkLogo] = useState(null);
    const [miniLightLogo, setMiniLightLogo] = useState(null);
    const [miniDarkLogo, setMiniDarkLogo] = useState(null);

    useEffect(() => {
        dispatch(actions.getCompanyCustomizationUI(companyId));
    }, []);

    useEffect(() => {
        if (!isGettingCustomization) {
            if (company.theme) {
                setThemeColors(company.theme);
            }
            setLightLogo(company.lightLogoUri);
            setDarkLogo(company.darkLogoUri);
            setMiniLightLogo(company.lightMiniLogoUri);
            setMiniDarkLogo(company.darkMiniLogoUri);
            setCompanyCaption(company.caption || '');
        }
    }, [company]);

    const isChanged = useCallback(() => {
        return !_.isEqual(company.theme ? company.theme : {}, themeColors) ||
            ((company.caption || '') !== companyCaption && companyCaption.trim().length) ||
            lightLogo !== company.lightLogoUri ||
            darkLogo !== company.darkLogoUri ||
            miniLightLogo !== company.lightMiniLogoUri ||
            miniDarkLogo !== company.darkMiniLogoUri;
    }, [company, themeColors, companyCaption, lightLogo, darkLogo, miniLightLogo, miniDarkLogo]);

    const onSave = useCallback(() => {
        const data = {
            companyId: company.companyId,
            caption: companyCaption,
            theme: themeColors,
            companyLogos: company.companyLogos,
            lightLogoUri: lightLogo,
            darkLogoUri: darkLogo,
            lightMiniLogoUri: miniLightLogo,
            darkMiniLogoUri: miniDarkLogo
        };
        dispatch(actions.companyCustomizeUI(data));
    }, [company, themeColors, companyCaption, lightLogo, miniLightLogo, darkLogo, miniDarkLogo]);

    const renderLogoPicker = (headerText, imgUri, onChange, onDelete) => (
        <div className={css([common.flexRowStart, styles.mb1])}>
            <p className={css(styles.header)}>{headerText}</p>
            <Core.ImageUpload
                imageUrl={imgUri}
                onDelete={onDelete}
                onChange={onChange}
                isLoading={isGettingCustomization}
                width={220}
                height={50}
            />
        </div>
    );

    return (
        <Core.Modal
            isVisible={true}
            onClose={onClose}
            title={t('companies.customizeUI.customizeUI')}>
            <>
                <Core.LabeledInput
                    label={t('companies.customizeUI.caption')}
                    value={companyCaption}
                    onChange={value => setCompanyCaption(value)}
                />
                <PaletteColorPicker
                    themeColors={themeColors}
                    onChange={setThemeColors}
                />
                {renderLogoPicker(t(`companies.customizeUI.miniLogoLight`), miniLightLogo, setMiniLightLogo, () => setMiniLightLogo(null))}
                {renderLogoPicker(t(`companies.customizeUI.logoLight`), lightLogo, setLightLogo, () => setLightLogo(null))}
                {renderLogoPicker(t(`companies.customizeUI.miniLogoDark`), miniDarkLogo, setMiniDarkLogo, () => setMiniDarkLogo(null))}
                {renderLogoPicker(t(`companies.customizeUI.logoDark`), darkLogo, setDarkLogo, () => setDarkLogo(null))}
                <div className={css(common.flexRowEnd)}>
                    <Core.Button
                        label={t('save')}
                        onPress={onSave}
                        isLoading={isGettingCustomization}
                        isDisabled={!isChanged()}
                    />
                </div>
            </>
        </Core.Modal>
    );
}
CustomizeUIModal.propTypes = {
    companyId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CustomizeUIModal;

const styles = StyleSheet.create({
    header: {
        color: colors.primary,
        ...fonts.smBold,
        width: spacing.s10*2
    },
    mb1: {
        marginBottom: spacing.s1
    }
});