import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { StyleSheet, css, spacing, colors, fonts } from '../../../../styles';
import translate from '../../services/translate';

class LabeledSelect extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        className: PropTypes.object,
        isDisabled: PropTypes.bool,
        onChange: PropTypes.func,
        options: PropTypes.array.isRequired, //enum object name/value
        i18n: PropTypes.object.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //you can add more types if your options has another one
    };

    static defaultProps = {
        isDisabled: false,
        className: undefined,
        onChange: null,
        placeholder: '',
        value: undefined
    };

    checkValueExists = item => {
        const { value } = this.props;
        return item.value === value;
    };

    renderSubscriptionsOptions = item => {
        const { i18n } = this.props;
        return (
            <Select.Option value={item.value} key={item.value}>{i18n.t(item.text)}</Select.Option>
        );
    };

    render() {
        const { value, label, className, isDisabled, onChange, options, placeholder } = this.props;
        return (
            <div className={css(styles.container, className)}>
                <span className={css(styles.label)}>{label}</span>
                <Select
                    value={value !== null && value !== undefined && options.some(this.checkValueExists) ? value : undefined}
                    addonBefore={label}
                    disabled={isDisabled}
                    onChange={onChange}
                    className={css(styles.select)}
                    placeholder={placeholder}>
                    {options.map(this.renderSubscriptionsOptions)}
                </Select>
            </div>
        )
    }
}

export default translate()(LabeledSelect);

const styles = StyleSheet.create({
    container: {
        marginBottom: spacing.s2,
        width: '100%',
        display: 'flex'
    },
    label: {
        ...fonts.smNormal,
        lineHeight: `${fonts.smNormal.fontSize}px`,
        color: colors.inputAddonText,
        backgroundColor: colors.inputAddon,
        borderBottomLeftRadius: spacing.s0,
        borderTopLeftRadius: spacing.s0,
        border: `1px solid ${colors.border}`,
        padding: '8px 11px',
        whiteSpace: 'nowrap'
    },
    select: {
        width: '100%',
        '& .ant-select-selection': {
            borderColor: `${colors.border} !important`
        }
    }
});