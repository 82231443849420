import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors, css, fonts, spacing, StyleSheet } from '../../../../../styles';
import { components as Core, useTranslation, getPagination, getTableProps } from '../../../../core';
import { valueBuilder } from '../../../../companies';
import * as selectors from '../../../selectors';
import { getCancellations } from '../../../actions';
import { useSubscription } from '../../../hooks';

const COLUMNS = {
    companyEmail: 'companyEmail',
    subscriptionPlan: 'subscriptionPlan',
    cancellationDate: 'cancellationDate',
    comment: 'comment',
    reason: 'reason',
    region: 'region'
}

export const CancellationTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { getSubscriptionPlan } = useSubscription();

    const isLoading = useSelector(selectors.isLoadingCancellations);
    const data = useSelector(selectors.getCancellations);
    const pagination = useSelector(selectors.getCancellationsPagination);

    const [searchText, setSearchText] = useState('');

    const tableColumns = () => ([{
        title: t("companyEmail"),
        key: COLUMNS.companyEmail,
        dataIndex: COLUMNS.companyEmail
    }, {
        title: t("dashboard.cancellations.cancellationDate"),
        key: COLUMNS.cancellationDate,
        dataIndex: COLUMNS.cancellationDate,
        render: valueBuilder.getLocalFullTime
    }, {
        title: t("subscriptionPlan"),
        key: COLUMNS.subscriptionPlan,
        dataIndex: COLUMNS.subscriptionPlan,
        render: renderSubscription
    }, {
        title: t("dashboard.cancellations.comment"),
        key: COLUMNS.comment,
        dataIndex: COLUMNS.comment
    }, {
        title: t("dashboard.cancellations.reason"),
        key: COLUMNS.reason,
        dataIndex: COLUMNS.reason,
        render: renderReason
    }, {
        title: t("region"),
        key: COLUMNS.region,
        dataIndex: COLUMNS.region,
        render: renderRegion
    }]);

    const renderSubscription = (value, company) => {
        const result = getSubscriptionPlan(company.subscriptionPlanId);
        return result ? result.text : null;
    };

    const renderReason = value => {
        return t(`dashboard.cancellations.reasons.${value}`);
    };

    const renderRegion = value => t(`regionTypes.${value}`);

    const getPaginationForTable = () => getPagination(pagination);

    const onChangeTable = (pagination, filters, sorter) => {
        dispatch(getCancellations({
            ...getTableProps(pagination, filters, sorter),
            term: searchText
        }));
    };

    const onSearch = () => {
        dispatch(getCancellations({
            ...pagination,
            term: searchText
        }));
    }

    return (
        <Core.Table
            columns={tableColumns()}
            data={data}
            isLoading={isLoading}
            pagination={getPaginationForTable()}
            className={css(styles.table)}
            onChange={onChangeTable}
            hasSearch={true}
            searchText={searchText}
            onSearch={onSearch}
            onChangeSearchText={setSearchText}
        />
    );
};


const styles = StyleSheet.create({
    passive: {
        color: colors.yellow
    },
    detractor: {
        color: colors.red
    },
    promoter: {
        color: colors.green
    },
    table: {
        '& .ant-table-footer': {
            position: 'initial',
            border: 'none',
            padding: 0
        }
    },
    footer: {
        position: 'absolute',
        margin: `${spacing.s3}px 0`
    },
    footerIcon: {
        marginRight: spacing.s0
    },
    message: {
        ...fonts.mdBold,
        marginTop: spacing.s2
    },
    companyName: {
        ...fonts.smBold
    }
});