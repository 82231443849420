import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { StyleSheet, css, spacing, fonts, colors } from '../../../../styles';
import { DATE_FORMATS } from '../../constants';

class LabeledDatePicker extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        className: PropTypes.object,
        isDisabled: PropTypes.bool,
        onChange: PropTypes.func,
        hasCommonStyles: PropTypes.bool,
        disabledDate: PropTypes.func,
        showTime: PropTypes.bool
    };

    static defaultProps = {
        className: undefined,
        isDisabled: false,
        onChange: null,
        hasCommonStyles: true,
        disabledDate: null,
        showTime: false
    };

    onChangeDate = date => {
        if (this.props.onChange) {
            this.props.onChange(
                date ?
                    moment(date).format(this.props.showTime ?
                        DATE_FORMATS.DATE_TIME :
                        DATE_FORMATS.DATE_ANT) :
                    date
            );
        }
    };

    render() {
        const { value, label, className, isDisabled, hasCommonStyles, disabledDate, showTime } = this.props;
        return (
            <div className={css(styles.container, className)}>
                <span className={css(hasCommonStyles ? styles.label : styles.customLabel)}>{label}</span>
                <DatePicker
                    value={value ? moment(value) : value}
                    showTime={showTime}
                    className={css(hasCommonStyles ? styles.datePicker : undefined)}
                    addonBefore={label}
                    disabled={isDisabled}
                    onChange={this.onChangeDate}
                    showToday={false}
                    format={showTime ? DATE_FORMATS.DATE_TIME : DATE_FORMATS.DATE_ANT}
                    disabledDate={disabledDate}
                />
            </div>
        );
    }
}

export default LabeledDatePicker;

const styles = StyleSheet.create({
    container: {
        marginBottom: spacing.s2,
        width: '100%',
        display: 'flex'
    },
    label: {
        ...fonts.smNormal,
        lineHeight: `${fonts.smNormal.fontSize}px`,
        color: colors.inputAddonText,
        backgroundColor: colors.inputAddon,
        borderBottomLeftRadius: spacing.s0,
        borderTopLeftRadius: spacing.s0,
        border: `1px solid ${colors.border}`,
        padding: '8px 11px',    //padding of input addon
        whiteSpace: 'nowrap'
    },
    customLabel: {
        ...fonts.mdNormal,
        color: colors.primary,
        marginTop: spacing.s0,
        marginRight: spacing.s3
    },
    datePicker: {
        width: '100%',
        '& .ant-calendar-picker-input': {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderLeft: 'none',
            borderColor: `${colors.border} !important`
        }
    }
});