import moment from 'moment';
import * as actionTypes from './actionTypes';
import * as api from './api';
import * as selectors from './selectors';
import { Toast, normalize, downloadFile } from '../core';

export const changeFiltersDateRange = (startDate, endDate) => dispatch => {
    dispatch({
        type: actionTypes.CAHNGE_FILTERS_DATE_RANGE,
        payload: {
            startDate,
            endDate
        }
    });
};

export const changeRegionFilter = region => dispatch => {
    dispatch({
        type: actionTypes.CHANGE_REGION_FILTER,
        payload: {
            region
        }
    });
};

export const getOverview = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_OVERVIEW_STATS.REQUEST });
    const filters = selectors.getFilters(getState());
    return api.getOverview(filters)
        .then(response => {
            dispatch({
                type: actionTypes.GET_OVERVIEW_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_OVERVIEW_STATS.ERROR });
        });
};

export const getRegistrations = (pagination = {}) => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_NEW_COMPANIES.REQUEST });
    const adminDashboardFilters = selectors.getFilters(getState());
    return api.getRegistrations({ adminDashboardFilters, ...pagination })
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_NEW_COMPANIES.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data)
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_NEW_COMPANIES.ERROR });
        });
};

export const downloadRegistrationsReport = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.DOWNLOAD_REPORT.REQUEST });
    const filters = selectors.getFilters(getState());
    return api.downloadRegistrationsReport(filters)
        .then(response => {
            dispatch({ type: actionTypes.DOWNLOAD_REPORT.SUCCESS });
            downloadFile(response.data, `New_Companies_Report_${moment().utc()}.csv`)
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.DOWNLOAD_REPORT.ERROR });
        });
};

export const getRegistrationsChartData = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_NEW_COMPANIES_CHART_DATA.REQUEST });
    const filters = selectors.getFilters(getState());
    return api.getRegistrationsChartData(filters)
        .then(response => {
            dispatch({
                type: actionTypes.GET_NEW_COMPANIES_CHART_DATA.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_NEW_COMPANIES_CHART_DATA.ERROR });
        });
};

// Usage Report

export const getUsageReportData = (pagination = {}) => (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_USAGE_REPORT_DATA.REQUEST });
    return api.getUsageReportData(pagination)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_USAGE_REPORT_DATA.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data)
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_USAGE_REPORT_DATA.ERROR });
        });
};