import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import RichTextEditorBase from './RichTextEditorBase';
import { translate } from '../../services';
import { colors, css, StyleSheet } from "../../../../styles";

class RichTextEditor extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        // valueToUpdate is not current editor state, valueToUpdate is new editor state created by side effects (select templates and etc)
        valueToUpdate: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.editorRef = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valueToUpdate !== this.props.valueToUpdate && this.editorRef && this.editorRef.model) {
            this.editorRef.setData(this.props.valueToUpdate || '');
        }
    }

    onReadyEditor = editor => {
        editor.focus();
        this.editorRef = editor;
        this.editorRef.setData(this.props.valueToUpdate || '');
    }

    handleChange = (event, editor) => {
        const data = editor.getData();
        this.props.onChange(data);
    };

    render() {
        const { i18n } = this.props;
        return (
            //there is a common bug with quill editor that text is deleted when browser translation is enabled
            //"notranslate" class allows not to translate editor's content and this should work fine
            <div className={`notranslate ${css(styles.main)}`}>
                <CKEditor
                    editor={RichTextEditorBase}
                    config={{
                        placeholder: i18n.t('writeYourMessage'),
                        ui: {
                            width: '100%',
                            height: '100%'
                        }
                    }}
                    onReady={this.onReadyEditor}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default translate()(RichTextEditor);

const styles = StyleSheet.create({
    main: {
        overflow: 'hidden',
        width: '100%',
        color: colors.text,
        '& .ck-content, .ck-source-editing-area': {
            minHeight: 280
        },
        '& .ck-content, .ck-source-editing-area, .ck-source-editing-area>textarea': {
            borderColor: `${colors.greyLight} !important`,
            boxShadow: 'none !important'
        },
        '& .ck-source-editing-area': {
            wordBreak: 'break-all'
        },
        '& .ck-source-editing-area>textarea': {
            backgroundColor: `${colors.white} !important`
        },
        '& .ck-list': {
            maxHeight: 200,
            overflowY: 'auto'
        }
    }
});