import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col } from "antd";
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { components as Core, translate, getTableProps, DATE_FORMATS, PERIODS, INTEGRATION_TYPES } from '../../../core';
import IntegrationsTable from '../IntegrationsTable';
import { AllIntegrationsDetailsModal } from '../IntegrationDetailsModal';
import { StyleSheet, css, spacing, common } from '../../../../styles';

const INTEGRATION_STATUSES = {
    all: 0,
    notBlocked: 1,
    blocked: 2
};

class AllIntegrations extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        integrations: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        integrations: [],
        isLoading: false
    };

    constructor(props) {
        super(props);

        this.state = {
            integrationType: INTEGRATION_TYPES.marketplace,
            integrationStatus: INTEGRATION_STATUSES.all,
            modalIntegrationId: null,
            searchText: '',
            period: PERIODS.allTime,
            startDate: moment().subtract(90, 'days'),
            endDate: moment(),
            integrations: []
        }
        this.props.actions.getAllIntegrations(this.formattedFilters);
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getAllIntegrations({
            ...getTableProps(pagination, filters, sorter),
            ...this.formattedFilters
        });
    };

    setPrioritySync = integration => {
        this.props.actions.setPrioritySync(integration.id, integration.companyId);
    }

    onChangeIntegrationType = value => {
        this.setState({ integrationType: value, integrations: [] }, this.onLoadIntegrations);
    };

    onChangeIntegrationStatus = value => {
        this.setState({ integrationStatus: value }, this.onLoadIntegrations);
    };

    onChangeIntegrationFilter = integrations => this.setState({ integrations }, this.onLoadIntegrations);

    onLoadIntegrations = () => {
        this.props.actions.getAllIntegrations(this.formattedFilters);
    }

    onDateFilterChange = (startDate, endDate, period) => {
        this.setState({ startDate, endDate, period }, this.onLoadIntegrations);
    }

    onOpenIntegrationDetailsModal = integration => {
        if (integration.id !== this.state.modalIntegrationId) {
            this.setState({ modalIntegrationId: integration.id });
        }
    };

    onCloseIntegrationDetailsModal = () => {
        this.setState({ modalIntegrationId: null });
    };

    get integrationTypes() {
        const { i18n } = this.props;
        return [{
            name: i18n.t('integrations.types.marketplace'),
            id: INTEGRATION_TYPES.marketplace
        }, {
            name: i18n.t('integrations.types.instantMessages'),
            id: INTEGRATION_TYPES.instantMessages
        }, {
            name: i18n.t('integrations.types.all'),
            id: INTEGRATION_TYPES.all
        }]
    }

    get integrationStatuses() {
        const { i18n } = this.props;
        return [{
            name: i18n.t('integrations.statuses.all'),
            id: INTEGRATION_STATUSES.all
        }, {
            name: i18n.t('integrations.statuses.notBlocked'),
            id: INTEGRATION_STATUSES.notBlocked
        }, {
            name: i18n.t('integrations.statuses.blocked'),
            id: INTEGRATION_STATUSES.blocked
        }]
    }

    get formattedFilters() {
        const { startDate, endDate, period, searchText, integrationType, integrationStatus, integrations } = this.state;
        let filters = {
            term: searchText,
            type: integrationType,
            status: integrationStatus,
            integrations
        };
        if (startDate && endDate && period !== PERIODS.allTime) {
            filters = {
                ...filters,
                from: moment(startDate).startOf('day').format(DATE_FORMATS.UTC),
                to: moment(endDate).endOf('day').format(DATE_FORMATS.UTC)
            };
        }
        return filters;
    }

    get renderCustomFilters() {
        const { integrations, integrationType } = this.state;
        return (
            <div>
                <Col sm={8} md={6} xl={4} className={css(styles.filterWrapper)}>
                    <Core.Select
                        data={this.integrationTypes}
                        onChange={this.onChangeIntegrationType}
                        defaultValue={INTEGRATION_TYPES.marketplace}
                    />
                </Col>
                <Col sm={10} md={8} xl={6} className={css(styles.filterWrapper)}>
                    <Core.IntegrationFilter
                        selected={integrations}
                        onChange={this.onChangeIntegrationFilter}
                        integrationType={integrationType}
                    />
                </Col>
                <Col sm={8} md={6} xl={4} className={css(styles.filterWrapper)}>
                    <Core.Select
                        data={this.integrationStatuses}
                        onChange={this.onChangeIntegrationStatus}
                        defaultValue={INTEGRATION_STATUSES.all}
                    />
                </Col>
                <Col sm={10} md={8} xl={6} className={css([common.flexRowStart, styles.filterWrapper, styles.integarationFilter])}>
                    <p>{this.props.i18n.t("lastSync")}</p>
                    <Core.DateFilter
                        onChange={this.onDateFilterChange}
                        defaultPeriod={PERIODS.allTime}
                        className={styles.dateFilter}
                    />
                </Col>
            </div>
        )
    }

    onSearch = () => {
        this.onLoadIntegrations();
    };

    onChangeSearchText = searchText => this.setState({ searchText });

    render() {
        const { isLoading, integrations, i18n } = this.props;
        const { modalIntegrationId } = this.state;
        const companyId = _.get(_.find(integrations, ['id', modalIntegrationId]), 'companyId', null);
        return (
            <div>
                <IntegrationsTable
                    title={i18n.t('integrations.allIntegrations')}
                    integrations={integrations}
                    isLoading={isLoading}
                    pagination={this.props.pagination}
                    setPrioritySync={this.setPrioritySync}
                    onChangeTable={this.onChangeTable}
                    customFilters={this.renderCustomFilters}
                    searchText={this.state.searchText}
                    onChangeSearchText={this.onChangeSearchText}
                    onPressRow={this.onOpenIntegrationDetailsModal}
                    onSearch={this.onSearch}
                />
                {modalIntegrationId && companyId ? (
                    <AllIntegrationsDetailsModal
                        companyId={companyId}
                        integrationId={modalIntegrationId}
                        onClose={this.onCloseIntegrationDetailsModal}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    integrations: selectors.getAllIntegrations(state),
    pagination: selectors.getAllIntegrationsPagination(state),
    isLoading: selectors.isGettingAllIntegrations(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AllIntegrations));

const styles = StyleSheet.create({
    filterWrapper: {
        marginTop: spacing.s0,
        marginLeft: spacing.s2
    },
    dateFilter: {
        flex: 1,
        marginLeft: spacing.s2
    },
    integarationFilter: {
        '@media (min-width: 1200px)': {
            marginLeft: 0
        }
    }
});