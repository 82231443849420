import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { StyleSheet, colors, css, spacing } from '../../../../../styles';
import { DashboardCard } from '../DashboardCard';

export const InlineDashboardCards = ({
    items, gutter, className, hasDashboardItemStyle, beforeComponent, span,
    isLoading, additionalItems, cardTextClassName
}) => {
    const isShowPercentages = items => !items.every(item => item.prevCount === 0);

    const renderCard = (item, index) => (!item.isHidden ? (
        <Col key={item.text} span={span} className={css(index < items.length - 1 ? styles.dashboardRightBorder : undefined)}>
            <DashboardCard
                {...item}
                isLoading={isLoading}
                withPercentages={isShowPercentages([...items, ...additionalItems])}
                textClassName={cardTextClassName}
            />
        </Col>
    ) : null);

    return (
        <Row
            type="flex"
            gutter={gutter}
            className={css(hasDashboardItemStyle ? styles.dashboardItem : undefined, styles.container, className)}>
            {beforeComponent}
            {items.map(renderCard)}
        </Row>
    );
};

InlineDashboardCards.propTypes = {
    items: PropTypes.array,
    additionalItems: PropTypes.array,
    span: PropTypes.number.isRequired,
    gutter: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    isLoading: PropTypes.bool,
    beforeComponent: PropTypes.node,
    hasDashboardItemStyle: PropTypes.bool,
    className: PropTypes.object,
    cardTextClassName: PropTypes.object
};

InlineDashboardCards.defaultProps = {
    items: [],
    additionalItems: [],
    gutter: spacing.s3,
    isLoading: false,
    beforeComponent: null,
    hasDashboardItemStyle: true,
    className: undefined,
    cardTextClassName: undefined
};

const styles = StyleSheet.create({
    container: {
        margin: '0 !important'
    },
    dashboardItem: {
        backgroundColor: colors.white,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
        overflow: 'hidden',
        height: '100%'
    },
    shadowItem: {
        backgroundColor: colors.white,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
    },
    dashboardRightBorder: {
        borderRight: `1px solid ${colors.background}`
    }
});