import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import { StyleSheet, css, spacing, fonts, colors } from '../../../../styles';

const INPUT_TYPES = {
    text: 'text',
    password: 'password'
};

class SearchInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        isPassword: PropTypes.bool,
        onPressEnter: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        placeholder: PropTypes.string
    };

    static defaultProps = {
        isPassword: false,
        onPressEnter: null,
        placeholder: undefined
    };

    get type() {
        return this.props.isPassword ? INPUT_TYPES.password : INPUT_TYPES.text;
    }

    onChange = event => {
        this.props.onChange(event.target.value);
    };

    render() {
        const { value, onPressEnter, placeholder, onSearch } = this.props;
        return (
            <div className={css(styles.container)}>
                <AntdInput.Search
                    value={value}
                    onPressEnter={onPressEnter}
                    onChange={this.onChange}
                    onSearch={onSearch}
                    enterButton={true}
                    className={css(styles.input)}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default SearchInput;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginTop: spacing.s1
    },
    input: {
        ...fonts.mdLight,
        height: 'auto !important',
        color: colors.black,
        backgroundColor: colors.white,
        '& .ant-input': {
            borderColor: colors.border,
            ':hover': {
                borderColor: colors.primary
            },
            ':focus': {
                borderColor: colors.primary
            },
            ':active': {
                borderColor: colors.primary
            }
        }
    },
});