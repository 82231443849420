import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../../core";
import { css, StyleSheet, colors } from "../../../../styles";

const Rating = ({ rating, i18n }) => {
    const getRating = rating => {
        let label;
        let style;
        if (rating < 7) {
            label = i18n.t('feedback.rate.detractor');
            style = styles.detractor;
        } else if (rating > 8) {
            label = i18n.t('feedback.rate.promoter');
            style = styles.promoter;
        } else {
            label = i18n.t('feedback.rate.passive');
            style = styles.passive;
        }

        return {
            label,
            style
        }
    };

    const renderRating = rating => {
        const { label, style } = getRating(rating);
        return (
            <p className={css(style)}>
                {label}
                {' - '}
                {rating}
            </p>
        )
    };

    return (
        renderRating(rating)
    );
}

export default (translate()(Rating));

Rating.propTypes = {
    i18n: PropTypes.object.isRequired,
    rating: PropTypes.element.isRequired,
}


const styles = StyleSheet.create({
    passive: {
        color: colors.yellow
    },
    detractor: {
        color: colors.red
    },
    promoter: {
        color: colors.green
    }

});