import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, components as Core, ICONS, MARKETPLACES_WITH_RELOGIN } from '../../../core';
import { common, css, StyleSheet, spacing, colors, marginsBottom, marginsRight } from '../../../../styles';
import * as actions from "../../actions";
import * as selectors from '../../selectors';
import { ButtonWithPopover } from './ButtonWithPopover';
import { Tooltip } from '../../../core/components';

export const AccountStatusButtons = ({ account, companyId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isBlocking = useSelector(state => selectors.isBlockingIntegration(state, account.id));
    const isUnblocking = useSelector(state => selectors.isUnblockingIntegration(state, account.id));
    const isSettingRelogin = useSelector(state => selectors.isSettingReloginIntegration(state, account.id));
    const isTogglingExceededLimitBlockedIntegration = useSelector(state => selectors.isTogglingExceededLimitBlockedIntegration(state, account.id));

    const [blockReason, setBlockReason] = useState('');

    const onUnblock = () => {
        dispatch(actions.unblockIntegration(
            companyId,
            account.id
        ));
    };

    const onBlock = notifyAboutBlocking => {
        dispatch(actions.blockIntegration({
            companyId,
            integrationId: account.id,
            blockReason,
            notifyAboutBlocking
        }));
    };

    const renderBlockButtons = () => {
        if (account.isExceededLimitBlocked) return null;
        return (
            <div className={css([common.flexRowEnd, marginsBottom.s3])}>
                {account.isBlocked && !account.isDeleting ? (
                    <Core.Button
                        label={t('integrations.unblock')}
                        onPress={onUnblock}
                        isLoading={isUnblocking}
                    />
                ) : null}
                {!account.isBlocked ? (
                    <div className={css(common.flexRow)}>
                        <div className={css([common.flexRow, marginsRight.s2])}>
                            <Core.Input
                                placeholder={t('integrations.blockReason')}
                                value={blockReason}
                                onChange={setBlockReason}
                                className={styles.blockReasonInput}
                                hasMargins={false}
                            />
                        </div>
                        <ButtonWithPopover
                            label={t('integrations.block')}
                            onPress={onBlock}
                            isLoading={isBlocking}
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    const onUnblockMarketSync = () => {
        dispatch(actions.unblockMarketSyncIntegration(
            companyId,
            account.id
        ));
    };

    const onBlockMarketSync = notifyAboutBlocking => {
        dispatch(actions.blockMarketSyncIntegration({
            companyId,
            integrationId: account.id,
            notifyAboutBlocking
        }));
    };

    const renderBlockMarketSyncButtons = () => {
        if (account.isExceededLimitBlocked) return null;
        return (
            <div className={css([common.flexRowEnd, marginsBottom.s3])}>
                {account.blockedMarketSync && !account.needRelogin && !account.isDeleting ? (
                    <Core.Button
                        label={t('integrations.unblockMarketSync')}
                        onPress={onUnblockMarketSync}
                        isLoading={isUnblocking}
                    />
                ) : null}
                {!account.blockedMarketSync ? (
                    <ButtonWithPopover
                        label={t('integrations.blockMarketSync')}
                        onPress={onBlockMarketSync}
                        isLoading={isBlocking}
                    />
                ) : null}
            </div>
        )
    };

    const onToggleExceededLimitBlockedIntegration = () => {
        dispatch(actions.toggleExceededLimitBlockedIntegration(
            companyId,
            account.id
        ));
    };

    const renderExceededLimitBlockButtons = () => (
        <div className={css([common.flexRowEnd, marginsBottom.s3])}>
            <Tooltip title={t('integrations.exceededLimitBlockToolTip')}>
                <Core.Button
                    label={account.isExceededLimitBlocked ? t('integrations.exceededLimitUnblock') : t('integrations.exceededLimitBlock')}
                    onPress={onToggleExceededLimitBlockedIntegration}
                    isLoading={isTogglingExceededLimitBlockedIntegration}
                />
            </Tooltip>
        </div>
    );

    const onReloginIntegration = () => {
        dispatch(actions.reloginIntegration(
            companyId,
            account.id
        ));
    };

    const renderNeedRelogin = () => {
        if (!account.isExceededLimitBlocked && MARKETPLACES_WITH_RELOGIN.includes(account.marketplace)) {
            return (
                <div className={css([common.flexRow, marginsBottom.s3])}>
                    <div className={css(common.flexRowStart)}>
                        <p>{t('integrations.needRelogin')}</p>
                        <Core.Tooltip title={account.needRelogin ? t('integrations.needReloginTooltip') : t('integrations.isUnlockedTooltip')}>
                            <Core.Icon
                                className={css(styles.needReloginIcon)}
                                type={account.needRelogin ? ICONS.lock : ICONS.unlock}
                                color={account.needRelogin ? colors.red : colors.green}
                                size={spacing.s6}
                            />
                        </Core.Tooltip>
                    </div>
                    <Core.Button
                        label={account.needRelogin ? t('integrations.setRelogin') : t('integrations.setNeedRelogin')}
                        onPress={onReloginIntegration}
                        isLoading={isSettingRelogin}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className={css(common.flexCol)}>
            {renderExceededLimitBlockButtons()}
            {renderNeedRelogin()}
            {renderBlockButtons()}
            {renderBlockMarketSyncButtons()}
        </div>
    );
};

AccountStatusButtons.propTypes = {
    account: PropTypes.object.isRequired,
    companyId: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
    blockReasonInput: {
        width: 'auto',
        flex: 1
    },
    needReloginIcon: {
        marginLeft: spacing.s4
    },
    needReloginButtonContainer: {
        flex: 1
    }
});