import { StyleSheet } from './aphroditeWrapper';
import colors from './colors';
import { spacingBuilder } from './utils';

export const common = StyleSheet.create({
    flexCol: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    flexColCenter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    flexRow: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    flexRowCenter: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    flexRowStart: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    flexRowEnd: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    pointer: {
        cursor: 'pointer'
    },
    textShadow: {
        textShadow: `1px 1px 1px ${colors.textShadow}`
    },
    printDisabled: {
        '@media print': {
            display: 'none !important'
        }
    }
});

export const paddings = StyleSheet.create(spacingBuilder('padding'));
export const paddingsTop = StyleSheet.create(spacingBuilder('paddingTop'));
export const paddingsBottom = StyleSheet.create(spacingBuilder('paddingBottom'));
export const paddingsLeft = StyleSheet.create(spacingBuilder('paddingLeft'));
export const paddingsRight = StyleSheet.create(spacingBuilder('paddingRight'));

export const margins = StyleSheet.create(spacingBuilder('margins'));
export const marginsTop = StyleSheet.create(spacingBuilder('marginTop'));
export const marginsBottom = StyleSheet.create(spacingBuilder('marginBottom'));
export const marginsLeft = StyleSheet.create(spacingBuilder('marginLeft'));
export const marginsRight = StyleSheet.create(spacingBuilder('marginRight'));