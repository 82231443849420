import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';
import i18n from './i18n/i18n';
import Router from './Router';
import axiosConfig from './config/axiosConfig';
import { StyleSheet, css, fonts } from './styles';

axiosConfig(store);

class App extends PureComponent {
    render() {
        return (
            <div className={css(styles.global)}>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Router />
                        </PersistGate>
                    </Provider>
                </I18nextProvider>
            </div>
        )
    }
}

export default App;

const styles = StyleSheet.create({
    global: {
        ...fonts.mdNormal
    }
});