import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import { components as Core } from '../../../core';

class AccountSetupCheckbox extends React.PureComponent {
    static propTypes = {     
        item: PropTypes.object.isRequired,
        selected: PropTypes.array.isRequired,
        onToggleCallback: PropTypes.func.isRequired
    };

    onToggle = item => {
        if (_.find(this.props.selected, i => i.id === item.id)) {
            this.props.onToggleCallback(_.filter(this.props.selected, i => i.id !== item.id));
        } else {
            this.props.onToggleCallback([...this.props.selected, item]);
        }
    };

    get isChecked() {
        return !!_.find(this.props.selected, i => i.id === this.props.item.id) || this.props.item.isExist;
    }

    render() {
        const { item } = this.props;
        return (
            <Core.Checkbox
                data={item}
                onToggle={this.onToggle}
                checked={this.isChecked}
                disabled={item.isExist}
            />
        );
    }
}

export default AccountSetupCheckbox;