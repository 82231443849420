import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS, getObjectValue, useTranslation } from '../../../../core';
import { colors, css, StyleSheet, spacing } from '../../../../../styles';
import { InlineDashboardCards } from '../../common';
import * as dashboardSelectors from '../../../selectors';
import { getOverview } from '../../../actions';
import { COMPANY_REGIONS } from '../../../../companies';

export const Overview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOverview());
    }, []);

    const isLoading = useSelector(dashboardSelectors.isLoadingOverview);
    const data = useSelector(dashboardSelectors.getOverview);
    const filters = useSelector(dashboardSelectors.getFilters);

    const items = () => [{
        count: getObjectValue(data, 'current.registrations', 0),
        prevCount: getObjectValue(data, 'previous.registrations', 0),
        text: t('registrations'),
        tooltipText: t('dashboard.overview.tooltips.registrations'),
        iconName: ICONS.plus,
        iconColor: colors.green,
        iconBackground: colors.greenOpacity,
        isHidden: filters.region !== null
    }, {
        count: getObjectValue(data, 'current.registrationsUK', 0),
        prevCount: getObjectValue(data, 'previous.registrationsUK', 0),
        text: t('dashboard.overview.registrationsUK'),
        tooltipText: t('dashboard.overview.tooltips.registrationsUK'),
        iconName: ICONS.plus,
        iconColor: colors.primary,
        iconBackground: colors.primaryLight,
        isHidden: filters.region != null && filters.region !== COMPANY_REGIONS.UK
    }, {
        count: getObjectValue(data, 'current.registrationsDE', 0),
        prevCount: getObjectValue(data, 'previous.registrationsDE', 0),
        text: t('dashboard.overview.registrationsDE'),
        tooltipText: t('dashboard.overview.tooltips.registrationsDE'),
        iconName: ICONS.plus,
        iconColor: colors.greenSee,
        iconBackground: colors.greenSeeOpacity,
        isHidden: filters.region != null && filters.region !== COMPANY_REGIONS.DE
    }, {
        count: getObjectValue(data, 'current.conversions', 0),
        prevCount: getObjectValue(data, 'previous.conversions', 0),
        text: t('dashboard.overview.conversions'),
        tooltipText: t('dashboard.overview.tooltips.conversions'),
        iconName: ICONS.userAdd,
        iconColor: colors.orange,
        iconBackground: colors.orangeOpacity,
        showPrecetageValue: true
    }, {
        count: getObjectValue(data, 'current.churnedUsers', 0),
        prevCount: getObjectValue(data, 'previous.churnedUsers', 0),
        text: t('dashboard.overview.churnedUsers'),
        tooltipText: t('dashboard.overview.tooltips.churnedUsers'),
        iconName: ICONS.dislike,
        iconColor: colors.red,
        iconBackground: colors.redOpacity,
        showPrecetageValue: true,
        isReverse: true
    }, {
        count: getObjectValue(data, 'current.payingUsers', 0),
        prevCount: getObjectValue(data, 'previous.payingUsers', 0),
        text: t('dashboard.overview.payingUsers'),
        tooltipText: t('dashboard.overview.tooltips.payingUsers'),
        iconName: ICONS.dollar,
        iconColor: colors.green,
        iconBackground: colors.greenOpacity,
    }];

    return (
        <div className={css(styles.row)}>
            <InlineDashboardCards
                items={items()}
                span={filters.region != null ? 6 : 4}
                isLoading={isLoading}
            />
        </div>
    );
};

const styles = StyleSheet.create({
    row: {
        //width: 'fit-content',
        height: spacing.s14*2
    }
});