import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Collapse as AntdCollapse } from 'antd';
import { StyleSheet, css, fonts, spacing, colors } from '../../../../styles';

class Collapse extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.element.isRequired,
            key: PropTypes.string.isRequired
        })).isRequired
    };

    renderItem = item => {
        return (
            <AntdCollapse.Panel key={item.key} header={item.title}>
                {item.content}
            </AntdCollapse.Panel>
        )
    };

    render() {
        const { data } = this.props;
        return (
            <AntdCollapse bordered={false} className={css(styles.collapseContainer)}>
                {_.map(data, this.renderItem)}
            </AntdCollapse>
        );
    }
}

export default Collapse;

const styles = StyleSheet.create({
    collapseContainer: {
        backgroundColor: colors.transparent,
        '& .ant-collapse-item': {
            borderBottom: 'none',
            backgroundColor: colors.transparent
        },
        '& .ant-collapse-header': {
            ...fonts.lgNormal,
            paddingLeft: `${spacing.s4}px !important`,
            paddingTop: spacing.s5,
            paddingBottom: spacing.s5
        },
        '& .ant-collapse-arrow': {
            left: `0 !important`
        }
    }
});