import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { components as Core, translate } from '../../../core'
import { FEATURES } from '../../constants'
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { css, common } from '../../../../styles';

export const COLUMNS = {
    name: 'name',
    value: 'value'
};

class CompanyFeaturesModal extends React.PureComponent {
    static propTypes = {
        companyId: PropTypes.string.isRequired,
        companyFeatures: PropTypes.array.isRequired,
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.tableColumns = [{
            key: COLUMNS.name,
            render: this.renderFeatureName
        }, {
            key: COLUMNS.value,
            render: this.renderFeatureCheckboxColumn
        }];

        this.state = {
            currentCompanyFeatures: this.props.companyFeatures
        };
    }

    get data() {
        const { currentCompanyFeatures } = this.state;
        return _.map(FEATURES, ({ name, value }) => ({ name, bitValue: value, value: (currentCompanyFeatures & value) !== 0 }))
    }

    get isUpdatedState() {
        const { companyFeatures } = this.props;
        const { currentCompanyFeatures } = this.state;
        return companyFeatures !== currentCompanyFeatures;
    }

    toggleFeature = data => {
        this.setState(prevState => ({
            currentCompanyFeatures:
            data.inActive ? prevState.currentCompanyFeatures & ~data.bitValue : prevState.currentCompanyFeatures | data.bitValue
        }));
    }

    onUpdateCompanyFeatures = () => {
        const { companyId } = this.props;
        this.props.actions.updateCompanyFeature(companyId, this.state.currentCompanyFeatures);
    }

    renderFeatureCheckboxColumn = ({ value, bitValue }) => {
        return (
            <Core.Checkbox
                checked={value}
                onToggle={this.toggleFeature}
                data={{ bitValue, inActive: value }}
            />
        );
    }

    renderFeatureName = feature => {
        const { i18n } = this.props;
        return i18n.t(feature.name);
    }

    render() {
        const { i18n, onClose } = this.props;
        return (
            <Core.Modal
                isVisible={true}
                title={this.renderTitle}
                onClose={onClose}>
                <Core.Table
                    title={i18n.t('companies.features.features')}
                    data={this.data}
                    columns={this.tableColumns}
                />
                <div className={css(common.flexRowCenter)}>
                    {this.isUpdatedState ? (
                        <Core.Button
                            label={i18n.t('setupSettings.save')}
                            onPress={this.onUpdateCompanyFeatures}
                        />
                    ): null
                    }
                </div>
            </Core.Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state, ownProps) => ({
    companyFeatures: selectors.getCompanyFeatures(state, ownProps.companyId)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CompanyFeaturesModal));