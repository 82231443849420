import { combineReducers } from 'redux';
import _ from 'lodash';
import * as types from './actionTypes';
import { LOADING_NAME, ERROR_NAME } from './constants';

const loadingInitialState = {};
const loadingReducer = (state = loadingInitialState, action) => {
    const { type } = action;
    if (type === types.RESET) return loadingInitialState;
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    if (!matches) return state;

    const [, requestName, requestState] = matches;
    const propName = _.has(action, 'requestId') ? `${requestName}/${action.requestId}` : requestName;

    return {
        ...state,
        [propName]: requestState === 'REQUEST'
    };
};

const errorInitialState = {};
const errorReducer = (state = errorInitialState, action) => {
    switch (action.type) {
        case types.ADD_ERROR: {
            const { actionType, error } = action.payload;
            return {
                ...state,
                [actionType]: error
            };
        }
        case types.REMOVE_ERROR: {
            const { actionType } = action.payload;
            return {
                ...state,
                [actionType]: null
            };
        }
        case types.RESET:
            return errorInitialState;
        default:
            return state;
    }
};

export default combineReducers({
    [LOADING_NAME]: loadingReducer,
    [ERROR_NAME]: errorReducer
});