import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Col, Row } from "antd";
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import { components as Core, translate, constants as coreConstants, getTableProps, getPagination, } from '../../../core';
import Users from '../Users';
import Integrations from '../Integrations';
import { StyleSheet, css, fonts, spacing, colors, common } from '../../../../styles';
import CompanyDetailsModal from '../CompanyDetailsModal';
import valueBuilder from '../../services/valueBuilder';
import SentMessages from "../SentMessages";
import CompanyRemovedThreads from "../CompanyRemovedThreads";
import CompanyImportThreads from "../CompanyImportThreads";

export const COLUMNS = {
    email: 'email',
    registrationDate: 'registrationDate',
    trialExpire: 'trialExpire',
    action: 'action',
    ticketCountLimitData: 'ticketCountLimitData',
    subscriptionPlanId: 'subscriptionPlanId',
    manualSubscriptionExpire: 'manualSubscriptionExpire',
    sentMessagesIn24h: 'sentMessagesIn24h',
    isManuallyVerified: 'isManuallyVerified',
    shopifyBillingStatus: 'shopifyBillingInfo'
};

export const PLANS = {
    'D749E972-3FFF-4A71-8C6E-9C532C36D17A': 'Basic Annual',
    'C20B7D30-5728-413D-8279-E05F393150B5': 'Basic Monthly',
    '840A4F1C-8B70-4A9D-A380-88DC0465C01E': 'Enterprise Annual',
    'AEF3B35A-5A2E-4E73-98C4-3A942D2D5CF7': 'Enterprise Monthly',
    'E5CE9C53-71BD-4F18-9B44-BA96CC46846F': 'PRO Annual',
    'F13EC770-8DB4-4546-92BC-D99442381562': 'PRO Monthly',
    '6E2CD8B4-1C42-4662-89C9-C349901DD7D5': 'Starter Annual',
    '037A5F32-7B2C-4735-A030-1A0C52CE35F1': 'Starter Monthly'
};

const COMPANY_TYPES = {
    all: 0,
    leads: 1,
    customers: 2
};

const SUBSCRIPTION_FILTER_TYPES = {
    all: 0,
    stripe: 1,
    manual: 2,
    shopify: 3
};

const COLLAPSE_ITEMS = {
    users: 'users',
    integrations: 'integrations',
    sentMessages: 'sentMessages',
    importThread: 'importThread',
    removedThreads: 'removedThreads'
};

class Companies extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companies: PropTypes.array,
        pagination: PropTypes.object.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        companies: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.tableColumns = [{
            title: i18n.t("companies.email"),
            key: COLUMNS.email,
            dataIndex: COLUMNS.email,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("companies.registrationDate"),
            key: COLUMNS.registrationDate,
            dataIndex: COLUMNS.registrationDate,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("companies.lastMonthTicketsCount"),
            key: COLUMNS.ticketCountLimitData,
            render: this.renderTicketsCountColumn
        }, {
            title: i18n.t("companies.trialExpire"),
            key: COLUMNS.trialExpire,
            dataIndex: COLUMNS.trialExpire,
            render: this.renderTrialDateColumn,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("companies.subscription"),
            key: COLUMNS.subscriptionPlanId,
            render: this.renderSubscriptionName
        }, {
            title: i18n.t("companies.manualSubscriptionExpire"),
            key: COLUMNS.manualSubscriptionExpire,
            dataIndex: COLUMNS.manualSubscriptionExpire,
            render: this.renderManualSubscriptionColumn,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("companies.shopifyBillingStatus"),
            key: COLUMNS.shopifyBillingStatus,
            dataIndex: COLUMNS.shopifyBillingStatus,
            render: this.renderShopifyBillingStatus,
            align: 'center'
        }, {
            title: i18n.t("companies.sentMessagesIn24h"),
            key: COLUMNS.sentMessagesIn24h,
            dataIndex: COLUMNS.sentMessagesIn24h,
            align: 'center'
        }, {
            title: i18n.t("companies.isManuallyVerified"),
            key: COLUMNS.isManuallyVerified,
            dataIndex: COLUMNS.isManuallyVerified,
            render: this.renderManuallyVerifiedField,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS,
            align: 'center'
        }, {
            title: i18n.t("companies.actions"),
            key: COLUMNS.action,
            render: this.renderActionColumn
        }];
        this.state = {
            selectedCompany: null,
            modalCompanyId: null,
            searchText: '',
            pagination: {},
            filters: {},
            sorter: {},
            companyType: COMPANY_TYPES.all,
            subscriptionType: SUBSCRIPTION_FILTER_TYPES.all,
            companyRegion: null,
            // Integration details filter
            hasAccountFilter: false,
            integrationTerm: ''
        };
        this.onLoadCompanies();
    }

    componentDidUpdate(prevProps) {
        const { isLoading } = this.props;
        if (prevProps.isLoading && !isLoading) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ selectedCompany: null });
        }
    }

    onLoadCompanies = (page = null) => {
        const { pagination, filters, sorter, companyType, searchText, subscriptionType, companyRegion, integrationTerm, hasAccountFilter } = this.state;
        this.props.actions.getCompanies({
            ...getTableProps(page ? { current: page } : pagination, filters, sorter),
            term: searchText,
            companyType,
            subscriptionType,
            companyRegion,
            // account filter
            ...(hasAccountFilter && integrationTerm.length ? {
                integrationTerm
            } : {})
        });
    };

    onShowCompany = company => {
        if (company.id !== _.get(this.state.selectedCompany, 'id')) {
            this.setState({ selectedCompany: company });
        }
    };

    get renderUsersKey() {
        return `users${this.state.selectedCompany.id}`;
    }

    get renderIntegrationsKey() {
        return `integrations${this.state.selectedCompany.id}`;
    }

    get renderSentMessagesKey() {
        return `sentMessages${this.state.selectedCompany.id}`;
    }

    get renderRemovedThreadsKey() {
        return `removedThreads${this.state.selectedCompany.id}`;
    }

    renderManuallyVerifiedField = isManuallyVerified => (
        isManuallyVerified ?
            <span className={css(styles.greenText)}>{this.props.i18n.t("yes")}</span> :
            <span className={css(styles.redText)}>{this.props.i18n.t("no")}</span>
    )

    renderShopifyBillingStatus = shopifyBillingInfo => {
        const status = _.get(shopifyBillingInfo, 'status');
        const isExpired = status && _.some(constants.EXPIRED_SHOPIFY_SUBSCRIPTION_STATUSES, st => st === status.toLowerCase())
        return (
            <span className={css(
                styles.shopifyBillingStatus,
                status === 'active' ? styles.greenText : undefined,
                isExpired ? styles.redText : undefined,
            )}>
                {isExpired ? 'Expired' : status}
            </span>
        )
    };

    renderActionColumn = (text, company) => (
        <div>
            <Core.TableAction
                onPress={this.onOpenCompanyDetailsModal}
                text={this.props.i18n.t("companies.showDetails")}
                data={company}
            />
        </div>
    );

    renderTicketsCountColumn = ({ ticketCountLimitData, ticketsLimit }) => {
        if (ticketCountLimitData != null) {
            if (!_.get(ticketCountLimitData, 'maxTicketCount') && !ticketsLimit) {
                return _.get(ticketCountLimitData, 'lastMonthTicketCount');
            }
            const limit = ticketCountLimitData.maxTicketCount || ticketsLimit;
            const className = ticketCountLimitData.ticketsWithResponses > limit ?
                css(styles.overLimitedTicketsCount) : null;
            const text = `${ticketCountLimitData.lastMonthTicketCount}/${ticketCountLimitData.ticketsWithResponses}/${limit}`;
            return (
                <p className={className}>{text}</p>
            );
        }
        return null;
    };

    renderTrialDateColumn = (val, company) => {
        if (company.subscriptionPlanId) return <span style={{ color: 'green' }}>Active Subscription</span>;
        const date = valueBuilder.getLocalFullTime(val);
        return moment().isBefore(date) ? date : <span style={{ color: 'red' }}>{date}</span>;
    };

    renderManualSubscriptionColumn = val => {
        const date = valueBuilder.getDate(val);
        const color = moment().isBefore(date) ? styles.greenText : styles.redText;
        return <span className={css(color)}>{date}</span>;
    };

    renderSubscriptionName = val => {
        const { i18n } = this.props;
        return `${val.isManualSubscribed ? `${i18n.t('companies.manual')} ` : ''}${_.get(val, 'subscriptionPlan.name',  "")}`;
    };

    onChangeTable = (pagination, filters, sorter) => {
        this.setState({ pagination, filters, sorter }, this.onLoadCompanies);
    };

    get pagination() {
        return getPagination(this.props.pagination);
    }

    onOpenCompanyDetailsModal = company => {
        if (company.id !== this.state.modalCompanyId) {
            this.setState({ modalCompanyId: company.id });
        }
    };

    onCloseCompanyDetailsModal = () => {
        this.setState({ modalCompanyId: null });
    };

    get renderUsersTable() {
        return (
            <Users
                key={this.renderUsersKey}
                companyId={this.state.selectedCompany.id}
            />
        );
    }

    get renderIntegrationsTable() {
        return (
            <Integrations
                key={this.renderIntegrationsKey}
                companyId={this.state.selectedCompany.id}
            />
        );
    }

    get renderSentMessagesTable() {
        const { selectedCompany } = this.state;
        return (
            <SentMessages
                key={this.renderSentMessagesKey}
                companyId={selectedCompany.id}
                companyLimit={_.get(selectedCompany.ticketCountLimitData, 'maxTicketCount') || _.get(selectedCompany, 'ticketsLimit')}
            />
        )
    }

    get renderImportThread() {
        const { selectedCompany } = this.state;
        return (
            <CompanyImportThreads
                companyId={selectedCompany.id}
            />
        )
    }

    get renderRemovedThreadsTable() {
        const { selectedCompany } = this.state;
        return (
            <CompanyRemovedThreads
                key={this.renderRemovedThreadsKey}
                companyId={selectedCompany.id}
            />
        )
    }

    get collapseData() {
        const { i18n } = this.props;
        return [{
            key: COLLAPSE_ITEMS.users,
            title: i18n.t('screens.users'),
            content: this.renderUsersTable
        }, {
            key: COLLAPSE_ITEMS.integrations,
            title: i18n.t('screens.integrations'),
            content: this.renderIntegrationsTable
        }, {
            key: COLLAPSE_ITEMS.sentMessages,
            title: i18n.t('integrations.sentMessages'),
            content: this.renderSentMessagesTable
        }, {
            key: COLLAPSE_ITEMS.importThread,
            title: i18n.t('companies.importThread'),
            content: this.renderImportThread
        }, {
            key: COLLAPSE_ITEMS.removedThreads,
            title: i18n.t('integrations.removedThreads'),
            content: this.renderRemovedThreadsTable
        }];
    }

    get companies() {
        const { i18n } = this.props;
        return [{
            name: i18n.t('companies.all'),
            id: COMPANY_TYPES.all
        }, {
            name: i18n.t('companies.leads'),
            id: COMPANY_TYPES.leads
        }, {
            name: i18n.t('companies.customers'),
            id: COMPANY_TYPES.customers
        }]
    }

    get subscriptionTypes() {
        const { i18n } = this.props;
        return [{
            name: i18n.t('companies.subscriptionFilter.all'),
            id: SUBSCRIPTION_FILTER_TYPES.all
        }, {
            name: i18n.t('companies.subscriptionFilter.stripe'),
            id: SUBSCRIPTION_FILTER_TYPES.stripe
        }, {
            name: i18n.t('companies.subscriptionFilter.manual'),
            id: SUBSCRIPTION_FILTER_TYPES.manual
        }, {
            name: i18n.t('companies.subscriptionFilter.shopify'),
            id: SUBSCRIPTION_FILTER_TYPES.shopify
        }]
    }

    get renderCustomFilters() {
        const { i18n } = this.props;
        const { hasAccountFilter, integrationTerm } = this.state;
        return (
            <>
                <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                    <Core.Select
                        data={this.companies}
                        onChange={this.onChange}
                        defaultValue={COMPANY_TYPES.all}
                    />
                </Col>
                <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                    <Core.Select
                        data={this.subscriptionTypes}
                        onChange={this.onChangeSubscriptionType}
                        defaultValue={SUBSCRIPTION_FILTER_TYPES.all}
                    />
                </Col>
                <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                    <Core.RegionFilter
                        onChange={this.onChangeRegionType}
                    />
                </Col>
                <Col sm={10} md={5} xl={4} className={css(styles.filterWrapper)}>
                    <Core.Toggle
                        checkedText={i18n.t('companies.hasAccountFilter')}
                        unCheckedText={i18n.t('companies.hasAccountFilter')}
                        onToggle={this.onToggle}
                        checked={hasAccountFilter}
                        className={common.printDisabled}
                    />
                </Col>
                {hasAccountFilter ? (
                    <Col span={24} className={css(styles.mt2)}>
                        <p>{i18n.t('companies.accountFilter')}</p>
                        <Row type="flex" align="middle">
                            <Col sm={10} md={8} xl={6}>
                                <Core.SearchInput
                                    value={integrationTerm}
                                    onChange={this.onChangeSearchText('integrationTerm')}
                                    onPressEnter={this.onSearch}
                                    onSearch={this.onSearch}
                                    placeholder={i18n.t('companies.search')}
                                />
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </>
        )
    }

    onChange = value => {
        this.setState({ companyType: value }, () => this.onLoadCompanies(1));
    };

    onChangeSubscriptionType = subscriptionType => {
        this.setState({ subscriptionType }, () => this.onLoadCompanies(1));
    };

    onChangeRegionType = companyRegion => {
        this.setState({ companyRegion }, () => this.onLoadCompanies(1));
    };

    onToggle = () => {
        const { hasAccountFilter } = this.state;
        if (hasAccountFilter) {
            this.setState({ hasAccountFilter: false }, () => this.onLoadCompanies(1));
        } else {
            this.setState({ hasAccountFilter: true });
        }
    };

    onSearch = () => {
        this.onLoadCompanies(1);
    };

    onChangeSearchText = property => searchText => this.setState({ [property]: searchText });

    rowClassName = record => (_.get(record, 'id') === _.get(this.state.selectedCompany, 'id') ? css(styles.selectedCompany) : '');

    render() {
        const { isLoading, companies, i18n } = this.props;
        const { selectedCompany, modalCompanyId, searchText } = this.state;
        return (
            <>
                <Core.Table
                    title={i18n.t('screens.companies')}
                    columns={this.tableColumns}
                    data={companies}
                    pagination={this.pagination}
                    isLoading={isLoading}
                    onChange={this.onChangeTable}
                    onPressRow={this.onShowCompany}
                    hasSearch={true}
                    searchText={searchText}
                    onSearch={this.onSearch}
                    onChangeSearchText={this.onChangeSearchText('searchText')}
                    customFilters={this.renderCustomFilters}
                    hasCustomFilters={true}
                    rowClassName={this.rowClassName}
                />
                {selectedCompany ? (
                    <div className={css(styles.selectedCompanyContainer)}>
                        <p className={css(styles.selectedCompanyText)}>
                            <span className={css(styles.selectedCompanyLabel)}>
                                {`${i18n.t('companies.selectedCompany')}: `}
                            </span>
                            <span className={css(styles.selectedCompanyValue)}>
                                {selectedCompany.companyName}
                            </span>
                        </p>
                        <Core.Collapse data={this.collapseData} />
                    </div>
                ): null
                }
                {modalCompanyId ? (
                    <CompanyDetailsModal
                        companyId={modalCompanyId}
                        onClose={this.onCloseCompanyDetailsModal}
                    />
                ): null
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    companies: selectors.getCompanies(state),
    pagination: selectors.getCompaniesPagination(state),
    isLoading: selectors.isGettingCompanies(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Companies));

const styles = StyleSheet.create({
    selectedCompanyContainer: {
        marginTop: spacing.s7,
        marginBottom: spacing.s1
    },
    selectedCompanyText: {
        ...fonts.xlNormal,
        marginBottom: spacing.s3,
        color: colors.black
    },
    selectedCompanyLabel: {
        ...fonts.lgLight
    },
    selectedCompanyValue: {
        ':hover': {
            color: colors.primary
        }
    },
    overLimitedTicketsCount: {
        color: colors.red
    },
    selectedCompany: {
        backgroundColor: colors.greyLightOpacity
    },
    redText: {
        color: `${colors.red} !important`
    },
    greenText: {
        color: `${colors.green} !important`
    },
    mt2: {
        marginTop: spacing.s2
    },
    filterWrapper: {
        marginTop: spacing.s0,
        marginLeft: spacing.s2
    },
    shopifyBillingStatus: {
        color: `${colors.yellow} !important`,
        textTransform: 'capitalize'
    }
});