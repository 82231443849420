import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { fonts, StyleSheet, css, colors, spacing } from "../../../../styles";
import translate from "../../services/translate";

class Toggle extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onToggle: PropTypes.func.isRequired,
        checkedText: PropTypes.string,
        unCheckedText: PropTypes.string,
        checked: PropTypes.bool,
        className: PropTypes.object,
        name: PropTypes.string,
        isSmall: PropTypes.bool,
        label: PropTypes.string,
        hasMargins: PropTypes.bool
    };

    static defaultProps = {
        checked: false,
        className: undefined,
        checkedText: undefined,
        unCheckedText: undefined,
        name: undefined,
        isSmall: false,
        label: undefined,
        hasMargins: false
    };

    onChange = value => {
        if (this.props.name) {
            this.props.onToggle(value, this.props.name);
        } else {
            this.props.onToggle(value);
        }
    };

    render() {
        const { checkedText, unCheckedText, checked, className, isSmall, label, hasMargins, ...props } = this.props;
        return (
            <>
                {label ?
                    <p className={css(styles.label)}>{label}</p> : null
                }
                <Switch
                    {...props}
                    onChange={this.onChange}
                    checkedChildren={checkedText || this.props.i18n.t('toggleModes.on')}
                    unCheckedChildren={unCheckedText || this.props.i18n.t('toggleModes.off')}
                    checked={checked}
                    className={isSmall ?
                        css(styles.containerSmall, checked ? styles.checkedContainerSmall : undefined, hasMargins ? styles.margins : undefined, className) :
                        css(styles.container, checked ? styles.checkedContainer : undefined, hasMargins ? styles.margins : undefined, className)}
                />
            </>
        );
    }
}

export default translate()(Toggle);

const styles = StyleSheet.create({
    container: {
        height: spacing.s7,
        ':after': {
            width: spacing.s6,
            height: spacing.s6
        },
        '& .ant-switch-inner': {
            fontSize: fonts.smNormal.fontSize,
            fontFamily: fonts.smNormal.fontFamily,
            fontWeight: fonts.smNormal.fontWeight,
            lineHeight: '1em',
            marginLeft: spacing.s8,
            marginRight: spacing.s2
        }
    },
    checkedContainer: {
        backgroundColor: colors.primaryBackground,
        ':after': {
            boxShadow: `0 0px ${spacing.s1}px 0 ${colors.primaryDarkOpacity}`,
            backgroundColor: colors.primary
        },
        '& .ant-switch-inner': {
            marginRight: spacing.s8,
            marginLeft: spacing.s2,
            color: colors.primary
        }
    },
    containerSmall: {
        height: spacing.s5,
        ':after': {
            width: spacing.s4,
            height: spacing.s4
        },
        '& .ant-switch-inner': {
            fontSize: fonts.xsNormal.fontSize,
            fontFamily: fonts.xsNormal.fontFamily,
            fontWeight: fonts.xsNormal.fontWeight,
            lineHeight: '1em',
            marginLeft: spacing.s6,
            marginRight: spacing.s1
        }
    },
    checkedContainerSmall: {
        backgroundColor: colors.primaryBackground,
        ':after': {
            boxShadow: `0 0px ${spacing.s1}px 0 ${colors.primaryDarkOpacity}`,
            backgroundColor: colors.primary
        },
        '& .ant-switch-inner': {
            marginRight: spacing.s6,
            marginLeft: spacing.s1,
            color: colors.primary
        }
    },
    label: {
        ...fonts.smMedium,
        color: colors.black,
        marginBottom: spacing.s1
    },
    margins: {
        marginBottom: spacing.s3
    }
});