import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css, common, colors, fonts } from '../../../../styles';
import Button from "../Button";

class TableAction extends React.PureComponent {
    static propTypes = {
        onPress: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        isButton: PropTypes.bool,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isButton: false,
        isLoading: false
    }

    onPress = event => {
        event.stopPropagation();
        this.props.onPress(this.props.data);
    };

    render() {
        const { text, isButton, isLoading } = this.props;
        return isButton ? (
            <Button
                label={text}
                onPress={this.onPress}
                className={styles.button}
                isLoading={isLoading}
            />
        ) : (
            <p onClick={this.onPress} className={css(common.pointer, styles.container)}>
                {text}
            </p>
        );
    }
}

export default TableAction;

const styles = StyleSheet.create({
    container: {
        ...fonts.xxsBold,
        ':hover': {
            color: colors.primary
        }
    },
    button: {
        '& > span': {
            color: `${colors.white} !important`
        },
    }
});