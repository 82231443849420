import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from '../../../../styles';
import Button from '../Button';
import { useTranslation } from '../../services';
import { BUTTON_TYPES } from '../../constants';

const FileInput = ({ onChange, isLoading, label, buttonProps, ...props }) => {
    const { t } = useTranslation();
    const inputRef = useRef();

    const onUpload = useCallback(event => {
        event.stopPropagation();
        inputRef.current.click();
    }, [inputRef]);

    return (
        <>
            <Button
                label={label || t('upload')}
                onPress={onUpload}
                isLoading={isLoading}
                isDisabled={isLoading}
                type={BUTTON_TYPES.blueOutline}
                {...buttonProps}
            />
            <input
                ref={inputRef}
                type="file"
                name="fileInput"
                onChange={onChange}
                className={css(styles.input)}
                {...props}
            />
        </>
    );
};

FileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    buttonProps: PropTypes.object
};

FileInput.defaultProps = {
    isLoading: false,
    label: undefined,
    buttonProps: {}
};

export default FileInput;

const styles = StyleSheet.create({
    input: {
        display: 'none'
    }
});
