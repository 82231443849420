import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { translate, components as Core } from '../../../core';
import { colors, css, fonts, spacing, StyleSheet } from "../../../../styles";

class TicketListReport extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        isDownloadingReport: PropTypes.bool
    };

    static defaultProps = {
        isDownloadingReport: false
    };

    state = {
        startDate: null,
        endDate: null,
        isSelectVisible: false
    };

    onChangeDate = (startDate, endDate) => {
        const params = startDate && endDate ? { from: startDate.toDate(), to: endDate.toDate() } : {};
        this.props.actions.downloadTicketListReport(this.props.companyId, params);
        this.onSelectDate();
    };

    onSelectDate = () => {
        this.setState(prevState => ({ isSelectVisible: !prevState.isSelectVisible }));
    };

    render() {
        const { i18n, isDownloadingReport } = this.props;
        const { startDate, endDate, isSelectVisible } = this.state;
        return (
            <div className={css(styles.main)}>
                {isSelectVisible ? (
                    <>
                        <p className={css(styles.title)}>{i18n.t('companies.ticketListReport')}</p>
                        <Core.DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={this.onChangeDate}
                            className={styles.datePicker}
                        />
                    </>
                ) : (
                    <Core.Button
                        label={i18n.t('downloadTickets')}
                        onPress={this.onSelectDate}
                        isLoading={isDownloadingReport}
                        isDisabled={isDownloadingReport}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isDownloadingReport: selectors.isDownloadingTicketListReport(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TicketListReport));

const styles = StyleSheet.create({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: spacing.s3
    },
    title: {
        ...fonts.smBold,
        marginTop: spacing.s0,
        marginBottom: spacing.s1
    },
    datePicker: {
        marginBottom: spacing.s1
    }
});