import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../selectors';
import { components as Core, useTranslation, FIELD_TYPES, MARKETPLACES_ENUM } from '../../../core';
import valueBuilder from '../../services/valueBuilder';
import { StyleSheet, css, colors, fonts, spacing, common } from '../../../../styles';
import * as actions from "../../actions";
import {
    MARKETPLACE_REGION_ENUM,
    AMAZON_MARKETPLACE_COUNTRY_ENUM,
    EBAY_MARKETPLACE_COUNTRY_ENUM,
    PREDEFINED_SMTP_PROTOCOL_ADDRESSES,
    SMTP_PROTOCOL_TYPES
} from "../../constants";
import IntegrationLogs from '../IntegrationLogs';
import { AccountStatusButtons } from './AccountStatusButtons';

const FIELDS = {
    marketplace: 'marketplace',
    name: 'name',
    login: 'login',
    shopCountry: 'shopCountry',
    shopRegion: 'shopRegion',
    shopBaseApiUrl: 'shopBaseApiUrl',
    marketplaceLastSync: 'marketplaceLastSync',
    marketplaceMessagesLastSync: 'marketplaceMessagesLastSync',
    marketplaceOrdersLastSync: 'marketplaceOrdersLastSync',
    restrictBlock: 'restrictBlock',
    receiveBlockNotification: 'receiveBlockNotification',
    smtpSettings: 'smtpSettings',
    isExceededLimitBlocked: 'isExceededLimitBlocked'
};

const SMTP_FIELDS = {
    password: 'password',
    smtpServer: 'smtpserver',
    smtpPort: 'smtpport',
    smtpUseSsl: 'smtpuseSsl',
    protocolType: 'protocolType',
    protocolAddress: 'protocolAddress',
    protocolPort: 'protocolPort',
    protocolUseSsl: 'protocolUseSSL',
};

const COLLAPSE_ITEMS = {
    logs: 'logs'
};

const MODAL_WIDTH = 600;

const MARKETPLACES_WITH_SHOP_URL = [
    MARKETPLACES_ENUM.BigCommerce,
    MARKETPLACES_ENUM.Shopify,
    MARKETPLACES_ENUM.WooCommerce,
    MARKETPLACES_ENUM.Magento
];

const DetailsModal = ({ companyId, integration, onClose }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [updatedData, setUpdatedData] = useState({
        name: integration.name,
        login: integration.login,
        shopCountry: integration.shopCountry,
        shopRegion: integration.shopRegion,
        shopBaseApiUrl: integration.shopBaseApiUrl,
        marketplaceOrdersLastSync: integration.marketplaceOrdersLastSync,
        marketplaceMessagesLastSync: integration.marketplaceMessagesLastSync,
        restrictBlock: integration.restrictBlock,
        isExceededLimitBlocked: integration.isExceededLimitBlocked,
        //smtp
        password: integration.password,
        [SMTP_FIELDS.smtpServer]: integration[SMTP_FIELDS.smtpServer],
        [SMTP_FIELDS.smtpUseSsl]: integration[SMTP_FIELDS.smtpUseSsl],
        [SMTP_FIELDS.protocolType]: integration[SMTP_FIELDS.protocolType],
        [SMTP_FIELDS.protocolAddress]: integration[SMTP_FIELDS.protocolAddress],
        [SMTP_FIELDS.protocolPort]: integration[SMTP_FIELDS.protocolPort],
        [SMTP_FIELDS.protocolUseSsl]: integration[SMTP_FIELDS.protocolUseSsl],

    });
    const isUpdating = useSelector(state => selectors.isUpdatingIntegration(state, integration.id));
    const isMigratingToAmazonSP = useSelector(state => selectors.isMigratingToAmazonSP(state, integration.id));
    const isMigratingToNativeOutlook= useSelector(state => selectors.isMigratingToNativeOutlook(state, integration.id));
    const isSettingRelogin = useSelector(state => selectors.isSettingReloginIntegration(state, integration.id));

    const fields = useCallback(() => [{
        fieldName: FIELDS.marketplace,
        label: t('integrations.marketplace'),
        render: valueBuilder.getMarketplace
    }, {
        fieldName: FIELDS.name,
        label: t('integrations.name'),
        onChange: integration.marketplace === MARKETPLACES_ENUM.Ebay ? onChangeProperty(FIELDS.name) : undefined
    }, {
        fieldName: FIELDS.login,
        label: t('integrations.login'),
        onChange: onChangeProperty(FIELDS.login)
    }, {
        fieldName: FIELDS.shopCountry,
        label: t('integrations.shopCountry'),
        fieldType: FIELD_TYPES.SELECT,
        options: formatEnumToSelectOptions(integration.marketplace === MARKETPLACES_ENUM.Ebay ?
            EBAY_MARKETPLACE_COUNTRY_ENUM : AMAZON_MARKETPLACE_COUNTRY_ENUM),
        onChange: onChangeProperty(FIELDS.shopCountry),
        placeholder: i18n.t('integrations.shopCountrySelectPlaceholder')
    }, {
        fieldName: FIELDS.shopRegion,
        label: t('integrations.shopRegion'),
        fieldType: FIELD_TYPES.SELECT,
        options: formatEnumToSelectOptions(MARKETPLACE_REGION_ENUM),
        onChange: onChangeProperty(FIELDS.shopRegion),
        placeholder: i18n.t('integrations.shopRegionSelectPlaceholder')
    }, {
        fieldName: FIELDS.shopBaseApiUrl,
        label: t('integrations.shopBaseApiUrl'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(FIELDS.shopBaseApiUrl),
        placeholder: i18n.t('integrations.shopBaseApiUrl'),
        isVisible: _.includes(MARKETPLACES_WITH_SHOP_URL, integration.marketplace)
    }, {
        fieldName: FIELDS.marketplaceLastSync,
        label: t('integrations.marketplaceLastSync'),
        render: getIntegrationSyncTime
    }, {
        fieldName: FIELDS.marketplaceMessagesLastSync,
        label: t('integrations.marketplaceMessagesLastSync'),
        fieldType: FIELD_TYPES.DATE_PICKER,
        showTime: true,
        onChange: onChangeProperty(FIELDS.marketplaceMessagesLastSync),
        render: valueBuilder.getLocalFullTime
    }, {
        fieldName: FIELDS.marketplaceOrdersLastSync,
        label: t('integrations.marketplaceOrdersLastSync'),
        fieldType: FIELD_TYPES.DATE_PICKER,
        showTime: true,
        onChange: onChangeProperty(FIELDS.marketplaceOrdersLastSync),
        render: valueBuilder.getLocalFullTime
    }, {
        fieldName: FIELDS.restrictBlock,
        label: t('integrations.restrictBlock'),
        fieldType: FIELD_TYPES.CHECKBOX,
        onChange: onChangeProperty(FIELDS.restrictBlock)
    }, {
        fieldName: FIELDS.receiveBlockNotification,
        label: t('integrations.receiveBlockNotification'),
        fieldType: FIELD_TYPES.CHECKBOX,
        onChange: onChangeProperty(FIELDS.receiveBlockNotification)
    }, {
        fieldName: FIELDS.smtpSettings,
        label: t('integrations.smtpSettings'),
        fieldType: FIELD_TYPES.COLLAPSE,
        fields: smtpFields()
    }], [integration]);

    const smtpFields = useCallback(() => ([{
        fieldName: SMTP_FIELDS.password,
        label: t('integrations.smtp.password'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(SMTP_FIELDS.password),
    }, {
        fieldName: SMTP_FIELDS.smtpServer,
        label: t('integrations.smtp.smtpServer'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(SMTP_FIELDS.smtpServer)
    }, {
        fieldName: SMTP_FIELDS.smtpPort,
        label: t('integrations.smtp.smtpPort'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(SMTP_FIELDS.smtpPort)
    }, {
        fieldName: SMTP_FIELDS.smtpUseSsl,
        label: t('integrations.smtp.smtpUseSsl'),
        fieldType: FIELD_TYPES.CHECKBOX,
        onChange: onChangeProperty(SMTP_FIELDS.smtpUseSsl)
    }, {
        fieldName: SMTP_FIELDS.protocolType,
        label: t('integrations.smtp.protocolType'),
        fieldType: FIELD_TYPES.SELECT,
        options: formatEnumToSelectOptions(SMTP_PROTOCOL_TYPES),
        onChange: onChangeProperty(SMTP_FIELDS.protocolType),
    }, {
        fieldName: SMTP_FIELDS.protocolAddress,
        label: t('integrations.smtp.protocolAddress'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(SMTP_FIELDS.protocolAddress)
    }, {
        fieldName: SMTP_FIELDS.protocolPort,
        label: t('integrations.smtp.protocolPort'),
        fieldType: FIELD_TYPES.INPUT,
        onChange: onChangeProperty(SMTP_FIELDS.protocolPort)
    }, {
        fieldName: SMTP_FIELDS.protocolUseSsl,
        label: t('integrations.smtp.protocolUseSsl'),
        fieldType: FIELD_TYPES.CHECKBOX,
        onChange: onChangeProperty(SMTP_FIELDS.protocolUseSsl)
    }]), [integration]);

    const formatEnumToSelectOptions = enums => _.map(_.keys(enums), key => ({
        value: enums[key],
        text: key
    }));

    const getIntegrationSyncTime = value => valueBuilder.getIntegrationSyncTime(value, integration.inProgress, i18n);

    const onChangeProperty = property => value => {
        setUpdatedData(prevState => ({
            ...prevState,
            [property]: value
        }));
    }

    const renderStatus = useCallback(() => {
        return (
            <div className={css(styles.statusContainer)}>
                <p>{t('integrations.integrationFindDetails')}</p>
                {(integration.isBlocked || integration.blockedMarketSync) ?
                    <div className={css(common.textShadow, styles.errorContainer)}>
                        <p>{t('integrations.blockingReason')}</p>
                        {integration.failMessage ?
                            <p>{integration.failMessage}</p> : null
                        }
                    </div> : null
                }
                <p>{integration.inProgress ? t('integrations.syncIsInProgress') : t('integrations.syncIsQueued')}</p>
            </div>
        );
    }, [integration]);

    const renderLogsTable = () => {
        return (
            <IntegrationLogs
                companyId={companyId}
                integrationId={integration.id}
            />
        );
    };

    const onMigrateToAmazonSP = () => {
        dispatch(actions.migrateToAmazonSP(
            integration.id,
            companyId
        ));
    };

    const onMigrateToNativeOutlook = () => {
        dispatch(actions.migrateToNativeOutlook(
            integration.id,
            companyId
        ));
    };

    const hasUpdated = useCallback(() => {
        const notEmptyStringFields = [FIELDS.name, FIELDS.login];
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const key in updatedData) {
            if ((notEmptyStringFields.includes(key) && updatedData[key] && updatedData[key].trim().length && integration[key] !== updatedData[key]) ||
                updatedData[key] !== integration[key]
            ) return true;
        }
    }, [integration, updatedData]);

    const renderSaveButton = () => (
        <div className={css(common.flexRowEnd)}>
            <Core.Button
                label={t('save')}
                onPress={onUpdate}
                isLoading={isSettingRelogin || isUpdating}
                isDisabled={isSettingRelogin || !hasUpdated() || isUpdating}
            />
        </div>
    );

    const onUpdate = () => {
        dispatch(actions.updateIntegration({
            companyId,
            ...integration,
            ...updatedData
        }));
    }

    return (
        <Core.Modal
            isVisible={true}
            onClose={onClose}
            title={i18n.t('integrations.integrationDetails')}
            modalWidth={MODAL_WIDTH}>
            <div>
                {renderStatus()}
                <Core.FieldsGenerator
                    fields={fields()}
                    data={integration}
                    updatedData={updatedData}
                />
                <AccountStatusButtons
                    companyId={companyId}
                    account={integration}
                />
                {integration.marketplace === MARKETPLACES_ENUM.Amazon ?
                    <div>
                        <Core.PopConfirm onConfirm={onMigrateToAmazonSP}>
                            <span>
                                <Core.Button
                                    label={t('integrations.migrateToAmazonSP')}
                                    isLoading={isMigratingToAmazonSP}
                                />
                            </span>
                        </Core.PopConfirm>
                    </div> : null
                }
                {integration.marketplace === MARKETPLACES_ENUM.Email &&
                 integration.protocolAddress === PREDEFINED_SMTP_PROTOCOL_ADDRESSES.outlook ?
                    <div>
                        <Core.PopConfirm onConfirm={onMigrateToNativeOutlook}>
                            <span>
                                <Core.Button
                                    label={t('integrations.migrateToNativeOutlook')}
                                    isLoading={isMigratingToNativeOutlook}
                                />
                            </span>
                        </Core.PopConfirm>
                    </div> : null
                }
                <Core.Collapse
                    data={[{
                        key: COLLAPSE_ITEMS.logs,
                        title: i18n.t('integrations.logs.logs'),
                        content: renderLogsTable()
                    }]}
                />
                {renderSaveButton()}
            </div>
        </Core.Modal>
    );
}

DetailsModal.propTypes = {
    companyId: PropTypes.string.isRequired,
    integration: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default DetailsModal;

const styles = StyleSheet.create({
    statusContainer: {
        textAlign: 'center',
        ...fonts.xsNormal,
        lineHeight: fonts.smNormal.lineHeight,
        marginBottom: spacing.s3
    },
    errorContainer: {
        ...fonts.xsBold,
        lineHeight: fonts.smNormal.lineHeight,
        color: colors.red
    }
});