import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popover as AntdPopover } from 'antd';
import { StyleSheet, css, colors } from '../../../../styles';

class Popover extends React.PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
        visible: PropTypes.bool,
        onVisibleChange: PropTypes.func,
        trigger: PropTypes.string,
        overlayClassName: PropTypes.object
    };

    static defaultProps = {
        visible: false,
        onVisibleChange: null,
        trigger: 'click',
        overlayClassName: null
    };

    render() {
        const { children, trigger, overlayClassName, ...props } = this.props;
        const propsToPass = trigger === 'hover' ? _.omit(props, ['visible']) : props;
        return (
            <AntdPopover
                arrowPointAtCenter={true}
                overlayClassName={css(styles.container, overlayClassName)}
                trigger={trigger}
                {...propsToPass}>
                {children}
            </AntdPopover>
        );
    }
}

export default Popover;

const styles = StyleSheet.create({
    container: {
        '& .ant-popover-arrow': {
            borderColor: `${colors.white} !important`
        },
        '& .ant-popover-inner': {
            backgroundColor: colors.white
        },
        '& .ant-popover-inner-content': {
            color: colors.text,
            padding: '0 !important'
        }
    }
});