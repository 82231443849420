import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { components as Core, translate, constants as coreConstants, getTableProps, getPagination } from '../../../core';
import valueBuilder from '../../services/valueBuilder';

export const COLUMNS = {
    date: 'date',
    action: 'action',
    ip: 'ip'
};

class UserActivities extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        userId: PropTypes.string.isRequired,
        activities: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        activities: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.tableColumns = [{
            title: i18n.t("users.activities.date"),
            key: COLUMNS.date,
            dataIndex: COLUMNS.date,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("users.activities.action"),
            key: COLUMNS.action,
            dataIndex: COLUMNS.action
        }, {
            title: i18n.t("users.activities.ip"),
            key: COLUMNS.ip,
            dataIndex: COLUMNS.ip
        }];
        this.props.actions.getUserActivities(this.props.userId);
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getUserActivities(
            this.props.userId,
            getTableProps(pagination, filters, sorter)
        );
    };

    get pagination() {
        return getPagination(this.props.pagination);
    }

    render() {
        const { isLoading, activities } = this.props;
        return (
            <div>
                <Core.Table
                    columns={this.tableColumns}
                    data={activities}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.onChangeTable}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    activities: selectors.getActivitiesByUser(state, ownProps.userId),
    pagination: selectors.getActivitiesPagination(state, ownProps.userId),
    isLoading: selectors.isGettingUserActivities(state, ownProps.userId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserActivities));