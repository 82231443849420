import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PERIODS } from '../../constants';
import DateRangePicker from '../DateRangePicker';
import Popover from '../Popover';
import { colors, common, css, spacing, StyleSheet, fonts } from "../../../../styles";
import { translate, toLocalTime } from "../../services";

class DateFilter extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.object,
        defaultPeriod: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        defaultPeriod: PERIODS.last90Days
    };

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            period: this.props.defaultPeriod,
            startDate: moment().subtract(90, 'days'),
            endDate: moment()
        };
    }

    onFilterChange = (startDate, endDate, period = PERIODS.custom) => {
        this.setState({ startDate, endDate, period, isVisible: false });
        this.props.onChange(startDate, endDate, period);
    };

    onSelectPeriod = ({ startDate, endDate, period }) => this.onFilterChange(startDate, endDate, period);

    get options() {
        const { i18n } = this.props;
        return [{
            period: PERIODS.today,
            label: i18n.t('today'),
            startDate: moment(),
            endDate: moment()
        }, {
            period: PERIODS.yesterday,
            label: i18n.t('yesterday'),
            startDate: moment().subtract(1, 'days'),
            endDate: moment().subtract(1, 'days')
        }, {
            period: PERIODS.last7Days,
            label: i18n.t('last7Days'),
            startDate: moment().subtract(7, 'days'),
            endDate: moment()
        }, {
            period: PERIODS.last30Days,
            label: i18n.t('last30Days'),
            startDate: moment().subtract(30, 'days'),
            endDate: moment()
        }, {
            period: PERIODS.last90Days,
            label: i18n.t('last90Days'),
            startDate: moment().subtract(90, 'days'),
            endDate: moment()
        }, {
            period: PERIODS.allTime,
            label: i18n.t('allTime'),
            startDate: moment("20210620", "YYYYMMDD"),
            endDate: moment()
        }];
    }

    getLabelForPeriod = period => {
        const { i18n } = this.props;
        switch (period) {
            case PERIODS.today: return i18n.t('today');
            case PERIODS.yesterday: return i18n.t('yesterday');
            case PERIODS.last7Days: return i18n.t('last7Days');
            case PERIODS.last30Days: return i18n.t('last30Days');
            case PERIODS.last90Days: return i18n.t('last90Days');
            case PERIODS.allTime: return i18n.t('allTime');
            default: return '';
        }
    };

    get content() {
        const { i18n } = this.props;
        const { startDate, endDate, period } = this.state;
        return (
            <div className={css(styles.content)}>
                <p className={css(styles.contentDividerText)}>{i18n.t('selectPeriod')}</p>
                {this.options.map(this.renderPeriod)}
                <p className={css(styles.contentDividerText)}>{i18n.t('orSelectCustomDates')}</p>
                <DateRangePicker
                    startDate={period === PERIODS.custom ? startDate : null}
                    endDate={period === PERIODS.custom ? endDate : null}
                    onChange={this.onFilterChange}
                    hasMargin={false}
                    className={styles.dateFilter}
                />
            </div>
        );
    }

    get label() {
        const { startDate, endDate, period } = this.state;
        return period === PERIODS.custom ? `${toLocalTime.toLocalDate(startDate)} - ${toLocalTime.toLocalDate(endDate)}` :
            this.getLabelForPeriod(period);
    }

    toggleVisible = () => this.setState(prevState => ({ isVisible: !prevState.isVisible }));

    renderPeriod = item => (
        <div
            key={item.period}
            onClick={() => this.onSelectPeriod(item)}
            className={css(common.pointer, styles.period)}>
            <p className={css(styles.periodLabel, item.period === this.state.period ? styles.periodSelected : undefined)}>
                {this.getLabelForPeriod(item.period)}
            </p>
        </div>
    );

    render() {
        const { isVisible } = this.state;
        const { className } = this.props;
        return (
            <Popover
                content={this.content}
                visible={isVisible}
                onVisibleChange={this.toggleVisible}
                placement="bottom">
                <div className={css(common.pointer, styles.main, className)}>
                    <p className={css(styles.label)}>{this.label}</p>
                </div>
            </Popover>
        );
    }
}

export default translate()(DateFilter);

const styles = StyleSheet.create({
    main: {
        backgroundColor: colors.white,
        height: `${spacing.s9}px !important`,
        display: 'flex',
        alignItems: 'baseline',
        color: colors.textColor,
        borderRadius: spacing.s9,
        border: `1px solid ${colors.grey}`,
        padding: `${spacing.s1}px ${spacing.s3}px`
    },
    label: {
        width: '100%',
        textAlign: 'center'
    },
    content: {
        padding: spacing.s3
    },
    contentDividerText: {
        ...fonts.xsNormal,
        color: colors.grey,
        marginBottom: spacing.s2,
        marginTop: spacing.s0
    },
    dateFilter: {
        '& .ant-calendar-range-picker-input': {
            width: '140px !important'
        }
    },
    period: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing.s2
    },
    periodLabel: {
        ...fonts.smNormal,
        marginRight: spacing.s5
    },
    periodSelected: {
        ...fonts.smNormal,
        color: colors.primary
    }
});