import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FIELD_TYPES } from '../../constants'
import LabeledInput from '../LabeledInput';
import LabeledDatePicker from '../LabeledDatePicker';
import LabeledCheckBox from '../LabeledCheckBox';
import LabeledSelect from '../LabeledSelect';
import LabeledInputNumber from '../LabeledInputNumber';
import Collapse from '../Collapse';

class FieldsGenerator extends React.PureComponent {
    static propTypes = {
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                fieldName: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                render: PropTypes.func,
                onChange: PropTypes.func,
                fieldType: PropTypes.number,
                options: PropTypes.array,
                placeholder: PropTypes.string,
                showTime: PropTypes.bool,
                isVisible: PropTypes.bool,
                min: PropTypes.number
            })
        ).isRequired,
        data: PropTypes.object.isRequired,
        updatedData: PropTypes.object
    };

    static defaultProps = {
        updatedData: null
    };

    getFieldValue = field => {
        const { data, updatedData } = this.props;
        if (field.sourceField) {
            const { sourceField } = field;
            return _.has(updatedData[sourceField], field.fieldName)
                ? updatedData[sourceField][field.fieldName]
                : data[sourceField][field.fieldName];
        }
        return _.has(updatedData, field.fieldName) ? updatedData[field.fieldName] : data[field.fieldName];
    };

    getFieldProps = field => ({
        key: field.fieldName,
        label: field.label,
        value: field.render ? field.render(this.getFieldValue(field)) : this.getFieldValue(field),
        isDisabled: !field.onChange,
        onChange: field.onChange,
        options: field.options,
        placeholder: field.placeholder,
        showTime: field.showTime || false,
        min: field.min
    });

    getFieldPropsCollapse = field => ({
        key: field.fieldName,
        title: field.label,
        content: this.getCollapseContent(field.fields)
    });

    getCollapseContent = fields => (
        <>
            {_.map(fields, field => this.renderField(field))}
        </>
    )

    renderField = field => {
        if (!_.get(field, 'isVisible', true)) return null;
        switch (field.fieldType) {
            case FIELD_TYPES.DATE_PICKER:
                return (<LabeledDatePicker {...this.getFieldProps(field)} />);
            case FIELD_TYPES.CHECKBOX:
                return (<LabeledCheckBox {...this.getFieldProps((field))} />);
            case FIELD_TYPES.SELECT:
                return (<LabeledSelect {...this.getFieldProps((field))} />);
            case FIELD_TYPES.INPUT_NUMBER:
                return (<LabeledInputNumber {...this.getFieldProps((field))} />);
            case FIELD_TYPES.COLLAPSE:
                return (<Collapse data={[this.getFieldPropsCollapse(field)]} />);
            default:
                return (<LabeledInput {...this.getFieldProps(field)} />);
        }
    };

    render() {
        return (
            <div>
                {_.map(this.props.fields, this.renderField)}
            </div>
        );
    }
}

export default FieldsGenerator;