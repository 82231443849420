import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../selectors';
import { components as Core, useTranslation } from '../../../core';
import valueBuilder from '../../services/valueBuilder';
import { StyleSheet, css, colors, fonts, spacing } from '../../../../styles';
import { getRemoveThreadLog, restoreThread } from "../../actions";

const FIELDS = {
    numberId: 'numberId',
    marketplace: 'marketplace',
    accountName: 'accountName',
    customerEmail: 'customerEmail',
    customerName: 'customerName',
    marketOrderId: 'marketOrderId',
    trackingNumber: 'trackingNumber',
    subject: 'subject',
    createdDate: 'createdDate',
};

const RemovedThreadModal = ({ companyId, threadId, onClose }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const thread = useSelector(state => selectors.getRemovedThread(state, companyId, threadId));
    const isRestoringThread = useSelector(state => selectors.isRestoringThread(state, threadId));

    useEffect(() => {
        dispatch(getRemoveThreadLog(companyId, threadId));
    }, []);

    useEffect(() => {
        if (!thread) onClose();
    }, [thread]);

    const fields = useCallback(() => [{
        fieldName: FIELDS.numberId,
        label: t('threads.numberId')
    }, {
        fieldName: FIELDS.marketplace,
        label: t('threads.marketplace'),
        render: valueBuilder.getMarketplace
    }, {
        fieldName: FIELDS.accountName,
        label: t('threads.accountName')
    }, {
        fieldName: FIELDS.customerEmail,
        label: t('threads.customerEmail')
    }, {
        fieldName: FIELDS.customerName,
        label: t('threads.customerName')
    }, {
        fieldName: FIELDS.marketOrderId,
        label: t('threads.marketOrderId')
    }, {
        fieldName: FIELDS.trackingNumber,
        label: t('threads.trackingNumber')
    }, {
        fieldName: FIELDS.subject,
        label: t('threads.subject')
    }, {
        fieldName: FIELDS.createdDate,
        label: t('threads.createdDate'),
        render: valueBuilder.getLocalFullTime
    }], [thread]);

    const restoreItem = () => dispatch(restoreThread(companyId, threadId));

    return (thread ?
        <Core.Modal
            isVisible={true}
            onClose={onClose}
            title={i18n.t('threads.threadDetails')}>
            <div>
                {_.map(fields(), ({fieldName, label, render}) => {
                    return (
                        <>
                            <p className={css(styles.label)}>
                                {label}
                            </p>
                            <p className={css(styles.value)}>
                                {!render ? thread[fieldName] : render(thread[fieldName])}
                            </p>
                        </>
                    );
                })}
                {_.get(thread, 'removeLog') ?
                    <p className={css(styles.value, styles.removeLog)}>
                        {valueBuilder.getFullTime(thread.removeLog.date)}
                        {" - "}
                        {thread.removeLog.userId ?
                            t('threads.removedByUser', {
                                user: thread.removeLog.userName,
                                id: thread.removeLog.userId
                            }) :
                            t('threads.removedAutomatically')
                        }
                    </p> : null
                }
                <Core.PopConfirm onConfirm={restoreItem}>
                <span>
                    <Core.Button
                        label={t('threads.restore')}
                        className={styles.restoreButton}
                        isLoading={isRestoringThread}
                        isDisabled={isRestoringThread}
                    />
                </span>
                </Core.PopConfirm>
            </div>
        </Core.Modal> : null
    );
}

RemovedThreadModal.propTypes = {
    companyId: PropTypes.string.isRequired,
    threadId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default RemovedThreadModal;

const styles = StyleSheet.create({
    label: {
        ...fonts.xsLight,
        marginBottom: 2
    },
    value: {
        ...fonts.smNormal,
        color: colors.black,
        marginBottom: spacing.s1
    },
    restoreButton: {
        margin: `${spacing.s1}px 0`
    },
    removeLog: {
        marginTop: spacing.s3,
        paddingTop: spacing.s3,
        borderTop: `1px solid ${colors.greyLight}`
    }
});