import React from 'react'
import { components as Core, useTranslation } from '../../core';
import { Dashboard } from './Dashboard';
import { CancellationDashboard } from './CancellationDashboard/CancellationDashboard';

const TABS = {
    dashboard: 'dashboard',
    cancellation: 'cancellation',
}

export const DashboardTabs = () => {
    const { t } = useTranslation();
    const getTabs = () => {
        return [{
            tabProps: {
                key: TABS.dashboard,
                tab: t('screens.companies')
            },
            component: <Dashboard />
        }, {
            tabProps: {
                key: TABS.cancellation,
                tab: t('screens.cancellations')
            },
            component: <CancellationDashboard />
        }];
    }

    return (
        <Core.Tabs
            tabs={getTabs()}
        />
    )
};