import { fonts, StyleSheet, css, colors } from './index';

const overwriteStyles = StyleSheet.create({
    antOverride: {
        ...fonts.mdLight,
        backgroundColor: colors.background,
        '& .ant-notification': {
            ...fonts.mdLight
        }
    }
});

document.body.classList.add(css(
    overwriteStyles.antOverride
));