import * as actionTypes from './actionTypes';
import * as api from './api';
import { Toast } from '../core';


export const removeCustomerData = searchTerm => dispatch => {
    dispatch({ type: actionTypes.REMOVE_CUSTOMER_DATA.REQUEST });
    dispatch({ type: actionTypes.CLEAR_REMOVED_CUSTOMERS_DATA.SUCCESS })
    return api.removeCustomerData(searchTerm)
        .then(response => {
            dispatch({
                type: actionTypes.REMOVE_CUSTOMER_DATA.SUCCESS,
                payload: {
                    data: response.data
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.REMOVE_CUSTOMER_DATA.ERROR });
        });
};

export const createNewEmailAccount = () => dispatch => {
    dispatch({ type: actionTypes.CREATE_NEW_EMAIL.REQUEST });
    return api.createNewEmailAccount()
        .then(response => {
            dispatch({
                type: actionTypes.CREATE_NEW_EMAIL.SUCCESS,
                payload: {
                    data: response.data
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.CREATE_NEW_EMAIL.ERROR });
        });
};