import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import {
    translate,
    getTableProps,
    getPagination,
    components as Core,
    constants as coreConstants
} from '../../../core';
import valueBuilder from "../../services/valueBuilder";
import { StyleSheet, css, fonts } from "../../../../styles";
import RemovedThreadModal from './RemovedThreadModal';

export const COLUMNS = {
    numberId: 'numberId',
    marketplace: 'marketplace',
    accountName: 'accountName',
    customerEmail: 'customerEmail',
    customerName: 'customerName',
    marketOrderId: 'marketOrderId',
    trackingNumber: 'trackingNumber',
    subject: 'subject',
    createdDate: 'createdDate',
};

class CompanyRemovedThreads extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        threads: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        threads: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.state = {
            searchText: '',
            sorter: {},
            selectedThreadId: null
        };
        this.tableColumns = [{
            title: i18n.t("threads.numberId"),
            key: COLUMNS.numberId,
            dataIndex: COLUMNS.numberId,
            width: '5%'
        }, {
            title: i18n.t("threads.marketplace"),
            key: COLUMNS.marketplace,
            dataIndex: COLUMNS.marketplace,
            render: valueBuilder.getMarketplace,
            width: '5%'
        }, {
            title: i18n.t("threads.accountName"),
            key: COLUMNS.accountName,
            dataIndex: COLUMNS.accountName,
            width: '10%'
        }, {
            title: i18n.t("threads.customerName"),
            key: COLUMNS.customerName,
            dataIndex: COLUMNS.customerName,
            width: '10%'
        }, {
            title: i18n.t("threads.customerEmail"),
            key: COLUMNS.customerEmail,
            dataIndex: COLUMNS.customerEmail,
            width: '10%'
        }, {
            title: i18n.t("threads.subject"),
            key: COLUMNS.subject,
            dataIndex: COLUMNS.subject,
            width: '30%'
        }, {
            title: i18n.t("threads.createdDate"),
            key: COLUMNS.createdDate,
            dataIndex: COLUMNS.createdDate,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS,
            width: '10%'
        }, {
            title: i18n.t("threads.marketOrderId"),
            key: COLUMNS.marketOrderId,
            dataIndex: COLUMNS.marketOrderId,
            width: '10%'
        }, {
            title: i18n.t("threads.trackingNumber"),
            key: COLUMNS.trackingNumber,
            dataIndex: COLUMNS.trackingNumber,
            width: '10%'
        }];
        this.props.actions.getCompanyRemovedThreads(this.props.companyId);
    }

    onChangeSearchText = searchText => this.setState({ searchText });

    onSearch = () => {
        const { searchText, sorter } = this.state;
        this.props.actions.getCompanyRemovedThreads(
            this.props.companyId,
            {
                term: searchText,
                ...getTableProps(this.pagination, {}, sorter)
            }
        );
    };

    onChangeTable = (pagination, filters, sorter) => {
        this.setState({ sorter });
        this.props.actions.getCompanyRemovedThreads(
            this.props.companyId,
            {
                term: this.state.searchText,
                ...getTableProps(pagination, filters, sorter)
            }
        );
    };

    get pagination() {
        return getPagination(this.props.pagination);
    }

    onSelectThread = thread => this.setState({ selectedThreadId: thread.id });

    onCloseModal = () => this.setState({ selectedThreadId: null });

    render() {
        const { isLoading, threads, i18n, companyId } = this.props;
        const { searchText, selectedThreadId } = this.state;
        return (
            <div>
                <Core.Table
                    columns={this.tableColumns}
                    data={threads}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.onChangeTable}
                    hasSearch={true}
                    searchText={searchText}
                    onSearch={this.onSearch}
                    onChangeSearchText={this.onChangeSearchText}
                    onPressRow={this.onSelectThread}
                    rightContent={(
                        <p className={css(styles.searchText)}>{i18n.t('threads.searchBy')}</p>
                    )}
                />
                {selectedThreadId ?
                    <RemovedThreadModal
                        companyId={companyId}
                        threadId={selectedThreadId}
                        onClose={this.onCloseModal}
                    /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    threads: selectors.getRemovedThreads(state, ownProps.companyId),
    pagination: selectors.getRemovedThreadsPagination(state, ownProps.companyId),
    isLoading: selectors.isLoadingRemovedThreads(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CompanyRemovedThreads));

const styles = StyleSheet.create({
    searchText: {
        ...fonts.xsLight
    }
});