import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { components as Core, translate } from "../../../core";
import { common, css, StyleSheet, spacing } from "../../../../styles";
import UsersInput from './UsersInput';

const { resetNextReviewDateForUser } = actions;

class ResetNpsForUsersModal extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        selectedUsers: PropTypes.array,
        onChangeSelectedUsers: PropTypes.func.isRequired,
        actions: PropTypes.object.isRequired
    };

    static defaultProps = {
        selectedUsers: []
    };

    onReset = () => {
        const { selectedUsers } = this.props;
        this.props.actions.resetNextReviewDateForUser(_.map(selectedUsers, user => user.id));
        this.props.onClose();
    };

    render() {
        const { onClose, i18n, selectedUsers, onChangeSelectedUsers } = this.props;
        return (
            <Core.Modal
                isVisible={true}
                onClose={onClose}
                title={i18n.t('users.sendNps')}>
                <div className={css(common.flexRowStart)}>
                    <UsersInput
                        selectedUsers={selectedUsers}
                        onChangeSelectedUsers={onChangeSelectedUsers}
                    />
                </div>
                {selectedUsers.length ?
                    <Core.Button
                        label={i18n.t('send')}
                        onPress={this.onReset}
                        className={styles.button}
                    /> : null
                }
            </Core.Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ resetNextReviewDateForUser }, dispatch)
});

export default connect(null, mapDispatchToProps)(translate()(ResetNpsForUsersModal));

const styles = StyleSheet.create({
    button: {
        marginTop: spacing.s1
    }
});