import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { translate, getTableProps, getPagination } from '../../../core';
import { IntegrationsDetailsModal } from '../IntegrationDetailsModal';
import IntegrationsTable from '../IntegrationsTable';

class Integrations extends React.PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        integrations: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        integrations: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.state = {
            modalIntegrationId: null
        };
        this.props.actions.getIntegrations(this.props.companyId);
    }

    setPrioritySync = integration => {
        this.props.actions.setPrioritySync(integration.id, this.props.companyId);
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getIntegrations(
            this.props.companyId,
            getTableProps(pagination, filters, sorter)
        );
    };

    get pagination() {
        return getPagination(this.props.pagination);
    }

    onOpenIntegrationDetailsModal = integration => {
        if (integration.id !== this.state.modalIntegrationId) {
            this.setState({ modalIntegrationId: integration.id });
        }
    };

    onCloseIntegrationDetailsModal = () => {
        this.setState({ modalIntegrationId: null });
    };

    render() {
        const { isLoading, integrations, companyId } = this.props;
        const { modalIntegrationId } = this.state;
        return (
            <div>
                <IntegrationsTable
                    integrations={integrations}
                    isLoading={isLoading}
                    pagination={this.props.pagination}
                    setPrioritySync={this.setPrioritySync}
                    onPressRow={this.onOpenIntegrationDetailsModal}
                    onChangeTable={this.onChangeTable}
                />
                {modalIntegrationId ? (
                    <IntegrationsDetailsModal
                        companyId={companyId}
                        integrationId={modalIntegrationId}
                        onClose={this.onCloseIntegrationDetailsModal}
                    />
                ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    integrations: selectors.getIntegrationsByCompany(state, ownProps.companyId),
    pagination: selectors.getIntegrationsPagination(state, ownProps.companyId),
    isLoading: selectors.isGettingIntegrations(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Integrations));