import { NAME } from './constants';
import { selectors as coreSelectors } from '../core';
import * as actionTypes from './actionTypes';

// removedData
export const getRemovedData = state => state[NAME].removedData;
export const isRemovingData = state => coreSelectors.isLoading(state, actionTypes.REMOVE_CUSTOMER_DATA.NAME);

// new email account
export const getNewEmailAccount = state => state[NAME].newEmailAccount;
export const isCreatingNewEmailAccount = state => coreSelectors.isLoading(state, actionTypes.CREATE_NEW_EMAIL.NAME);