import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Col, Row } from "antd";
import * as actions from '../../actions';
import { RATING_TYPES } from '../../constants';
import { translate, DATE_FORMATS, getTableProps } from '../../../core';
import { StyleSheet, css, fonts, spacing, colors, common } from '../../../../styles';
import { Select, SearchInput, DateFilter } from "../../../core/components";
import PromoterScoreStats from "./PromoterScoreStats";
import PromoterScoresTable from "./PromoterScoresTable";
import RepeatSurvey from "./RepeatSurvey";
import FeedbackChart from "./FeedbackChart";

class Feedback extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            rating: undefined,
            startDate: moment().subtract(90, 'days'),
            endDate: moment(),
            searchText: null
        };
        this.props.actions.getMissedNpsCount();
        this.onFiltersChange();
    }

    get ratingTypes() {
        const { i18n } = this.props;
        return [{
            name: i18n.t('feedback.promoters'),
            id: RATING_TYPES.promoters
        }, {
            name: i18n.t('feedback.passives'),
            id: RATING_TYPES.passives
        }, {
            name: i18n.t('feedback.detractors'),
            id: RATING_TYPES.detractors
        }]
    }

    get formattedFilters() {
        const { startDate, endDate, rating, searchText } = this.state;
        let filters = {
            rating, term: searchText
        };
        if (startDate && endDate) {
            filters = {
                ...filters,
                from: moment(startDate).startOf('day').format(DATE_FORMATS.UTC),
                to: moment(endDate).endOf('day').format(DATE_FORMATS.UTC)
            };
        }
        return filters;
    }

    onFiltersChange = () => {
        this.props.actions.getPromoterScores(this.formattedFilters);
        this.props.actions.getPromoterScoreStats(this.formattedFilters);
    };

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getPromoterScores({
            ...getTableProps(pagination, filters, sorter),
            ...this.formattedFilters
        });
    };

    onChangeFilter = rating => {
        this.setState({ rating }, this.onFiltersChange);
    };

    onDateFilterChange = (startDate, endDate) => {
        this.setState({ startDate, endDate }, this.onFiltersChange);
    };

    onDownload = () => {
        this.props.actions.downloadNPSReport(this.formattedFilters);
    };

    onSearch = () => {
        this.props.actions.getPromoterScores(this.formattedFilters);
        this.props.actions.getPromoterScoreStats(this.formattedFilters);
    }

    onChangeSearchText = text => {
        this.setState({ searchText: text });
    }

    render() {
        const { i18n } = this.props;
        const { rating, searchText } = this.state;
        return (
            <>
                <div className={css(common.flexRow)}>
                    <div className={css(common.flexCol)}>
                        <Row align="middle" gutter={spacing.s3} className={css(styles.top)}>
                            <Col sm={4} md={2} xl={1}>
                                <p className={css(styles.title)}>{i18n.t('screens.feedback')}</p>
                            </Col>
                            <Col className={css(styles.top)}>
                                <SearchInput
                                    className={css(styles.searchInput)}
                                    value={searchText}
                                    onChange={this.onChangeSearchText}
                                    onPressEnter={this.onSearch}
                                    onSearch={this.onSearch}
                                    placeholder={i18n.t('companies.search')}
                                />
                            </Col>
                            <Col sm={4} md={4} xl={4}>
                                <Select
                                    value={rating}
                                    data={this.ratingTypes}
                                    onChange={this.onChangeFilter}
                                    mainClassName={styles.type}
                                    allowClear={true}
                                    placeholder={i18n.t('feedback.filterByRating')}
                                />
                            </Col>
                            <Col sm={4} md={4} xl={4}>
                                <DateFilter onChange={this.onDateFilterChange} />
                            </Col>
                            <Col className={css(styles.repeatSurveyCol)}>
                                <RepeatSurvey />
                            </Col>
                        </Row>
                        <div className={css(common.flexRow)}>
                            <PromoterScoreStats onChangeRateFilter={this.onChangeFilter} />
                        </div>
                    </div>
                </div>
                <Row align="middle" gutter={spacing.s8} className={css(styles.mainContainer)}>
                    <Col sm={24} md={12}>
                        <PromoterScoresTable
                            onChangeTable={this.onChangeTable}
                            onDownload={this.onDownload}
                        />
                    </Col>
                    <Col sm={24} md={12}>
                        <FeedbackChart />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const FeedbackWrraped = connect(null, mapDispatchToProps)(translate()(Feedback));

export {
    FeedbackWrraped as Feedback
};

const styles = StyleSheet.create({
    top: {
        margin: `${spacing.s3}px 0`,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: spacing.s1
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
    },
    leftTop: {
        flex: 'none',
        marginRight: spacing.s2,
        flexWrap: 'nowrap'
    },
    rightTop: {
        flex: 2
    },
    repeatSurveyCol: {
        marginLeft: 'auto'
    },
    mainContainer: {
        flexWrap: 'nowrap',
        alignItems: 'stretch'
    },
    searchInput: {
        marginBottom: spacing.s1,
    }
});