import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { colors, StyleSheet, css } from "../../../../styles";

class LoadingComponent extends React.PureComponent {
    static propTypes = {
        component: PropTypes.object.isRequired,     //eslint-disable-line
        routerProps: PropTypes.object.isRequired
    };

    get suspenseComponent() {
        return (
            <div className={css(styles.suspenseContainer)}>
                <Spinner />
            </div>
        )
    }

    render() {
        const { routerProps } = this.props;
        return (
            <Suspense fallback={this.suspenseComponent}>
                <this.props.component {...routerProps} />
            </Suspense>
        );
    }
}

export default LoadingComponent;

const styles = StyleSheet.create({
    suspenseContainer: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 4,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.white
    }
});
