import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { components as Core, ICONS } from "../../../core";
import { common, css, StyleSheet, colors, spacing } from "../../../../styles";

class UsersInput extends React.PureComponent {
    static propTypes = {
        selectedUsers: PropTypes.array,
        onChangeSelectedUsers: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedUsers: []
    };

    renderSelectedUsers = user => (
        <div key={user.id} className={css([common.flexRow, styles.user])}>
            {user.fullName}
            <Core.Icon type={ICONS.close} onClick={() => this.onRemoveUser(user)} className={css(styles.closeIcon)} />
        </div>
    );

    onRemoveUser = user => {
        const users = _.filter(this.props.selectedUsers, item => item.id !== user.id);
        const usersKeys = _.map(users, _.property('id'));
        this.props.onChangeSelectedUsers(usersKeys, users)
    };

    render() {
        const { selectedUsers } = this.props;
        return selectedUsers.length ?
            _.map(selectedUsers, this.renderSelectedUsers) :
            null;
    }
}

export default UsersInput;

const styles = StyleSheet.create({
    user: {
        border: `1px solid ${colors.primary}`,
        borderRadius: spacing.s5,
        width: 'fit-content',
        padding: `${spacing.s0}px ${spacing.s1}px`,
        margin: spacing.s0,
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        flex: 'unset'
    },
    closeIcon: {
        marginLeft: spacing.s0
    }
});