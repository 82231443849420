import React, { PureComponent } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { history, constants as coreConstants, components as Core } from './modules/core';
import { store } from './store';

const Home = React.lazy(() => import('./modules/shared/components'));

class Router extends PureComponent {
    homeComponent = routerProps => {
        return <Core.LoadingComponent component={Home} routerProps={routerProps} />
    };

    render() {
        return (
            <ConnectedRouter history={history} store={store}>
                <Switch>
                    <Route
                        exact={true}
                        path={coreConstants.ROUTES.home()}
                        component={this.homeComponent}
                    />
                    <Redirect to={coreConstants.ROUTES.home()} />
                </Switch>
            </ConnectedRouter>
        );
    }
}

export default Router;