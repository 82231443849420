import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Col } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { components as Core, translate, Toast, TOAST_TYPES } from "../../../core";
import { common, css, StyleSheet, spacing, fonts, colors } from "../../../../styles";
import UsersInput from './UsersInput';

const MODAL_WIDTH = 800;

class NewNotificationModal extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        selectedUsers: PropTypes.array,
        onChangeSelectedUsers: PropTypes.func.isRequired,
        actions: PropTypes.object.isRequired
    };

    static defaultProps = {
        selectedUsers: []
    };

    constructor(props) {
        super(props);
        this.state = {
            expiryDate: null
        }
        this.text = '';
    }

    get renderUsers() {
        const { i18n, selectedUsers, onChangeSelectedUsers } = this.props;
        return selectedUsers.length ? (
            <UsersInput
                selectedUsers={selectedUsers}
                onChangeSelectedUsers={onChangeSelectedUsers}
            />
        ) : (
            <p className={css([styles.allUsers])}>{i18n.t('users.notificationForAllUsers')}</p>
        );
    }

    get sendButton() {
        const { i18n } = this.props;
        return (
            <Col>
                <Core.Button label={i18n.t('send')} onPress={this.onSend} />
            </Col>
        );
    }

    onSend = () => {
        const { selectedUsers } = this.props;
        const { expiryDate } = this.state;
        if (!this.text) {
            Toast.error({ type: TOAST_TYPES.cannotSendAnEmptyNotification });
            return;
        }

        selectedUsers.length ?
            this.props.actions.createNotificationForUsers(this.text, moment(expiryDate).utc(), _.map(selectedUsers, user => user.id)) :
            this.props.actions.createNotificationForAll(this.text, moment(expiryDate).utc());
        this.props.onClose();
    };

    onChangeText = value => this.text = value;

    onChangeExpiryDate = value => this.setState({ expiryDate: value });

    render() {
        const { onClose, i18n } = this.props;
        const { expiryDate } = this.state;
        return (
            <Core.Modal
                isVisible={true}
                onClose={onClose}
                modalWidth={MODAL_WIDTH}
                footer={this.sendButton}
                title={i18n.t('users.newNotification')}>
                <div className={css(common.flexRowStart)}>
                    {this.renderUsers}
                    <div className={css(styles.textEditor, styles.container)}>
                        <Core.RichTextEditor
                            valueToUpdate={this.text}
                            onChange={this.onChangeText}
                        />
                    </div>
                    <div className={css(styles.expiryDateContainer)}>
                        <Core.LabeledDatePicker
                            className={styles.expiryDateInput}
                            label={i18n.t('users.notifications.expiryDate')}
                            value={expiryDate}
                            onChange={this.onChangeExpiryDate}
                            showTime={true}
                        />
                        <p className={css(styles.notRequiredLabel)}>{i18n.t('users.notifications.notRequired')}</p>
                    </div>
                </div>
            </Core.Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(translate()(NewNotificationModal));

const styles = StyleSheet.create({
    container: {
        width: '100%'
    },
    textEditor: {
        margin: `${spacing.s2}px`
    },
    allUsers: {
        ...fonts.mdNormal,
        margin: 'auto'
    },
    expiryDateContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing.s2
    },
    expiryDateInput: {
        width: 'auto',
        marginBottom: 0
    },
    notRequiredLabel: {
        color: colors.primary,
        marginLeft: spacing.s0
    }
});