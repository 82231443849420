import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { translate } from "../../services";
import { DATE_FORMATS } from "../../constants";

const PICKER_MODE = 'year';

class PickerYear extends React.PureComponent {
    static propTypes = {
        pickerType: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        year: PropTypes.number
    };

    static defaultProps = {
        year: moment().format(DATE_FORMATS.YEAR)
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    onChange = date => {
        this.props.onChange(date.format(DATE_FORMATS.YEAR));
        this.onOpenChange(false);
    };

    onValueChange = date => {
        this.props.onChange(date || moment().format(DATE_FORMATS.YEAR));
    };

    onOpenChange = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    get value() {
        const { year } = this.props;
        return moment(year, DATE_FORMATS.YEAR);
    }

    render() {
        const { open } = this.state;
        const { ...props } = this.props;
        return (
            <DatePicker
                onOpenChange={this.onOpenChange}
                open={open}
                mode={PICKER_MODE}
                format={DATE_FORMATS.YEAR}
                value={this.value}
                onPanelChange={this.onChange}
                {...props}
                onChange={this.onValueChange}
            />
        );
    }
}

export default (translate()(PickerYear));