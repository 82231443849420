import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntdButton } from 'antd';
import { StyleSheet, css, colors, spacing, fonts } from '../../../../styles';

class Button extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        onPress: PropTypes.func,
        isLoading: PropTypes.bool,
        isDisabled: PropTypes.bool,
        type: PropTypes.string,
        className: PropTypes.object,
        hasFullWidth: PropTypes.bool
    };

    static defaultProps = {
        onPress: null,
        isLoading: false,
        isDisabled: false,
        type: 'primary',
        className: undefined,
        hasFullWidth: false
    };

    render() {
        const { label, onPress, isLoading, isDisabled, type, className, hasFullWidth } = this.props;
        return (
            <AntdButton
                onClick={onPress}
                loading={isLoading}
                disabled={isDisabled}
                type={type}
                className={css(styles.button, hasFullWidth ? styles.fullWidth : undefined, className)}>
                {label}
            </AntdButton>
        );
    }
}

export default Button;

const styles = StyleSheet.create({
    button: {
        marginBottom: spacing.s1,
        ...fonts.mdNormal,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        height: 'auto !important',
        borderRadius: spacing.s4,
        border: `1px solid ${colors.primary}`,
        color: colors.white,
        boxShadow: 0,
        backgroundColor: colors.primary,
        ':hover': {
            backgroundColor: colors.primaryDark
        }
    },
    fullWidth: {
        width: '100%'
    }
});