const en = {
    adminLogIn: {
        part1: 'Admin',
        part2: ' log in',
    },
    ok: 'Ok',
    email: 'E-mail',
    password: 'Password',
    signIn: 'Sign in',
    footerText: '© Replyco, 2024',
    logout: 'Logout',
    deletePicture: 'Delete picture',
    continue: 'Continue',
    upload: 'Upload',
    download: 'Download',
    copyToClipboard: 'Copy to ClipBoard',
    export: 'Export',
    period: 'Period',
    region: 'Region',
    regionTypes: {
        all: 'All Region',
        0: 'United Kingdom',
        1: 'Germany',
    },
    country: 'Country',
    toast: {
        error: {
            message: 'Error',
            description: 'Something went wrong...'
        },
        success: {
            message: 'Success'
        },
        serverUnavailable: {
            description: 'Server is unavailable'
        },
        cannotSendAnEmptyNotification: {
            message: 'Warning',
            description: 'You can not send an empty notification'
        }
    },
    screens: {
        companies: 'Companies',
        integrations: 'Integrations',
        users: 'Users',
        feedback: 'NPS',
        dashboard: 'Dashboard',
        usageReport: 'Usage Report'
    },
    companies: {
        stripeSubscriptionDetails: 'Stripe Subscription Details',
        shopifyBillingInfoCollapse: 'Shopify Billing Info',
        stripeSubscriptionId: 'Stripe subscription id',
        companyName: 'Company name',
        workPhone: 'Phone',
        email: 'Email',
        appLanguage: 'Application Language',
        registrationDate: 'Registration Date',
        trialExpire: 'Trial expire',
        selectedCompany: 'Selected company',
        actions: 'Actions',
        showDetails: 'Show details',
        hideDetails: 'Hide details',
        companyDetails: 'Company details',
        accountSetup: 'Account Setup',
        locationDetails: 'Locations Details',
        country: 'Country',
        state: 'State',
        city: 'City',
        address1: 'Address line 1',
        address2: 'Address line 2',
        zipCode: 'Zip Code',
        billingCountry: 'Billing country',
        billingState: 'Billing state',
        billingCity: 'Billing city',
        billingAddress1: 'Billing address 1',
        billingAddress2: 'Billing address 2',
        billingZipeCode: 'Billing Zipe Code',
        dataRemoveCollapse: 'Remove Data Details',
        dataLifetime: 'Tickets Data Lifetime (month)',
        ordersDataLifetime: 'Orders Data Lifetime (month)',
        feedbacksDataLifetime: 'Feedbacks Data Lifetime (month)',
        archiveThreadsLifetime: 'Archive tickets Lifetime (days)',
        deletedDataLifetime: 'Deleted Data Lifetime (days)',
        updateTrialExpire: 'Update trial expire date',
        notifiedAboutCompanyRemovingDate: 'Notified About Company Removing Date',
        deleteCompany: 'Delete company',
        search: 'Search',
        deleteCompanyConfirm: 'Deleting company will automatically delete all its data, users. You won\'t be able to revert these changes.',
        manualSubscription: 'Manual subscription',
        manualSubscriptionExpire: 'Subscription expire',
        subscriptionPlan: 'Subscription plan',
        subscriptionDate: 'Subscription Date',
        paymentCustomerId: 'Payment Customer Id',
        isManualSubscribed: 'Manual Subscribed',
        subscription: 'Subscription',
        accountFilter: 'Filter by Integration details:',
        subscriptionPlanEnum: {
            solo: 'Standard',
            team: 'Pro',
            advanced: 'Advanced',
            enterprise: 'Enterprise (new)',
            soloAnnual: 'Standard Annual',
            teamAnnual: 'Pro Annual',
            advancedAnnual: 'Advanced Annual',
            enterpriseAnnual: 'Enterprise Annual (new)',
            growth: 'Growth (new)',
            growthAnnual: 'Growth Annual (new)',
            business: 'Business (new)',
            businessAnnual: 'Business Annual (new)'
        },
        subscriptionPlanSelectPlaceholder: 'Please select a plan',
        updateManualSubscription: 'Update manual subscription',
        lastMonthTicketsCount: 'Tickets count',
        unlimited: 'Unlimited',
        billingUsage: 'Billing Usage',
        isOldSubscription: 'Old Pricing',
        failedSubscription: 'Failed subscription',
        showUsedTicketsCount: 'Show used tickets count',
        integrationsCount: 'Integrations Count',
        endlessIntegrations: 'Endless Integrations',
        updateCompany: 'Update Company',
        customers: 'Customers',
        leads: 'Leads',
        all: 'All customers',
        sentMessagesIn24h: 'Sent in 24h',
        totalTicketsCount: 'Total tickets count',
        totalTicketsWithResponsesCount: 'Total tickets count with response',
        ticketsLimit: 'Tickets limit',
        ticketListReport: 'Ticket List Report',
        ticketsDuringTrialPeriod: 'Tickets during Trial period',
        enterpriseSubscription: 'Manual Subscription',
        removeSubscription: 'Revove Subscription',
        enterpriseFields: {
            numberOfTickets: 'Number of tickets',
            subscriptionExpire: 'Subscription expire',
            apply: 'Apply'
        },
        verified: 'Email verified',
        isManuallyVerified: 'Manually verified',
        shopifyBillingStatus: 'Shopify Billing Status',
        showPopupNotifications: 'Show popup notifications',
        manual: 'Manual',
        subscriptionFilter: {
            all: 'All subscriptions',
            stripe: 'Stripe',
            manual: 'Manual',
            shopify: 'Shopify'
        },
        features: {
            features: 'Features',
            chat: 'Chat',
            channelSpecificSignatures: 'Channel-specific signatures',
            whatsApp: 'Whats App',
            customFields: 'Custom fields',
            twoFactorAuthentication: 'Two Factor Authentication',
            dedicatedAccountManager: 'Dedicated account manager',
            agentCollisionsDetection: 'User collision detection',
            lifetimeHistoricalData: 'Lifetime historical data',
            channelSpecificTemplates: 'Channel-specific templates',
            autoresponses: 'Autoresponses',
            textSnippets: 'Text Snippets',
            translations: 'Translations',
            apiAccess: 'API Access (optional)',
            advancedReporting: 'Advanced reporting',
            smartFilters: 'Smart filters',
            userPermissions: 'User permissions',
            betaFeatures: 'Beta features',
            cases: 'Cases',
            feedbacks: 'Feedbacks',
            orderSync: 'Sync Orders',
            productSync: 'Sync Products',
            customerSync: 'Sync Customers',
            feedbackRequests: 'Feedback Requests',
            draftSaving: 'Draft Message Saving',
            calls: 'Calls',
            userRoles: 'User Roles',
            ebayInTicketCancellationReturnsRefunds: 'Ebay In Ticket Cancellation/Returns/Refunds',
            customisedPrioritiesAndSLA: 'Customised Priorities And SLA',
            liveChats: 'Live Chats (Widget)',
            ai: 'AI'
        },
        customizeUI: {
            customizeUI: 'Customize UI',
            caption: 'Company caption:',
            logoLight: 'Light Logo:',
            miniLogoLight: 'Mini light logo:',
            logoDark: 'Dark Logo:',
            miniLogoDark: 'Mini dark logo:',
            primary: 'Primary:',
            success: 'Success:',
            warning: 'Warning:',
            error: 'Error:',
        },
        hasAccountFilter: 'Integration details',
        importThread: 'Import Tickets',
        importFromJSON: 'Import Tickets from JSON',
        notImportedThread: 'Not Imported Tickets:',
        importedThreadCount: ' Imported Tickets Count: {{count}}',
        notImportedThreadCount: 'Not Imported Tickets Count: {{count}}',
        importErrorDetails: "Import Error Details:",
        shopifyBillingInfo: {
            shopUrl: 'Shop Url',
            status: 'Status',
            activationDate: 'Activation Date',
            createdDate: 'Created Date',
            subscriptionId: 'Subscription Id',
            tempReplycoSubscriptionId: 'Temp Replyco Subscription Id'
        },
    },
    users: {
        fullName: 'Full name',
        email: 'Email',
        lastLogin: 'Last login',
        lastUserAction: 'Last User Action',
        nextReviewDate: 'Next Review Date',
        userDetails: 'User details',
        phone: 'Phone',
        preferedLanguageCode: 'Preferred language',
        loginAsUser: 'Log in as user',
        unblockUser: 'Unblock user',
        userActivities: 'User activities',
        admin: 'Admin',
        activities: {
            date: 'Date',
            action: 'Action',
            ip: 'IP'
        },
        notifications: {
            creationDate: 'Creation date',
            text: 'Text',
            read: 'Read',
            unRead: 'Unread',
            expiryDate: 'Expiry Date:',
            notRequired: '* Not required'
        },
        userNotifications: 'User notifications',
        newNotification: 'New notification',
        notificationForAllUsers: 'Notification for all users',
        sendNps: 'Send NPS'
    },
    integrations: {
        companyEmail: 'Company Email',
        allIntegrations: 'All integrations',
        marketplace: 'Marketplace',
        name: 'Name',
        login: 'Login',
        shopCountry: 'Shop Country',
        shopCountrySelectPlaceholder: 'Please select Shop Country',
        shopRegion: 'Shop Region',
        shopRegionSelectPlaceholder: 'Please select Shop Region',
        shopBaseApiUrl: 'Shop URL',
        marketplaceLastSync: 'Last sync',
        marketplaceMessagesLastSync: 'Last Messages sync',
        marketplaceOrdersLastSync: 'Last Orders sync',
        marketplaceReturnsLastSync: 'Last Returns sync',
        restrictBlock: 'Restrict Block',
        receiveBlockNotification: 'Receive Notifications about Blocking',
        smtpSettings: 'SMTP Settings',
        smtp: {
            password: 'Password',
            smtpServer: 'SMTP Server',
            smtpPort: 'SMTP Port',
            smtpUseSsl: 'SMTP Use SSL',
            protocolType: 'Protocol Type',
            protocolAddress: 'Protocol Address',
            protocolPort: 'Protocol Port',
            protocolUseSsl: 'Protocol Use SSL',
        },
        status: 'Status',
        needRelogin: 'Need Relogin?',
        isLockedTooltip: 'Integration is temporary blocked.',
        isMarketLockedTooltip: 'Marketplace data sync is temporary blocked.',
        needReloginTooltip: 'Need Relogin!',
        isUnlockedTooltip: 'Integration operating without any problems.',
        integrationDetails: 'Integration details',
        integrationFindDetails: 'You can find details about current integrations and change some of them.',
        syncIsInProgress: 'Account Sync is in progress now.',
        syncIsQueued: 'Account sync is queued.',
        blockingReason: 'Blocking reason:',
        syncIsQueuedNow: 'Sync is queued now.',
        unblock: 'Unblock',
        block: 'Block',
        exceededLimitBlock: 'Exceeded Limit Block',
        exceededLimitUnblock: 'Exceeded Limit Unblock',
        exceededLimitBlockToolTip: 'Users won\'t be able to unblock this integration by themself',
        unblockMarketSync: 'Unblock Market Sync',
        blockMarketSync: 'Block Market Sync',
        blockReason: 'Block reason',
        notifyAboutBlocking: 'Notify Customer',
        skipNotifyAboutBlocking: 'Skip Notification',
        prioritySync: 'Priority sync',
        syncTime: 'Sync time',
        min: 'min',
        sentMessages: 'Sent messages',
        setNeedRelogin: 'Set Need relogin',
        setRelogin: 'Set relogin',
        types: {
            marketplace: 'Marketplace',
            instantMessages: 'Instant messages',
            all: 'All'
        },
        statuses: {
            blocked: 'Blocked',
            notBlocked: 'Not Blocked',
            all: 'All Statuses'
        },
        logs: {
            logs: 'Logs',
            date: 'Date',
            action: 'Action',
            message: 'Message',
            actions: {
                1: 'Block',
                2: 'Unblock',
                3: 'Need Relogin',
                4: 'Relogin',
                5: 'Block Market Sync',
                6: 'Unblock Market Sync',
                7: 'Exceeded Limit Block',
                8: 'Exceeded Limit Unblock',
            }
        },
        migrateToAmazonSP: 'Migrate to Amazon SP',
        migrateToNativeOutlook: 'Migrate to Native Outlook',
        removedThreads: 'Removed threads',
        filterByIntegrations: 'Filter by Integrations'
    },
    delete: {
        title: 'Are you sure?',
        yes: 'Yes',
        no: 'No',
    },
    cancel: 'Cancel',
    writeYourMessage: 'Write your message...',
    showPreview: 'Show preview',
    closePreview: 'Close preview',
    send: 'Send',
    downloadTickets: 'Download tickets',
    setupSettings: {
        labels: 'Labels',
        rules: 'Rules',
        templates: 'Templates',
        autoResponses: 'Auto-responses',
        customFields: 'Custom Fields',
        save: 'Save',
        cancel: 'Cancel',
        selectAll: 'Select All'
    },
    feedback: {
        noData: 'No message',
        message: 'Message',
        latestReview: 'Latest Review',
        skippedViews: 'Skipped views',
        filled: 'Reviews',
        promoters: 'Promoters',
        passives: 'Passives',
        detractors: 'Detractors',
        nps: 'NPS',
        repeatSurvey: 'Repeat survey',
        days: 'days',
        filterByRating: 'Filter by rating',
        timeComparison: 'Time Comparison',
        table: {
            companyName: 'Company Name',
            email: 'User Email',
            date: 'Date',
            rating: 'Rating',
            comment: 'Comment'
        },
        rate: {
            promoter: 'Promoter',
            detractor: 'Detractor',
            passive: 'Passive',
        },
        countTooltip: 'Count: {{count}}',
        chart: {
            promoters: 'Promoters',
            detractors: 'Detractors',
            passives: 'Passives'
        }
    },
    day: 'day',
    days: 'days',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    noData: 'No data',
    reviewDate: "Review Date - ",
    comment: "Comment: ",
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    toggleModes: {
        on: 'On',
        off: 'Off',
    },
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days',
    last90Days: 'Last 90 days',
    selectPeriod: 'Select period',
    lastSync: 'Last Sync',
    orSelectCustomDates: 'Or select custom dates',
    allTime: 'All time',
    registrations: "Registrations",
    threads: {
        numberId: 'Ticket ID',
        marketplace: 'Marketplace',
        accountName: 'Account Name',
        customerEmail: 'Customer Email',
        customerName: 'Customer Name',
        subject: 'Subject',
        createdDate: 'Created Date',
        marketOrderId: 'Market Order Id',
        trackingNumber: 'Tracking Number',
        searchBy: '*Search by Ticket ID, Subject, Customer Name&Email, Market customer Id, Market order Id, Tracking Number',
        threadDetails: 'Thread details',
        restore: 'Restore',
        removedAutomatically: 'Removed automatically',
        removedByUser: 'Remove by {{user}} (ID - {{id}})'
    },
    encrypt: {
        encrypt: 'Delete data',
        encryptedCustomersCount: 'Deleted customers',
        encryptedOrderCount: 'Deleted orders',
        enterCustomerData: 'Customer to delete'
    },
    createNewEmail: {
        email: 'Email',
        password: 'Password',
        createEmail: 'Create Replyco Email Account',
        protocolAddress: 'Protocol Address',
        protocolPort: 'Protocol Port',
        protocolType: 'Protocol Type',
        protocolUseSSL: 'Protocol Use SSL',
        smtpServer: 'SMTP Server',
        smtpPort: 'SMTP Port',
        smtpUseSSL: 'SMTP Use SSL'

    },
    dashboard: {
        overview: {
            registrationsUK: 'Registrations UK',
            registrationsDE: 'Registrations DE',
            conversions: 'Conversions',
            churnedUsers: 'Churned Users',
            payingUsers: 'Paying Users',
            tooltips: {
                registrations: 'Amount of Registrations that registared during selected period and in selected region',
                registrationsUK: 'Amount of Registrations from DE that registared during selected period',
                registrationsDE: 'Amount of Registrations from UK that registared during selected period',
                conversions: 'Number of companies registered within selected period who already bought subscription / all registered companies * 100%',
                churnedUsers: 'Number of companies registered within selected period who did not buy a subscription and their trial already expired / all registered companies',
                payingUsers: 'Companies who already have subscription',
            }
        },
        registrationsTable: {
            companyEmail: "Company Email",
            registrationDate: "Registration Date",
            trialExpire: "Trial Expire"
        }
    },
    usageReport: {
        companyEmail: 'Company Email',
        lastSubPeriodStartDate: 'Last Sub Period Start Date',
        currentAndExpectedUsage: 'Current Usage / Expected Usage',
        ticketsLimit: 'Tickets Limit',
        overusage: 'Overusage',
        companySubscriptionPeriodTypes: {
            all: "All",
            yearly: "Yearly",
            monthly: "Monthly"
        },
        subscriptonUsageTypes: {
            all: 'All',
            usageBased: 'Usage Based',
            unlimited: 'Unlimited (Old)'
        }
    }
};

export default en;
