import React from "react";
import PropTypes from "prop-types";
import { Table as AntdTable, Row, Col } from "antd";
import { fonts, StyleSheet, css, colors, spacing, common } from "../../../../styles";
import SearchInput from '../SearchInput';
import translate from '../../services/translate';

class Table extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        data: PropTypes.array,
        columns: PropTypes.array.isRequired,
        title: PropTypes.string,
        onPressRow: PropTypes.func,
        pagination: PropTypes.object.isRequired,
        rowKey: PropTypes.func,
        onChange: PropTypes.func,
        hasSearch: PropTypes.bool,
        searchText: PropTypes.string,
        onSearch: PropTypes.func,
        onChangeSearchText: PropTypes.func,
        rightContent: PropTypes.element,
        customFilters: PropTypes.element,
        hasCustomFilters: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false,
        data: [],
        title: undefined,
        onPressRow: null,
        rowKey: null,
        onChange: null,
        hasSearch: false,
        searchText: undefined,
        onSearch: null,
        onChangeSearchText: null,
        rightContent: null,
        customFilters: null,
        hasCustomFilters: false
    };

    onRow = record => {
        return this.props.onPressRow ? {
            onClick: () => this.props.onPressRow(record)
        } : null;
    };

    rowKey = item => {
        return this.props.rowKey || item.id;
    };

    get renderSearch() {
        const { searchText, hasSearch, onSearch, onChangeSearchText, i18n, customFilters, hasCustomFilters } = this.props;
        return (
            hasSearch || hasCustomFilters ? (
                <Row className={css(styles.fieldsContainer)}>
                    {hasSearch ? (
                        <Col sm={16} md={12} xl={8}>
                            <SearchInput
                                value={searchText}
                                onChange={onChangeSearchText}
                                onPressEnter={onSearch}
                                onSearch={onSearch}
                                placeholder={i18n.t('companies.search')}
                            />
                        </Col>
                    ) : null}
                    {hasCustomFilters ? (
                        <>
                            {customFilters}
                        </>
                    ) : null}
                </Row>
            ) : null
        );
    }

    render() {
        const { isLoading, data, title, pagination, onChange, rightContent, hasSearch, hasCustomFilters, ...restProps } = this.props;
        return (
            <div className={css(styles.container)}>
                {title ?
                    <p className={css(styles.title)}>{title}</p> : null
                }
                {(hasSearch || hasCustomFilters) && (
                    <div className={css(common.flexRow)}>
                        <div className={css(styles.leftContent)}>
                            {this.renderSearch}
                        </div>
                        <div>
                            {rightContent}
                        </div>
                    </div>
                )}
                <AntdTable
                    {...restProps}
                    loading={isLoading}
                    dataSource={data}
                    onRow={this.onRow}
                    pagination={pagination}
                    rowKey={this.rowKey}
                    onChange={onChange}
                />
            </div>
        );
    }
}

export default translate()(Table);

const styles = StyleSheet.create({
    container: {
        width: '100%',
        '& .ant-table-thead > tr > th': {
            ...fonts.xsNormal,
            color: colors.tableHeaderText,
            backgroundColor: colors.tableHeader
        },
        '& .ant-table-row td, .ant-table-row td span': {
            ...fonts.xxsNormal,
            color: colors.tableBodyText,
            textShadow: `1px 1px 1px ${colors.textShadow}`
        },
        '& .ant-table-body': {
            overflowX: 'auto !important'
        },
        '& .ant-table-thead>tr>th, .ant-table-tbody>tr>td': {
            wordBreak: 'unset !important'
        },
        '& .ant-table-content': {
            background: colors.white,
            borderRadius: spacing.s1,
            boxShadow: `0px 0px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
            marginBottom: spacing.s0
        },
        '& .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td': {
            background: colors.white,
            marginBottom: spacing.s0
        },
        '& .ant-table-tbody > tr:hover': {
            boxShadow: `0px ${spacing.s0}px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
            cursor: 'pointer'
        },
        '& .ant-pagination-prev, .ant-pagination-next': {
            borderRadius: spacing.s3,
            boxShadow: `0px ${spacing.s0}px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
            background: colors.transparent,
            '& a': {
                background: colors.transparent
            }
        },
        '& .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link': {
            color: colors.grey,
            border: `1px solid ${colors.grey}`,
            borderRadius: spacing.s3
        },
        '& .ant-pagination-disabled, .ant-pagination-prev.ant-pagination-disabled, .ant-pagination-next.ant-pagination-disabled': {
            boxShadow: `0px ${spacing.s0}px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
            '& a': {
                border: 0
            },
        },
        ['& .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link' +
        ' .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link']: {
            color: colors.textColor,
            borderColor: colors.textColor
        },
        '& .ant-pagination-item': {
            borderRadius: spacing.s3,
            boxShadow: `0px 0px ${spacing.s2}px 1px ${colors.shadow}`,
            border: `1px solid ${colors.grey}`,
            backgroundColor: colors.transparent,
            '& a': {
                color: colors.grey
            },
            ':hover': {
                color: colors.textColor,
                borderColor: colors.primary
            },
            ':hover a': {
                color: colors.primary
            }
        },
        '& .ant-pagination-item-active': {
            backgroundColor: colors.white,
            borderColor: colors.primary,
            boxShadow: `0px 0px ${spacing.s2}px 1px ${colors.primaryTransparent}`,
            '& a': {
                color: colors.primary
            }
        },
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
        marginBottom: spacing.s3,
        marginTop: spacing.s3
    },
    leftContent: {
        flex: 1
    },
    fieldsContainer: {
        marginBottom: spacing.s3
    }
});