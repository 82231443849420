import axios from 'axios';

export const endpoints = {
    removeCustomerData: () => 'admin/encryptCustomerData',
    createNewEmailAccount: () => 'admin/createNewEmailAccount',
};

export const removeCustomerData = searchTerm => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    return axios.post(endpoints.removeCustomerData(), `"${searchTerm}"`, config);
};

export const createNewEmailAccount = () => axios.post(endpoints.createNewEmailAccount());