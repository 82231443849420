import { Col } from 'antd';
import React, { useCallback } from 'react';
import { components as Core, useTranslation } from '../../../core';
import { StyleSheet, css, spacing, colors } from '../../../../styles';
import { CompanySubscriptionPeriodTypes, SubscriptonUsageTypes } from '../../constants';

export const useUsageReportFilters = ({
    onChangeRegionType, onChangeSubscriptionPeriod, onChangeSubscriptionUsage
}) => {
    const { t } = useTranslation();

    const getCompanySubscriptionPeriodTypes = useCallback(() => {
        return [{
            name: t('dashboard.usageReport.companySubscriptionPeriodTypes.all'),
            id: CompanySubscriptionPeriodTypes.all
        }, {
            name: t('dashboard.usageReport.companySubscriptionPeriodTypes.monthly'),
            id: CompanySubscriptionPeriodTypes.monthly
        }, {
            name: t('dashboard.usageReport.companySubscriptionPeriodTypes.yearly'),
            id: CompanySubscriptionPeriodTypes.yearly
        }]
    }, []);

    const getSubscriptonUsageTypes = useCallback(() => {
        return [{
            name: t('dashboard.usageReport.subscriptonUsageTypes.all'),
            id: SubscriptonUsageTypes.all
        }, {
            name: t('dashboard.usageReport.subscriptonUsageTypes.usageBased'),
            id: SubscriptonUsageTypes.usageBased
        }, {
            name: t('dashboard.usageReport.subscriptonUsageTypes.unlimited'),
            id: SubscriptonUsageTypes.unlimited
        }]
    }, []);

    const getFilters = () => (
        <div>
            <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                <Core.Select
                    data={getCompanySubscriptionPeriodTypes()}
                    onChange={onChangeSubscriptionPeriod}
                    defaultValue={CompanySubscriptionPeriodTypes.all}
                />
            </Col>
            <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                <Core.Select
                    data={getSubscriptonUsageTypes()}
                    onChange={onChangeSubscriptionUsage}
                    defaultValue={SubscriptonUsageTypes.usageBased}
                />
            </Col>
            <Col sm={10} md={5} xl={3} className={css(styles.filterWrapper)}>
                <Core.RegionFilter
                    onChange={onChangeRegionType}
                />
            </Col>
        </div>
    );

    return {
        getFilters
    };
};

const styles = StyleSheet.create({
    filterWrapper: {
        marginTop: spacing.s0,
        marginLeft: spacing.s2
    }
});