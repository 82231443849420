import axios from 'axios';

export const endpoints = {
    signIn: () => 'auth/admin/token'
};

export const signIn = params => {
    return axios.post(endpoints.signIn(), { grantType: 'password', ...params });
};

export const refreshToken = params => {
    return axios.post(endpoints.signIn(), { grantType: 'refresh_token', ...params });
};