import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntdCheckbox } from 'antd';
import { StyleSheet, css, fonts, spacing, colors } from '../../../../styles';

class Checkbox extends React.PureComponent {
    static propTypes = {
        onToggle: PropTypes.func.isRequired,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        checked: PropTypes.bool,
        data: PropTypes.object,
        className: PropTypes.object,
        hasMargins: PropTypes.bool,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        text: undefined,
        checked: false,
        data: null,
        className: undefined,
        hasMargins: false,
        disabled: false
    };

    onToggle = () => {
        this.props.onToggle(this.props.data);
    };

    render() {
        const { hasMargins, className, ...props } = this.props;
        return (
            <div className={css(styles.container, hasMargins ? styles.marginContainer : undefined, this.props.className)}>
                <AntdCheckbox
                    {...props}
                    checked={this.props.checked}
                    onChange={this.onToggle}
                    className={css(styles.spanContainer, className)}
                    disabled={this.props.disabled}>
                    {this.props.text}
                </AntdCheckbox>
            </div>
        );
    }
}

export default Checkbox;

const styles = StyleSheet.create({
    container: {
        marginBottom: 0,
        '& .ant-checkbox-inner': {
            borderColor: colors.blackLightOpacity,
            backgroundColor: `${colors.transparent} !important`,
            width: `14px !important`,
            height: `14px !important`
        },
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: colors.white,
            borderColor: `${colors.primary} !important`,
            boxShadow: `0 1px 6px ${colors.primaryOpacity}`
        },
        '& .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner': {
            borderColor: `${colors.primary} !important`,
            outlineColor: `${colors.primary} !important`
        },
        '& .ant-checkbox-input:focus + .ant-checkbox-inner': {
            borderColor: `${colors.blackLightOpacity} !important`,
            outlineColor: `${colors.blackLightOpacity} !important`
        },
        '& .ant-checkbox-input:hover + .ant-checkbox-inner': {
            borderColor: `${colors.primary} !important`
        },
        '& .ant-checkbox-checked .ant-checkbox-inner::after': {
            borderColor: colors.primary
        },
        '& .ant-checkbox-inner:after': {
            width: '5px',
            height: '8px'
        },
        '& .ant-checkbox-disabled + span': {
            color: colors.primaryDarkOpacity
        }
    },
    marginContainer: {
        marginBottom: spacing.s1
    },
    spanContainer: {
        ...fonts.xsRegular,
        cursor: 'pointer',
        color: colors.text
    }
});