import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Tabs as AntdTabs } from "antd";
import { fonts, StyleSheet, css, colors, spacing } from "../../../../styles";
import translate from '../../services/translate';

class Tabs extends React.PureComponent {
    static propTypes = {
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                tabProps: PropTypes.object.isRequired,
                component: PropTypes.element.isRequired
            })
        ).isRequired,
        className: PropTypes.object
    };

    static defaultProps = {
        className: undefined
    };

    renderTab = item => {
        return (
            <AntdTabs.TabPane key={item.tabProps.key} {...item.tabProps}>
                {item.component}
            </AntdTabs.TabPane>
        );
    };

    render() {
        const { tabs, className, ...props } = this.props;
        return (
            <AntdTabs {...props} className={css(styles.container, className)}>
                {_.map(tabs, this.renderTab)}
            </AntdTabs>
        );
    }
}
export default translate()(Tabs);

const styles = StyleSheet.create({
    container: {
        '& .ant-tabs-top-bar': {
            borderColor: colors.transparent
        },
        '& .ant-tabs-tab': {
            padding: 0,
            paddingBottom: 6,
            ...fonts.smRegular,
            color: colors.blackOpacity
        },
        '& .ant-tabs-tab-active': {
            ...fonts.smMedium,
            color: colors.primary
        },
        '& .ant-tabs-tab+.ant-tabs-tab': {
            marginLeft: spacing.s3
        },
        '& .ant-tabs-ink-bar': {
            background: colors.primary
        },
        '& .ant-tabs-nav-animated': {
            display: 'flex',
            '& > div': {
                flex: 1,
                display: 'flex',
                '& > .ant-tabs-tab': {
                    flex: 1,
                    padding: `${spacing.s2}px 0px !important`,
                    ...fonts.mdRegular,
                    margin: '0 !important',
                    textAlign: 'center',
                    color: colors.black
                },
                '& > .ant-tabs-tab-active ': {
                    color: colors.primary
                }
            }
        }
    }
});