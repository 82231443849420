import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { components as Core, translate, constants as coreConstants, getTableProps, getPagination } from '../../../core';
import valueBuilder from '../../services/valueBuilder';
import UserDetailsModal from '../UserDetailsModal';
import UserActions from "../UsersTab/UserActions";
import UserFullNameCell from './UserFullNameCell';

export const COLUMNS = {
    fullName: 'fullName',
    email: 'email',
    lastUserAction: 'lastUserAction',
    action: 'action'
};

class Users extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        users: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        users: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.tableColumns = [{
            title: i18n.t("users.fullName"),
            key: COLUMNS.fullName,
            render: this.renderFullName
        }, {
            title: i18n.t("users.email"),
            key: COLUMNS.email,
            dataIndex: COLUMNS.email,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("users.lastUserAction"),
            key: COLUMNS.lastUserAction,
            dataIndex: COLUMNS.lastUserAction,
            render: valueBuilder.getLocalFullTime
        }, {
            title: i18n.t("companies.actions"),
            key: COLUMNS.action,
            render: this.renderActionColumn
        }];
        this.state = {
            modalUserId: null
        };
        this.props.actions.getUsers(this.props.companyId);
    }

    get pagination() {
        return getPagination(this.props.pagination);
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getUsers(
            this.props.companyId,
            getTableProps(pagination, filters, sorter)
        );
    };

    renderFullName = user => <UserFullNameCell user={user} />;

    renderActionColumn = user => {
        return (
            <UserActions user={user} />
        );
    };

    onOpenUserDetailsModal = user => {
        if (user.id !== this.state.modalUserId) {
            this.setState({ modalUserId: user.id });
        }
    };

    onCloseUserDetailsModal = () => {
        this.setState({ modalUserId: null });
    };

    render() {
        const { isLoading, users } = this.props;
        const { modalUserId } = this.state;
        return (
            <div>
                <Core.Table
                    columns={this.tableColumns}
                    data={users}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.onChangeTable}
                    onPressRow={this.onOpenUserDetailsModal}
                />
                {modalUserId && (
                    <UserDetailsModal
                        userId={modalUserId}
                        onClose={this.onCloseUserDetailsModal}
                        isUserFromAll={false}
                    />
                )
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    users: selectors.getUsersByCompany(state, ownProps.companyId),
    pagination: selectors.getUsersPagination(state, ownProps.companyId),
    isLoading: selectors.isGettingUsers(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Users));