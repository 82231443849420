import React from "react";
import PropTypes from 'prop-types';
import { StyleSheet, css, colors, fonts, spacing } from "../../../../styles";
import Tooltip from '../Tooltip';
import Icon, { ICONS } from '../Icon';

export const HelpTooltip = ({ text, iconColor, className }) => {
    const tooltipValue = () => <p className={css(styles.text)}>{text}</p>;

    return (
        <Tooltip title={tooltipValue()}>
            <div className={css(styles.container, className)}>
                <Icon
                    type={ICONS.infoCircle}
                    color={iconColor}
                    size={12}
                />
            </div>
        </Tooltip>
    );
}

HelpTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    className: PropTypes.object
};

HelpTooltip.defaultProps = {
    iconColor: colors.blackOpacity,
    className: undefined
};

const styles = StyleSheet.create({
    container: {
        marginLeft: spacing.s0,
        height: 12,
        display: 'inline-flex',
        cursor: 'pointer'
    },
    text: {
        ...fonts.smRegular,
        color: colors.whiteDefault
    }
});