import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { components as Auth, selectors as authSelectors } from '../../../auth';
import ContentLayout from './ContentLayout';
import Layout from '../Layout';

class Home extends React.PureComponent {
    static propTypes = {
        isLoggedIn: PropTypes.bool
    };

    static defaultProps = {
        isLoggedIn: false
    };

    render() {
        const { isLoggedIn } = this.props;
        return (
            <div>
                {isLoggedIn ? (
                    <Layout>
                        <ContentLayout />
                    </Layout>
                ) : <Auth.Login />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn: authSelectors.isLoggedIn(state)
});

export default connect(mapStateToProps)(Home);