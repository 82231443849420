import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import { StyleSheet, css, spacing, colors } from '../../../../styles';

class LabeledInput extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        className: PropTypes.object,
        isDisabled: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        value: undefined,
        className: undefined,
        isDisabled: false,
        onChange: null
    };

    onChange = event => {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    };

    render() {
        const { value, label, className, isDisabled } = this.props;
        return (
            <div className={css(styles.container, className)}>
                <AntdInput
                    value={value}
                    className={css(styles.input)}
                    addonBefore={label}
                    disabled={isDisabled}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}

export default LabeledInput;

const styles = StyleSheet.create({
    container: {
        marginBottom: spacing.s2,
        width: '100%'
    },
    input: {
        height: 'auto !important',
        border: 'none',
        color: colors.primary,
        backgroundColor: colors.white,
        '& .ant-input, .ant-input-group-addon': {
            borderColor: colors.border
        }
    }
});