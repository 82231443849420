import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import * as selectors from '../../selectors';
import { components as Core, translate, FIELD_TYPES } from '../../../core';
import { css, common } from '../../../../styles';
import * as actions from "../../actions";
import { SUBSCRIPTION_PLANS, ENTERPRISE_PLAN_IDS } from "../../constants";
import { valueBuilder } from "../../services";

const FIELDS = {
    subscriptionPlanId: 'subscriptionPlanId',
    manualSubscriptionExpire: 'manualSubscriptionExpire',
    ticketsNumber: 'ticketsNumber'
};

class ManualSubscriptionModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        company: PropTypes.object,
        isEnterprise: PropTypes.bool,
        isSettingManualSubscription: PropTypes.bool,
        onClose: PropTypes.func.isRequired
    };

    static defaultProps = {
        company: null,
        isSettingManualSubscription: false,
        isEnterprise: false
    };

    constructor(props) {
        super(props);
        this.state = {
            subscriptionPlanId: this.props.company.subscriptionPlanId ? this.props.company.subscriptionPlanId.toUpperCase() : null,
            manualSubscriptionExpire: this.props.company.isManualSubscribed && this.props.company.manualSubscriptionExpire ?
                moment(this.props.company.manualSubscriptionExpire) : null,
            ticketsNumber: this.props.isEnterprise ? this.props.company.ticketsLimit : 0
        }
    }

    get isEnterpriseSelected() {
        return _.includes(ENTERPRISE_PLAN_IDS, this.state.subscriptionPlanId);
    }

    get fields() {
        const { i18n } = this.props;
        let fields = [{
            fieldName: FIELDS.subscriptionPlanId,
            label: i18n.t('subscriptionPlan'),
            fieldType: FIELD_TYPES.SELECT,
            options: SUBSCRIPTION_PLANS,
            onChange: this.onChangeSubscriptionPlanId,
            placeholder: i18n.t('companies.subscriptionPlanSelectPlaceholder'),
        }, {
            fieldName: FIELDS.manualSubscriptionExpire,
            label: i18n.t('companies.manualSubscriptionExpire'),
            render: valueBuilder.getLocalFullTime,
            onChange: this.onChangeExpireDate,
            fieldType: FIELD_TYPES.DATE_PICKER
        }];
        if (this.isEnterpriseSelected) {
            fields = [
                ...fields, {
                    fieldName: FIELDS.ticketsNumber,
                    label: i18n.t('companies.enterpriseFields.numberOfTickets'),
                    onChange: this.onChangeTicketsNumber,
                    fieldType: FIELD_TYPES.INPUT_NUMBER
                }
            ]
        }
        return fields;
    }

    onChangeTicketsNumber = ticketsNumber => this.setState({ ticketsNumber });

    onChangeExpireDate = manualSubscriptionExpire => this.setState({ manualSubscriptionExpire });

    onChangeSubscriptionPlanId = subscriptionPlanId => this.setState({ subscriptionPlanId });

    onApplySubscription = () => {
        this.props.actions.setupManualSubsription(this.props.companyId, this.state);
    }

    render() {
        const { i18n, onClose, isSettingManualSubscription } = this.props;
        const { ticketsNumber, manualSubscriptionExpire, subscriptionPlanId } = this.state;
        return (
            <Core.Modal
                isVisible={true}
                onClose={onClose}
                title={i18n.t('companies.enterpriseSubscription')}>
                <div>
                    <Core.FieldsGenerator
                        fields={this.fields}
                        data={this.state}
                    />
                    {subscriptionPlanId && manualSubscriptionExpire && (this.isEnterpriseSelected ? ticketsNumber : true) ? (
                        <div className={css(common.flexRowCenter)}>
                            <Core.Button
                                label={i18n.t('companies.enterpriseFields.apply')}
                                onPress={this.onApplySubscription}
                                isLoading={isSettingManualSubscription}
                                isDisabled={isSettingManualSubscription}
                            />
                        </div>
                    ) : null}
                </div>
            </Core.Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const company = selectors.getCompany(state, ownProps.companyId);
    return {
        company,
        isEnterprise: selectors.isEnterprise(state, company.subscriptionPlanId),
        isSettingManualSubscription: selectors.isSettingManualSubscription(state, ownProps.companyId)
    }
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ManualSubscriptionModal));