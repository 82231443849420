import _ from 'lodash';
import { NAME } from './constants';
import * as actionTypes from './actionTypes';
import { selectors as coreSelectors } from '../core';

export const isLoggedIn = state => !!state[NAME].tokens;
export const getAccessToken = state => _.get(state[NAME].tokens, 'accessToken');
export const getRefreshToken = state => _.get(state[NAME].tokens, 'refreshToken');
export const getTokenExpiryDate = state => _.get(state[NAME].tokens, 'expiresIn');

export const isSigningIn = state => coreSelectors.isLoading(state, actionTypes.SIGN_IN.NAME);