export const NAME = 'companies';

export const COMPANY_REGIONS = {
    UK: 0,
    DE: 1
}

export const SUBSCRIPTION_PLANS = [
    { text: 'S-Monthly-GBP', value: 'BB9E868F-F262-406C-A2EA-1A782967D9FB' },
    { text: 'S-AI-GBP', value: '3C394493-B72F-4682-8E0A-73876690E6B0' },
    { text: 'S-Monthly-EUR', value: '47D67850-FC9A-43DF-A8E9-7A97E084362E' },
    { text: 'S-AI-EUR', value: '3FFF7E43-540F-4FB8-8EF5-9C7E8EA28EFE' },
    { text: 'S-Monthly-USD', value: '92E9E9F7-4C4B-441C-AC09-30FC431AFF1E' },
    { text: 'S-AI-USD', value: '0F084C1B-009F-409D-BA9A-C2A79413F102' },
    { text: 'M-Monthly-GBP', value: '849BEB60-04AC-49CF-8844-D5A38736469D' },
    { text: 'M-AI-GBP', value: '19B13425-5805-4F8A-9F5B-821D0EF0E48D' },
    { text: 'M-Monthly-EUR', value: '9AB31E77-EE34-4BE1-958C-8AAB1BC038F6' },
    { text: 'M-AI-EUR', value: '353FCA33-98EF-4432-833B-6B2E83467A55' },
    { text: 'M-Monthly-USD', value: '5A0BF626-5CEB-4EBA-BD3D-9E8485CC0537' },
    { text: 'M-AI-USD', value: '50A48A3A-F66F-4809-8564-3D822D6FBAA9' },
    { text: 'L-Monthly-GBP', value: '4853DBDC-DF91-4233-8A92-8FF81B705B62' },
    { text: 'L-AI-GBP', value: '2541CB95-F048-4A3C-B145-799BFAEE4E70' },
    { text: 'L-Monthly-EUR', value: 'FBA831C0-8D8E-43E3-B611-D83406A6DA47' },
    { text: 'L-AI-EUR', value: '4B141C4E-BED5-4A54-8AA5-8C79B3166653' },
    { text: 'L-Monthly-USD', value: '126A4F75-A37A-4A6A-8F1C-A0390B7997C4' },
    { text: 'L-AI-USD', value: '48D49A50-DAC9-4911-88A0-E27C82B4586B' },
    { text: 'XL-Monthly', value: 'AEF3B35A-5A2E-4E73-98C4-3A942D2D5CF7' },
    { text: 'XL-Annual', value: '840a4f1c-8b70-4a9d-a380-88dc0465c01e' }
];

export const ENTERPRISE_PLAN_IDS = [
    'AEF3B35A-5A2E-4E73-98C4-3A942D2D5CF7',
    '840A4F1C-8B70-4A9D-A380-88DC0465C01E'
];

export const RATING_TYPES = {
    promoters: 0,
    detractors: 1,
    passives: 2
};

export const FEATURES = [
    { name: 'companies.features.chat', value: 1 << 0 },
    { name: 'companies.features.channelSpecificSignatures', value: 1 << 1 },
    { name: 'companies.features.whatsApp', value: 1 << 2 },
    { name: 'companies.features.customFields', value: 1 << 3 },
    { name: 'companies.features.twoFactorAuthentication', value: 1 << 4 },
    { name: 'companies.features.dedicatedAccountManager', value: 1 << 5 },
    { name: 'companies.features.agentCollisionsDetection', value: 1 << 6 },
    { name: 'companies.features.lifetimeHistoricalData', value: 1 << 7 },
    { name: 'companies.features.channelSpecificTemplates', value: 1 << 8 },
    { name: 'companies.features.autoresponses', value: 1 << 9 },
    { name: 'companies.features.textSnippets', value: 1 << 10 },
    { name: 'companies.features.translations', value: 1 << 11 },
    { name: 'companies.features.apiAccess', value: 1 << 12 },
    { name: 'companies.features.advancedReporting', value: 1 << 13 },
    { name: 'companies.features.smartFilters', value: 1 << 14 },
    { name: 'companies.features.userPermissions', value: 1 << 15 },
    { name: 'companies.features.betaFeatures', value: 1 << 16 },
    { name: 'companies.features.cases', value: 1 << 17 },
    { name: 'companies.features.feedbacks', value: 1 << 18 },
    { name: 'companies.features.orderSync', value: 1 << 19 },
    { name: 'companies.features.productSync', value: 1 << 20 },
    { name: 'companies.features.customerSync', value: 1 << 21 },
    { name: 'companies.features.feedbackRequests', value: 1 << 22 },
    { name: 'companies.features.draftSaving', value: 1 << 23 },
    { name: 'companies.features.calls', value: 1 << 24 },
    { name: 'companies.features.userRoles', value: 1 << 25 },
    { name: 'companies.features.ebayInTicketCancellationReturnsRefunds', value: 1 << 26 },
    { name: 'companies.features.customisedPrioritiesAndSLA', value: 1 << 27 },
    { name: 'companies.features.liveChats', value: 1 << 28 },
    { name: 'companies.features.ai', value: 1 << 29 }
];

export const MARKETPLACE_REGION_ENUM = {
    Empty: 0,
    NorthAmerica: 1,
    Brazil: 2,
    Europe: 3,
    India: 4,
    China: 5,
    Japan: 6,
    Australia: 7
};

export const AMAZON_MARKETPLACE_COUNTRY_ENUM = {
    Empty: 0,
    US: 1,
    Canada: 2,
    Mexico: 3,
    Brazil: 4,
    Spain: 5,
    UK: 6,
    France: 7,
    Netherlands: 8,
    Germany: 9,
    Italy: 10,
    Sweden: 11,
    Poland: 12,
    Turkey: 13,
    UAE: 14,
    India: 15,
    Singapore: 16,
    Australia: 17,
    Japan: 18,
    Egypt: 19
};

export const EBAY_MARKETPLACE_COUNTRY_ENUM = {
    Empty: 0,
    US: 1,
    Canada: 2,
    Spain: 5,
    UK: 6,
    France: 7,
    Netherlands: 8,
    Germany: 9,
    Italy: 10,
    Poland: 12,
    Singapore: 16,
    Australia: 17,
    Austria: 20,
    Switzerland: 21,
    HongKong: 22,
    Ireland: 23
};

export const USER_STATUSES = {
    notActivated: 0,
    active: 1,
    blocked: 2
}

export const SMTP_PROTOCOL_TYPES = {
    Pop: 0,
    Imap: 1
};

export const PREDEFINED_SMTP_PROTOCOL_ADDRESSES = {
    custom: null,
    gmail: 'imap.zoho.eu',
    zoho: 'imap.zoho.eu',
    yandex: 'imap.yandex.com',
    outlook: 'outlook.office365.com'
};

export const EXPIRED_SHOPIFY_SUBSCRIPTION_STATUSES = [
    'frozen', 'closed', 'cancelled', 'declined', 'expired'
];