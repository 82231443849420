import * as actionTypes from './actionTypes';

const initialState = {
    removedData: { },
    newEmailAccount: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REMOVE_CUSTOMER_DATA.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                removedData: data
            };
        }
        case actionTypes.CLEAR_REMOVED_CUSTOMERS_DATA.SUCCESS:
            return {
                ...state,
                removedData: {}
            }
        case actionTypes.CREATE_NEW_EMAIL.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                newEmailAccount: data
            }
        }
        default:
            return state;
    }
};
