import React from 'react';
import PropTypes from 'prop-types';
import { Icon as AntdIcon } from 'antd';
import { spacing, colors } from '../../../../styles';

export const ICONS = {
    lock: 'lock',
    unlock: 'unlock',
    close: 'close',
    infoCircle: 'info-circle',
    save: 'save',
    check: 'check',
    download: 'download',
    star: 'star',
    plus: 'plus',
    arrowUp: 'arrowUp',
    arrowDown: 'arrowDown',
    dollar: 'dollar',
    userAdd: 'user-add',
    dislike: 'dislike',
};

class Icon extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired,
        size: PropTypes.number,
        color: PropTypes.string
    };

    static defaultProps = {
        size: spacing.s3,
        color: colors.primary
    };

    get iconStyle() {
        return {
            fontSize: this.props.size,
            color: this.props.color
        };
    }

    render() {
        return (
            <AntdIcon
                {...this.props}
                style={this.iconStyle}
            />
        );
    }
}

export default Icon;