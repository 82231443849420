import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from '../../selectors';
import { components as Core, translate } from '../../../core';
import valueBuilder from '../../services/valueBuilder';
import UserActivities from '../UserActivities';
import UserNotifications from "../UserNotifications";

const FIELDS = {
    fullName: 'fullName',
    email: 'email',
    lastLogin: 'lastLogin',
    phone: 'phone',
    preferedLanguageCode: 'preferedLanguageCode',
};

const COLLAPSE_ITEMS = {
    activities: 'activities'
};

class UserDetailsModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        userId: PropTypes.string.isRequired,     //eslint-disable-line
        user: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.fields = [{
            fieldName: FIELDS.fullName,
            label: i18n.t('users.fullName')
        }, {
            fieldName: FIELDS.email,
            label: i18n.t('users.email')
        }, {
            fieldName: FIELDS.lastLogin,
            label: i18n.t('users.lastLogin'),
            render: valueBuilder.getLocalFullTime
        }, {
            fieldName: FIELDS.phone,
            label: i18n.t('users.phone')
        }, {
            fieldName: FIELDS.preferedLanguageCode,
            label: i18n.t('users.preferedLanguageCode')
        }];
    }

    get renderActivitiesTable() {
        return (
            <UserActivities userId={this.props.userId} />
        );
    }

    get renderNotificationsTable() {
        return (
            <UserNotifications userId={this.props.userId} />
        );
    }

    get collapseData() {
        const { i18n } = this.props;
        return [{
            key: COLLAPSE_ITEMS.activities,
            title: i18n.t('users.userActivities'),
            content: this.renderActivitiesTable
        }];
    }

    get collapseNotifications() {
        const { i18n } = this.props;
        return [{
            key: COLLAPSE_ITEMS.activities,
            title: i18n.t('users.userNotifications'),
            content: this.renderNotificationsTable
        }];
    }

    render() {
        const { i18n, onClose, user } = this.props;
        return (
            <Core.Modal
                isVisible={true}
                onClose={onClose}
                title={i18n.t('users.userDetails')}>
                <div>
                    <Core.FieldsGenerator
                        fields={this.fields}
                        data={user}
                    />
                    <Core.Collapse data={this.collapseData} />
                    <Core.Collapse data={this.collapseNotifications} />
                </div>
            </Core.Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: ownProps.isUserFromAll ? selectors.getUserFromAll(state, ownProps.userId) : selectors.getUser(state, ownProps.userId)
});

export default connect(mapStateToProps)(translate()(UserDetailsModal));