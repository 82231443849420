import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../core';
import { StyleSheet, css, fonts, colors, spacing } from '../../../../styles';

class Footer extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className={css(styles.container)}>
                <p className={css(styles.text)}>{this.props.i18n.t('footerText')}</p>
            </div>
        );
    }
}

export default translate()(Footer);

const styles = StyleSheet.create({
    container: {
        padding: spacing.s3
    },
    text: {
        ...fonts.smLight,
        color: colors.primaryLight
    }
});