import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as AntdTooltip } from 'antd';

class Tooltip extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        placement: PropTypes.string
    };

    static defaultProps = {
        placement: 'top'
    };

    render() {
        return (
            <AntdTooltip {...this.props} />
        );
    }
}

export default Tooltip;