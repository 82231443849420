import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { routerReducer } from 'react-router-redux';
import { reducer as coreReducer, constants as coreConstants } from '../modules/core';
import { reducer as authReducer, constants as authConstants } from '../modules/auth';
import { reducer as companiesReducer, constants as companiesConstants } from '../modules/companies';
import { reducer as sharedReducer, constants as sharedConstants } from '../modules/shared';
import { reducer as dashboardReducer, constants as dashboardConstants } from '../modules/dashboard';

const ROUTER_NAME = 'router';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        ROUTER_NAME,
        coreConstants.NAME,
        authConstants.NAME,
        companiesConstants.NAME,
        sharedConstants.NAME,
        dashboardConstants.NAME,
    ]
};

const rootReducer = combineReducers({
    [ROUTER_NAME]: routerReducer,
    [coreConstants.NAME]: coreReducer,
    [authConstants.NAME]: authReducer,
    [companiesConstants.NAME]: companiesReducer,
    [sharedConstants.NAME]: sharedReducer,
    [dashboardConstants.NAME]: dashboardReducer
});

export default persistReducer(persistConfig, rootReducer);
