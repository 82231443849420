import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table } from "antd";
import { connect } from 'react-redux';
import { components as Core, translate } from '../../../core';
import { StyleSheet, css, spacing } from '../../../../styles';
import AccountSetupCheckbox from "../AccountSetupCheckbox";
import * as selectors from '../../selectors';

const COLUMNS = {
    name: 'name',
    view: 'view'
};

class AccountSettingTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        data: PropTypes.array,
        selected: PropTypes.array
    }

    static defaultProps = {
        data: [],
        isLoading: false,
        selected: []
    };

    get tableColumns() {
        const { i18n } = this.props;
        return [{
            title: i18n.t('name'),
            key: COLUMNS.name,
            dataIndex: 'name',
            width: '80%'
        }, {
            title: i18n.t('installed'),
            key: COLUMNS.view,
            render: this.renderCheckbox,
            align: 'center'
        }];
    }

    get isAllSelected() {
        return this.props.selected && this.props.selected.length === (_.filter(this.props.data, item => item.isExist !== true)).length;
    }

    onToggle = selected => {
        this.props.onChange(selected);
    };

    onSelectAll = () => {
        if (this.isAllSelected) {
            this.props.onChange([]);
        }
        else {
            this.props.onChange(_.filter(this.props.data, item => item.isExist !==true));
        }
    }

    renderCheckbox = item => (
        <AccountSetupCheckbox
            item={item}
            selected={this.props.selected}
            onToggleCallback={this.onToggle}
        />
    );

    render() {
        const { isLoading, data, i18n } = this.props;
        return (
            <div className={css(styles.container)}>
                <Core.Checkbox
                    text={i18n.t('setupSettings.selectAll')}
                    className={styles.checkbox}
                    onToggle={this.onSelectAll}
                    checked={this.isAllSelected}
                />

                <Table
                    columns={this.tableColumns}
                    dataSource={data}
                    pagination={false}
                    bordered={true}
                    showHeader={false}
                    loading={isLoading}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLoadingSetupSettings: selectors.isLoadingSetupSettings(state, ownProps.companyId)
});

export default connect(mapStateToProps)(translate()(AccountSettingTable))

const styles = StyleSheet.create({
    container: {
        '& .ant-table-tbody > tr > td': {
            padding: spacing.s1
        },
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: spacing.s0
    }
});