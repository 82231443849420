/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as selectors from '../../selectors';
import { components as Core, translate, FIELD_TYPES } from '../../../core';
import valueBuilder from '../../services/valueBuilder';
import { css, common, StyleSheet, spacing } from '../../../../styles';
import * as actions from "../../actions";
import DeleteCompanyModal from '../DeleteCompanyModal';
import AccountSetupModal from '../AccountSetupModal';
import ManualSubscriptionModal from '../ManualSubscriptionModal';
import CompanyFeaturesModal from '../CompanyFeaturesModal';
import CustomizeUIModal from '../CustomizeUIModal';

const FIELDS = {
    stripeSubscriptionId: 'stripeSubscriptionId',
    companyName: 'companyName',
    email: 'email',
    trialExpire: 'trialExpire',
    notifiedAboutCompanyRemovingDate: 'notifiedAboutCompanyRemovingDate',
    country: 'country',
    state: 'state',
    city: 'city',
    region: 'region',
    appLanguage: 'appLanguage',
    address1: 'address1',
    address2: 'address2',
    zipCode: 'zipCode',
    billingCountry: 'billingCountry',
    billingState: 'billingState',
    billingCity: 'billingCity',
    billingAddress1: 'billingAddress1',
    billingAddress2: 'billingAddress2',
    billingZipCode: 'billingZipCode',
    workPhone: 'workPhone',
    showUsedTicketsCount: 'showUsedTicketsCount',
    billingUsage: 'billingUsage',
    isOldSubscription: 'isOldSubscription',
    failedSubscription: 'failedSubscription',
    dataRemoveCollapse: 'dataRemoveCollapse',
    isVerified: 'isVerified',
    isManuallyVerified: 'isManuallyVerified',
    showPopupNotifications: 'showPopupNotifications',
    locationDetailsCollapse: 'locationDetailsCollapse',
    shopifyBillingInfoCollapse: 'shopifyBillingInfo',
    subscriptionDate: 'subscriptionDate',
    subscriptionPlan: 'subscriptionPlanId',
    paymentCustomerId: 'paymentCustomerId',
    isManualSubscribed: 'isManualSubscribed',
    stripeSubscriptionDetailsCollapse: 'stripeSubscriptionDetailsCollapse',
    integrationsCount: 'integrationsCount',
    endlessIntegrations: 'endlessIntegrations'
};

const DATE_FIELDS = [
    FIELDS.trialExpire,
    FIELDS.notifiedAboutCompanyRemovingDate
];

const DATE_TIME_FIELDS = [
    FIELDS.subscriptionDate
];

const SHOPIFY_BILLING_INFO_FIELDS = {
    shopifyBillingShopUrl: 'shopifyBillingShopUrl',
    status: 'status',
    activationDate: 'activationDate',
    createdDate: 'createdDate',
    subscriptionId: 'subscriptionId',
    tempReplycoSubscriptionId: 'tempReplycoSubscriptionId'
};

const DATA_REMOVE_FIELDS = {
    dataLifetime: 'dataLifetime',
    ordersDataLifetime: 'ordersDataLifetime',
    feedbacksDataLifetime: 'feedbacksDataLifetime',
    archiveThreadsLifetime: 'archiveThreadsLifetime',
    deletedDataLifetime: 'deletedDataLifetime'
};


const MODAL_WIDTH = 580;

class CompanyDetailsModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        company: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        isUpdatingCompany: PropTypes.bool,
        isDeleting: PropTypes.bool,
        subscriptionPlans: PropTypes.array
    };

    static defaultProps = {
        company: null,
        isUpdatingCompany: false,
        isDeleting: false,
        subscriptionPlans: []
    };

    constructor(props) {
        super(props);
        const { trialExpire, showUsedTicketsCount, billingUsage, notifiedAboutCompanyRemovingDate,
            isOldSubscription, failedSubscription, dataLifetime, ordersDataLifetime, feedbacksDataLifetime, deletedDataLifetime,
            archiveThreadsLifetime, isManuallyVerified, isVerified, showPopupNotifications, stripeSubscriptionId, region,
            appLanguage, integrationsCount, endlessIntegrations } = this.props.company;
        this.state = {
            updatedValues: {
                trialExpire,
                notifiedAboutCompanyRemovingDate,
                showUsedTicketsCount,
                billingUsage,
                isOldSubscription,
                failedSubscription,
                dataLifetime,
                ordersDataLifetime,
                feedbacksDataLifetime,
                archiveThreadsLifetime,
                deletedDataLifetime,
                isManuallyVerified,
                isVerified,
                showPopupNotifications,
                stripeSubscriptionId,
                region,
                appLanguage,
                integrationsCount,
                endlessIntegrations
            },
            isVisibleDeleteModal: false,
            isVisibleAccountSetupModal: false,
            isVisibleEnterpriseModal: false,
            isVisibleFeaturesModal: false,
            isVisibleCustomizeUIModal: false,
        };
        if (!props.subscriptionPlans.length) {
            this.props.actions.getSubscriptionPlans();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeleting && !this.props.isDeleting && !this.props.company) {
            this.props.onClose();
        }
    }

    get fields() {
        const { i18n, company } = this.props;
        return [{
            fieldName: FIELDS.stripeSubscriptionDetailsCollapse,
            label: i18n.t('companies.stripeSubscriptionDetails'),
            fieldType: FIELD_TYPES.COLLAPSE,
            fields: this.stripeSubscriptionFields,
            isVisible: !_.get(company, 'shopifyBillingInfo', false)
        }, {
            fieldName: FIELDS.shopifyBillingInfoCollapse,
            label: i18n.t('companies.shopifyBillingInfoCollapse'),
            fieldType: FIELD_TYPES.COLLAPSE,
            fields: this.shopifyBillingInfoFields,
            isVisible: _.get(company, 'shopifyBillingInfo', false)
        }, {
            fieldName: FIELDS.companyName,
            label: i18n.t('companies.companyName')
        }, {
            fieldName: FIELDS.email,
            label: i18n.t('companies.email')
        }, {
            fieldName: FIELDS.region,
            label: i18n.t('region'),
            fieldType: FIELD_TYPES.SELECT,
            options: this.regionOptions,
            onChange: this.onChangeProperty(FIELDS.region)
        }, {
            fieldName: FIELDS.appLanguage,
            label: i18n.t('companies.appLanguage'),
            fieldType: FIELD_TYPES.SELECT,
            options: this.appLanguageOptions,
            onChange: this.onChangeProperty(FIELDS.appLanguage)
        }, {
            fieldName: FIELDS.trialExpire,
            label: i18n.t('companies.trialExpire'),
            render: valueBuilder.getLocalFullTime,
            onChange: this.onChangeProperty(FIELDS.trialExpire),
            fieldType: FIELD_TYPES.DATE_PICKER
        }, {
            fieldName: FIELDS.notifiedAboutCompanyRemovingDate,
            label: i18n.t('companies.notifiedAboutCompanyRemovingDate'),
            render: valueBuilder.getLocalFullTime,
            onChange: this.onChangeProperty(FIELDS.notifiedAboutCompanyRemovingDate),
            fieldType: FIELD_TYPES.DATE_PICKER
        }, {
            fieldName: FIELDS.integrationsCount,
            label: i18n.t('companies.integrationsCount'),
            onChange: this.onChangeProperty(FIELDS.integrationsCount),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            min: 0
        }, {
            fieldName: FIELDS.endlessIntegrations,
            label: i18n.t('companies.endlessIntegrations'),
            onChange: this.onChangeProperty(FIELDS.endlessIntegrations),
            fieldType: FIELD_TYPES.CHECKBOX,
        }, {
            fieldName: FIELDS.showUsedTicketsCount,
            label: i18n.t('companies.showUsedTicketsCount'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.showUsedTicketsCount)
        }, {
            fieldName: FIELDS.billingUsage,
            label: i18n.t('companies.billingUsage'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.billingUsage)
        }, {
            fieldName: FIELDS.isOldSubscription,
            label: i18n.t('companies.isOldSubscription'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.isOldSubscription)
        }, {
            fieldName: FIELDS.failedSubscription,
            label: i18n.t('companies.failedSubscription'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.failedSubscription)
        }, {
            fieldName: FIELDS.isVerified,
            label: i18n.t('companies.verified'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.isVerified)
        }, {
            fieldName: FIELDS.isManuallyVerified,
            label: i18n.t('companies.isManuallyVerified'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.isManuallyVerified)
        }, {
            fieldName: FIELDS.showPopupNotifications,
            label: i18n.t('companies.showPopupNotifications'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.showPopupNotifications)
        }, {
            fieldName: FIELDS.dataRemoveCollapse,
            label: i18n.t('companies.dataRemoveCollapse'),
            fieldType: FIELD_TYPES.COLLAPSE,
            fields: this.dataRemoveCollapse
        }, {
            fieldName: FIELDS.locationDetailsCollapse,
            label: i18n.t('companies.locationDetails'),
            fieldType: FIELD_TYPES.COLLAPSE,
            fields: this.locationDetialsFields
        }];
    }

    get stripeSubscriptionFields() {
        const { i18n } = this.props;
        return [{
            fieldName: FIELDS.stripeSubscriptionId,
            label: i18n.t('companies.stripeSubscriptionId'),
            onChange: this.onChangeProperty(FIELDS.stripeSubscriptionId)
        }, {
            fieldName: FIELDS.subscriptionDate,
            label: i18n.t('companies.subscriptionDate'),
            fieldType: FIELD_TYPES.DATE_PICKER,
            showTime: true,
            onChange: this.onChangeProperty(FIELDS.subscriptionDate)
        }, {
            fieldName: FIELDS.subscriptionPlan,
            label: i18n.t('companies.subscriptionPlan'),
            fieldType: FIELD_TYPES.SELECT,
            options: this.subscriptionPlanOptions,
            placeholder: i18n.t('companies.subscriptionPlanSelectPlaceholder'),
            onChange: this.onChangeProperty(FIELDS.subscriptionPlan)
        }, {
            fieldName: FIELDS.paymentCustomerId,
            label: i18n.t('companies.paymentCustomerId'),
            fieldType: FIELD_TYPES.INPUT,
            onChange: this.onChangeProperty(FIELDS.paymentCustomerId)
        }, {
            fieldName: FIELDS.isManualSubscribed,
            label: i18n.t('companies.isManualSubscribed'),
            fieldType: FIELD_TYPES.CHECKBOX,
            onChange: this.onChangeProperty(FIELDS.isManualSubscribed)
        }];
    }

    get dataRemoveCollapse() {
        const { i18n } = this.props;
        return [{
            fieldName: DATA_REMOVE_FIELDS.dataLifetime,
            label: i18n.t('companies.dataLifetime'),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            onChange: this.onChangeProperty(DATA_REMOVE_FIELDS.dataLifetime)
        }, {
            fieldName: DATA_REMOVE_FIELDS.ordersDataLifetime,
            label: i18n.t('companies.ordersDataLifetime'),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            onChange: this.onChangeProperty(DATA_REMOVE_FIELDS.ordersDataLifetime)
        }, {
            fieldName: DATA_REMOVE_FIELDS.feedbacksDataLifetime,
            label: i18n.t('companies.feedbacksDataLifetime'),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            onChange: this.onChangeProperty(DATA_REMOVE_FIELDS.feedbacksDataLifetime)
        }, {
            fieldName: DATA_REMOVE_FIELDS.archiveThreadsLifetime,
            label: i18n.t('companies.archiveThreadsLifetime'),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            onChange: this.onChangeProperty(DATA_REMOVE_FIELDS.archiveThreadsLifetime)
        }, {
            fieldName: DATA_REMOVE_FIELDS.deletedDataLifetime,
            label: i18n.t('companies.deletedDataLifetime'),
            fieldType: FIELD_TYPES.INPUT_NUMBER,
            onChange: this.onChangeProperty(DATA_REMOVE_FIELDS.deletedDataLifetime)
        }];
    }

    get locationDetialsFields() {
        const { i18n } = this.props;
        return [{
            fieldName: FIELDS.workPhone,
            label: i18n.t('companies.workPhone')
        }, {
            fieldName: FIELDS.country,
            label: i18n.t('companies.country')
        }, {
            fieldName: FIELDS.state,
            label: i18n.t('companies.state')
        }, {
            fieldName: FIELDS.city,
            label: i18n.t('companies.city')
        }, {
            fieldName: FIELDS.address1,
            label: i18n.t('companies.address1')
        }, {
            fieldName: FIELDS.address2,
            label: i18n.t('companies.address2')
        }, {
            fieldName: FIELDS.zipCode,
            label: i18n.t('companies.zipCode')
        }, {
            fieldName: FIELDS.billingCountry,
            label: i18n.t('companies.billingCountry')
        }, {
            fieldName: FIELDS.billingState,
            label: i18n.t('companies.billingState')
        }, {
            fieldName: FIELDS.billingCity,
            label: i18n.t('companies.billingCity')
        }, {
            fieldName: FIELDS.billingAddress1,
            label: i18n.t('companies.billingAddress1')
        }, {
            fieldName: FIELDS.billingAddress2,
            label: i18n.t('companies.billingAddress2')
        }, {
            fieldName: FIELDS.billingZipCode,
            label: i18n.t('companies.billingZipCode')
        }];
    }

    get shopifyBillingInfoFields() {
        const { i18n } = this.props;
        return [{
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.shopifyBillingShopUrl,
            label: i18n.t('companies.shopifyBillingInfo.shopUrl'),
            sourceField: FIELDS.shopifyBillingInfoCollapse
        }, {
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.status,
            label: i18n.t('companies.shopifyBillingInfo.status'),
            sourceField: FIELDS.shopifyBillingInfoCollapse
        }, {
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.activationDate,
            label: i18n.t('companies.shopifyBillingInfo.activationDate'),
            sourceField: FIELDS.shopifyBillingInfoCollapse,
            fieldType: FIELD_TYPES.DATE_PICKER
        }, {
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.createdDate,
            label: i18n.t('companies.shopifyBillingInfo.createdDate'),
            sourceField: FIELDS.shopifyBillingInfoCollapse,
            fieldType: FIELD_TYPES.DATE_PICKER
        }, {
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.subscriptionId,
            label: i18n.t('companies.shopifyBillingInfo.subscriptionId'),
            sourceField: FIELDS.shopifyBillingInfoCollapse
        }, {
            fieldName: SHOPIFY_BILLING_INFO_FIELDS.tempReplycoSubscriptionId,
            label: i18n.t('companies.shopifyBillingInfo.tempReplycoSubscriptionId'),
            sourceField: FIELDS.shopifyBillingInfoCollapse
        }];
    }

    get subscriptionPlanOptions() {
        const { subscriptionPlans } = this.props;
        return _.map(subscriptionPlans, subscriptionPlan => ({
            text: `${subscriptionPlan.name}  |  ${subscriptionPlan.price}`,
            value: subscriptionPlan.id
        }))
    }

    get regionOptions() {
        return [
            { text: 'England', value: 0 },
            { text: 'German', value: 1 }
        ]
    }

    get appLanguageOptions() {
        return [
            { text: 'English', value: 5 },
            { text: 'German', value: 7 }
        ]
    }

    onChangeProperty = property => value => {
        this.setState(prevState => ({
            updatedValues: {
                ...prevState.updatedValues,
                [property]: value
            }
        }));
    }

    get showUpdateButton() {
        const { updatedValues } = this.state;
        const { company } = this.props;
        if (updatedValues[FIELDS.integrationsCount] === null ||
            updatedValues[FIELDS.integrationsCount] === undefined) return false;
        // isUpdatedState
        return !_.every(_.keys(updatedValues), key => {
            if (key === FIELDS.integrationsCount && company[key] && !updatedValues[key]) return false;
            if (DATE_FIELDS.includes(key)) {
                return valueBuilder.getDate(updatedValues[key]) === valueBuilder.getDate(company[key]);
            }
            if (DATE_TIME_FIELDS.includes(key)) {
                return valueBuilder.getFullTime(updatedValues[key]) === valueBuilder.getFullTime(company[key]);
            }
            return updatedValues[key] === company[key];
        });
    }

    onUpdateCompany = () => {
        this.props.actions.updateCompany({
            ...this.props.company,
            ...this.state.updatedValues
        });
    };

    onDeleteCompany = () => {
        this.setState({ isVisibleDeleteModal: true });
    };

    onCloseDeleteCompanyModal = () => this.setState({ isVisibleDeleteModal: false });

    onOpenAccountSetupModal = () => this.setState({ isVisibleAccountSetupModal: true });

    onCloseAccountSetupModal = () => {
        this.setState({ isVisibleAccountSetupModal: false });
        this.props.onClose();
    }

    onOpenEnterpriseModal = () => {
        this.setState({ isVisibleEnterpriseModal: true });
    }

    onCloseEnterpriseModal = () => {
        this.setState({ isVisibleEnterpriseModal: false });
        this.props.onClose();
    }

    onOpenFeaturesModal = () => {
        this.setState({ isVisibleFeaturesModal: true });
    }

    onOpenCustomizeUIModal = () => {
        this.setState({ isVisibleCustomizeUIModal: true });
    }

    onCloseFeaturesModal = () => {
        this.setState({ isVisibleFeaturesModal: false });
        this.props.onClose();
    }

    onRemoveSubscription = () => {
        this.props.actions.removeSubsription(this.props.companyId)
    }

    get renderTitle() {
        const { i18n } = this.props;
        return (
            <>
                <div className={css(styles.title)} >
                    {i18n.t('companies.companyDetails')}
                </div>
                <div className={css(common.flexRowCenter, styles.buttonRow)}>
                    <Core.Button
                        label={i18n.t('companies.accountSetup')}
                        onPress={this.onOpenAccountSetupModal}
                        className={styles.button}
                    />
                    <Core.Button
                        label={i18n.t('companies.enterpriseSubscription')}
                        onPress={this.onOpenEnterpriseModal}
                        className={styles.button}
                    />
                    <Core.Button
                        label={i18n.t('companies.features.features')}
                        onPress={this.onOpenFeaturesModal}
                        className={styles.button}
                    />
                    <Core.PopConfirm onConfirm={this.onRemoveSubscription}>
                        <a>
                            <Core.Button
                                label={i18n.t('companies.removeSubscription')}
                            />
                        </a>
                    </Core.PopConfirm>
                    <Core.Button
                        label={i18n.t('companies.customizeUI.customizeUI')}
                        onPress={this.onOpenCustomizeUIModal}
                        className={styles.button}
                    />
                </div>
            </>
        );
    }

    render() {
        const { i18n, onClose, company, isUpdatingCompany, isDeleting, companyId } = this.props;
        const { isVisibleDeleteModal, isVisibleAccountSetupModal, isVisibleEnterpriseModal,
            isVisibleFeaturesModal, isVisibleCustomizeUIModal } = this.state;
        return (
            <Core.Modal
                isVisible={true}
                onClose={onClose}
                title={this.renderTitle}
                modalWidth={MODAL_WIDTH}>
                {company ? (
                    <div>
                        <Core.FieldsGenerator
                            fields={this.fields}
                            data={company}
                            updatedData={this.state.updatedValues}
                        />
                        <div className={css(common.flexRowCenter)}>
                            {this.showUpdateButton ? (
                                <Core.Button
                                    label={i18n.t('companies.updateCompany')}
                                    onPress={this.onUpdateCompany}
                                    isLoading={isUpdatingCompany}
                                    className={styles.trialExpireButton}
                                />
                            ): null
                            }
                            <Core.PopConfirm onConfirm={this.onDeleteCompany}>
                                <a>
                                    <Core.Button
                                        label={i18n.t('companies.deleteCompany')}
                                        isLoading={isDeleting}
                                    />
                                </a>
                            </Core.PopConfirm>
                        </div>
                    </div>
                ): null
                }
                {isVisibleDeleteModal ? (
                    <DeleteCompanyModal
                        companyId={companyId}
                        onClose={this.onCloseDeleteCompanyModal}
                    />
                ): null
                }
                {isVisibleAccountSetupModal ? (
                    <AccountSetupModal
                        companyId={companyId}
                        onClose={this.onCloseAccountSetupModal}
                    />
                ) : null
                }
                {isVisibleEnterpriseModal ? (
                    <ManualSubscriptionModal
                        companyId={companyId}
                        onClose={this.onCloseEnterpriseModal}
                    />
                ) : null}
                {isVisibleFeaturesModal ? (
                    <CompanyFeaturesModal
                        companyId={companyId}
                        onClose={this.onCloseFeaturesModal}
                    />
                ) : null}
                {isVisibleCustomizeUIModal ? (
                    <CustomizeUIModal
                        companyId={companyId}
                        onClose={this.onCloseFeaturesModal}
                    />
                ) : null}
            </Core.Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    company: selectors.getCompany(state, ownProps.companyId),
    isUpdatingCompany: selectors.isUpdatingCompany(state, ownProps.companyId),
    isDeleting: selectors.isDeletingCompany(state, ownProps.companyId),
    subscriptionPlans: selectors.getSubscriptionPlans(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CompanyDetailsModal));

const styles = StyleSheet.create({
    trialExpireButton: {
        marginRight: spacing.s3
    },
    title: {
        textAlign: 'center'
    },
    buttonRow: {
        marginTop: spacing.s2
    },
    button: {
        margin: `0 ${spacing.s0}px`,
        marginBottom: spacing.s0
    }
});
