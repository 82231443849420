import React from "react";
import PropTypes from "prop-types";
import { colors, css, fonts, spacing, StyleSheet } from "../../../../styles";

const FeedbackTitle = React.memo(({ title }) => (
    <div className={css(styles.main)}>
        <p className={css(styles.title)}>
            {title}
        </p>
    </div>
));

export default FeedbackTitle;

FeedbackTitle.propTypes = {
    title: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: spacing.s3,
        marginTop: spacing.s4
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
        textAlign: 'center'
    }
});