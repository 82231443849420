import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../../selectors';
import DetailsModal from './DetailsModal';

const IntegrationsDetailsModal = ({ companyId, integrationId, onClose }) => {
    const integration = useSelector(state => selectors.getIntegration(state, integrationId));
    return (
        <DetailsModal
            companyId={companyId}
            integration={integration}
            onClose={onClose}
        />
    );
};

IntegrationsDetailsModal.propTypes = {
    companyId: PropTypes.string.isRequired,
    integrationId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default IntegrationsDetailsModal;
