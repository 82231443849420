import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { actionTypes as coreActionTypes } from '../core';
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    tokens: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGN_IN.SUCCESS:
        case actionTypes.REFRESH_TOKEN.SUCCESS:
            return {
                ...state,
                tokens: action.payload
            };
        case actionTypes.SIGN_OUT:
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: ['tokens']
};

export default persistReducer(persistConfig, reducer);