import React, { useCallback } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ICONS, translate } from "../../../core";
import { colors, common, css, fonts, spacing, StyleSheet } from "../../../../styles";
import { RATING_TYPES } from '../../constants';
import { Icon, Spinner, Tooltip } from "../../../core/components";
import * as selectors from "../../selectors";

const DATA_TYPES = {
    views: 'views',
    filled: 'filled',
    nps: 'nps'
};

const PERCENTAGE_ITEMS = [RATING_TYPES.detractors, RATING_TYPES.passives, RATING_TYPES.promoters];

const PromoterScoreStats = ({ i18n, onChangeRateFilter, isLoading, stats, missedNpsCount }) => {
    const data = useCallback(() => [{
        label: i18n.t('feedback.skippedViews'),
        value: missedNpsCount || 0,
        type: DATA_TYPES.views,
        tooltip: undefined
    }, {
        label: i18n.t('feedback.filled'),
        value: _.get(stats, 'scoresCount'),
        type: DATA_TYPES.filled,
        tooltip: undefined
    }, {
        label: i18n.t('feedback.promoters'),
        value: _.get(stats, 'promotersPerc'),
        type: RATING_TYPES.promoters,
        tooltip: i18n.t('feedback.countTooltip', { count: _.get(stats, 'promotersCount') })
    }, {
        label: i18n.t('feedback.detractors'),
        value: _.get(stats, 'detractorsPerc'),
        type: RATING_TYPES.detractors,
        tooltip: i18n.t('feedback.countTooltip', { count: _.get(stats, 'detractorsCount') })
    }, {
        label: i18n.t('feedback.passives'),
        value: _.get(stats, 'passivesPerc'),
        type: RATING_TYPES.passives,
        tooltip: i18n.t('feedback.countTooltip', { count: _.get(stats, 'passivesCount') })
    }, {
        label: i18n.t('feedback.nps'),
        value: _.get(stats, 'npsPerc'),
        type: DATA_TYPES.nps,
        tooltip: i18n.t('feedback.countTooltip', { count: _.get(stats, 'nps') })
    }], [stats, missedNpsCount, i18n]);

    return (
        <div className={css(common.flexRowStart, styles.mainContainer)}>
            {_.map(data(), ({ label, value, type, tooltip }) => {
                const isClickable = _.includes(RATING_TYPES, type);
                const isPercentage = _.includes(PERCENTAGE_ITEMS, type);
                const number = isPercentage ? `${value}%` : value;
                return (
                    <div key={type} className={css(styles.item, isClickable && styles.clickable)} onClick={() => (isClickable ? onChangeRateFilter(type) : null)}>
                        <div className={css(common.flexCol, styles.itemInner)}>
                            <p className={css(styles.title)}>
                                {label}
                                {tooltip && (
                                    <Tooltip title={tooltip}>
                                        <Icon
                                            className={css(styles.icon)}
                                            size={spacing.s2}
                                            color={colors.grey}
                                            type={ICONS.infoCircle}
                                        />
                                    </Tooltip>
                                )}
                            </p>
                            <p className={css(styles.value)}>{isLoading ? <Spinner /> : number}</p>
                        </div>
                    </div>
                ) }
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    isLoading: selectors.isLoadingPromoterScoreStats(state) || selectors.isGettingMissedNpsCount(state),
    stats: selectors.getPromoterScoresAllStats(state),
    missedNpsCount: selectors.getMissedNpsCount(state)
});

export default connect(mapStateToProps)(translate()(PromoterScoreStats));

PromoterScoreStats.propTypes = {
    i18n: PropTypes.object.isRequired,
    onChangeRateFilter: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    stats: PropTypes.object,
    missedNpsCount: PropTypes.number
};

PromoterScoreStats.defaultProps = {
    stats: null,
    missedNpsCount: null
};

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: colors.white,
        padding: `0 ${spacing.s3}px`,
        flex: 1,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
        overflow: 'hidden',
        minHeight: spacing.s11 * 2
    },
    title: {
        ...fonts.xsLight,
        whiteSpace: 'nowrap'
    },
    value: {
        ...fonts.xlBold,
        whiteSpace: 'nowrap'
    },
    item: {
        flex: 1,
        padding: `${spacing.s5}px ${spacing.s1}px`,
        ':not(:last-child)': {
            borderRight: `1px solid ${colors.greyLightOpacity}`
        }
    },
    itemInner: {
        width: 'fit-content',
        margin: 'auto'
    },
    clickable: {
        cursor: 'pointer',
        transition: `all 0.5s`,
        ':hover': {
            backgroundColor: colors.background
        }
    },
    icon: {
        marginLeft: spacing.s0
    }
});