import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, css,  common, colors, spacing, fonts } from '../../../../styles';
import { components as Core, useTranslation, fileToBase64, downloadFile } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

const CompanyImportThreads = ({ companyId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const importedSuccessfullyThreadsCount = useSelector(state => selectors.getImportedSuccessfullyThreadsCount(state, companyId));
    const totalCount = useSelector(state => selectors.getTotalThreadsToImportCount(state, companyId));
    const badThreadsJson = useSelector(state => selectors.getBadThreadsJson(state, companyId));
    const importThreadError = useSelector(state => selectors.getImportThreadErrors(state, companyId));
    const isImportingThreads = useSelector(state => selectors.isImportingThreads(state, companyId));

    const importfromJson = async e => {
        if (e.target.files && e.target.files.length > 0) dispatch(actions.importThreadsFromJson(companyId, await fileToBase64(e.target.files[0])));
    }

    const copyToClipBoardBadThreads = () => navigator.clipboard.writeText(badThreadsJson);
    const downloadBadThreadsJson = () => downloadFile(badThreadsJson, `Bad_tickets_${moment().utc()}.json`)

    const renderErrors = useCallback(() => _.map(importThreadError, el => (<p>{el}</p>)), [importThreadError]);
    const renderDivider = () => (<div className={css(styles.divider)} />)

    return (
        <div className={css(common.flexCol)}>
            <div className={css(common.flexRow)}>
                <Core.FileInput
                    label={t('companies.importFromJSON')}
                    onChange={importfromJson}
                    isLoading={isImportingThreads}
                />
            </div>
            {totalCount ? (
                <div className={css(styles.result)}>
                    <div className={css(styles.headerContainer)}>
                        <p className={css(styles.successCount)}>{t('companies.importedThreadCount', { count: importedSuccessfullyThreadsCount })}</p>
                        <p className={css(styles.errorCount)}>{t('companies.notImportedThreadCount', { count: totalCount - importedSuccessfullyThreadsCount })}</p>
                    </div>
                    {(totalCount - importedSuccessfullyThreadsCount) ? (
                        <>
                            {renderDivider()}
                            <div className={css(common.flexRowCenter, styles.spaceWrapper)}>
                                <p className={css(styles.text)}>{t('companies.notImportedThread')}</p>
                                <Core.Button
                                    label={t('download')}
                                    className={styles.space}
                                    onPress={downloadBadThreadsJson}
                                />
                                <Core.Button
                                    label={t('copyToClipboard')}
                                    className={styles.space}
                                    onPress={copyToClipBoardBadThreads}
                                />
                            </div>
                            <div className={css(styles.returnedJsonContainer)}>
                                <code>
                                    {badThreadsJson}
                                </code>
                            </div>
                            {importThreadError.length ? (
                                <>
                                    {renderDivider()}
                                    <div className={css(styles.headerContainer)}>
                                        <p className={css(styles.text)}>{t('companies.importErrorDetails')}</p>
                                    </div>
                                    <div className={css(styles.errorsItems)}>
                                        {renderErrors()}
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}

export default CompanyImportThreads;

CompanyImportThreads.propTypes = {
    companyId: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
    result: {
        marginTop: spacing.s3,
        width: '100%',
        backgroundColor: colors.white,
        borderRadius: spacing.s2,
        boxShadow: `0px 0px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: spacing.s0
    },
    divider: {
        height: spacing.s0/4,
        borderBottom: `solid 1px ${colors.greyLight}`
    },
    successCount: {
        paddingRight: spacing.s6,
        color: colors.green,
        ...fonts.smBold
    },
    errorCount: {
        color: colors.red,
        ...fonts.smBold
    },
    returnedJsonContainer: {
        ...common.flexCol,
        padding: spacing.s1,
        maxHeight: '500px',
        overflowX: 'auto'
    },
    errorsItems: {
        padding: spacing.s1,
        '& p': {
            paddingTop: spacing.s2,
            ...fonts.smNormal,
            textAlign: 'justify'
        },
        '& p:first-child': {
            paddingTop: 0,
        }
    },
    spaceWrapper: {
        margin: spacing.s1
    },
    space: {
        marginLeft: spacing.s2
    },
    text: {
        ...fonts.smBold
    }
});