import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '../../services';
import Select from '../Select';
import { MARKETPLACES_ENUM, INTEGRATION_TYPES, MARKETPLACES_WITH_TICKETS, CHAT_MARKETPLACES } from '../../constants';

class IntegrationFilter extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        selected: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        mainClassName: PropTypes.object,
        hasMargins: PropTypes.bool,
        integrationType: PropTypes.number
    };

    static defaultProps = {
        selected: [],
        mainClassName: undefined,
        hasMargins: true,
        integrationType: INTEGRATION_TYPES.all
    };

    onSelect = value => {
        this.props.onChange([...this.props.selected, value]);
    };

    onDeselect = value => {
        this.props.onChange(_.filter(this.props.selected, i => i !== value));
    };

    get integrations() {
        const { integrationType } = this.props;
        switch (integrationType) {
            case INTEGRATION_TYPES.marketplace:
                return MARKETPLACES_WITH_TICKETS;
            case INTEGRATION_TYPES.instantMessages:
                return CHAT_MARKETPLACES;
            default:
                return [
                    ...MARKETPLACES_WITH_TICKETS,
                    ...CHAT_MARKETPLACES,
                    MARKETPLACES_ENUM.Aircall
                ];
        }
    }

    get items() {
        const integrations = Object.keys(MARKETPLACES_ENUM).map(key => ({ key: MARKETPLACES_ENUM[key], value: key }));
        return _.filter(integrations, item => _.includes(this.integrations, item.key));
    }

    render() {
        const { i18n, selected, mainClassName, hasMargins } = this.props;
        return (
            <Select
                mode="multiple"
                keyProp="key"
                valueProp="key"
                labelProp="value"
                mainClassName={mainClassName}
                placeholder={i18n.t('integrations.filterByIntegrations')}
                onSelect={this.onSelect}
                onDeselect={this.onDeselect}
                value={selected}
                data={this.items}
                hasMargins={hasMargins}
            />
        );
    }
}

export default translate()(IntegrationFilter);