import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { colors, common, css, spacing, StyleSheet } from "../../../../styles";
import { Button, Icon, Input, Spinner } from "../../../core/components";
import { translate, ICONS } from "../../../core";
import * as selectors from "../../selectors";
import * as actions from "../../actions";

class RepeatSurvey extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        repeatSurvey: PropTypes.number
    };

    static defaultProps = {
        isLoading: false,
        isSaving: false,
        repeatSurvey: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.repeatSurvey
        };
        this.props.actions.getAdminSettings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({ value: this.props.repeatSurvey });
        }
    }

    onChange = value => this.setState({ value });

    get label() {
        return (
            <Icon type={ICONS.save} color={colors.white} />
        );
    }

    onSave = () => {
        this.props.actions.saveRepeatSurvey(this.state.value);
    };

    get renderLoading() {
        return <Spinner className={styles.spinner} />
    }

    render() {
        const { i18n, repeatSurvey, isSaving, isLoading } = this.props;
        const { value } = this.state;
        return (
            <div className={css(common.flexRow, styles.repeatSurvey)}>
                {i18n.t('feedback.repeatSurvey')}
                {isLoading ? this.renderLoading : (
                    <>
                        <Input
                            className={styles.input}
                            hasMargins={false}
                            onChange={this.onChange}
                            value={value}
                        />
                        <Button
                            className={styles.saveButton}
                            label={this.label}
                            onPress={this.onSave}
                            isLoading={isSaving}
                            isDisabled={repeatSurvey === value || isSaving}
                        />
                    </>
                )}
                {i18n.t('feedback.days')}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    repeatSurvey: selectors.getNpsRepeatSurvey(state),
    isLoading: selectors.isLoadingAdminSettings(state),
    isSaving: selectors.isSavingRepeatSurvey(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RepeatSurvey));

const styles = StyleSheet.create({
    repeatSurvey: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap'
    },
    input: {
        margin: `0 ${spacing.s0}px`
    },
    saveButton: {
        padding: `${spacing.s0}px ${spacing.s1}px`,
        marginRight: spacing.s0,
        ':disabled': {
            backgroundColor: colors.transparent,
            borderColor: colors.greyOpacity,
            '& i': {
                color: `${colors.greyOpacity} !important`
            },
            ':hover': {
                backgroundColor: colors.transparent,
                borderColor: colors.greyOpacity
            }
        }
    },
    spinner: {
        margin: `0 ${spacing.s1}px`
    }
});