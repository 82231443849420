import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css, fonts, colors, spacing } from '../../../../styles';

const InlineButton = React.memo(({ label, onClick, href, style, ...props }) => {
    return (
        <a className={css(styles.text, style)} href={href} onClick={onClick} {...props}>
            <span>{label}</span>
        </a>
    );
});

InlineButton.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
};

InlineButton.defaultProps = {
    href: undefined,
    onClick: null,
    style: undefined
};

export default InlineButton;

const styles = StyleSheet.create({
    text: {
        display: 'block',
        ...fonts.xsRegular,
        color: colors.primary,
        textDecoration: 'none',
        marginBottom: spacing.s1,
        ':hover': {
            opacity: 0.6
        }
    }
});