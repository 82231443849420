/* eslint-disable no-restricted-syntax */
/**
 * use to safely access nested properties of an object without throwing errors if any intermediate property is undefined
 * @param {Object} obj the object you want to access properties from
 * @param {*} path the nested property path (using dot notation)
 * @param {*} defaultValue an optional default value if the property is not found
 * usage: getObjectValue(obj, 'property1.property2.property3', 0)
 */
export const getObjectValue = (obj, path, defaultValue = null) => {
    const pathArray = path.split('.');
    let result = obj;
    for (const key of pathArray) {
        if (result && typeof result === 'object' && key in result) {
            result = result[key];
        } else {
            return defaultValue;
        }
    }
    return result || defaultValue;
};

/**
 * Creates an array with values transformed by the iteratee function.
 * @param {Object} object - The input object to iterate over.
 * @param {function(value, key)} iteratee - The function invoked per iteration.
 * @returns {Object} Returns the new mapped array.
 */

export const mapObject = (object, iteratee) => {
    const result = [];
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            result.push(iteratee(object[key], key));
        }
    }
    return result;
};

/**
 * @param {Object} obj Object to be processed
 * @param {string} key Key to delete
 * @returns object without key
 * usage: omitObjectKey(obj, 'key')
 */
// eslint-disable-next-line no-unused-vars
export const omitObjectKey = ({ [key]: _, ...obj }, key) => obj;