import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { components as Core, ICONS, getPagination, useTranslation, getTableProps } from '../../../../../../core';
import { StyleSheet, colors, css, spacing, fonts } from '../../../../../../../styles';
import { valueBuilder } from '../../../../../../companies';
import * as dashboardSelectors from '../../../../../selectors';
import { getRegistrations, downloadRegistrationsReport } from '../../../../../actions';

const COLUMNS = {
    companyEmail: 'companyEmail',
    registrationDate: 'registrationDate',
    trialExpire: 'trialExpire',
    region: 'region',
    country: 'country',
};

export const RegistrationsTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegistrations());
    }, []);

    const isLoading = useSelector(dashboardSelectors.isLoadingRegistrationsData);
    const isLoadingReport = useSelector(dashboardSelectors.isLoadingDownloadingReport);
    const data = useSelector(dashboardSelectors.getRegistrations);
    const pagination = useSelector(dashboardSelectors.getPagination);

    const tableColumns = () => ([{
        title: t("companyEmail"),
        key: COLUMNS.companyEmail,
        dataIndex: COLUMNS.companyEmail
    }, {
        title: t("dashboard.registrationsTable.registrationDate"),
        key: COLUMNS.registrationDate,
        dataIndex: COLUMNS.registrationDate,
        render: valueBuilder.getLocalFullTime
    }, {
        title: t("dashboard.registrationsTable.trialExpire"),
        key: COLUMNS.trialExpire,
        dataIndex: COLUMNS.trialExpire,
        render: renderTrialDateColumn
    }, {
        title: t("region"),
        key: COLUMNS.region,
        dataIndex: COLUMNS.region,
        render: renderRegion
    }, {
        title: t("country"),
        key: COLUMNS.country,
        dataIndex: COLUMNS.country,
    }]);

    const renderTrialDateColumn = (value, company) => {
        if (company.hasSubscription) return <span style={{ color: 'green' }}>Active Subscription</span>;
        const date = valueBuilder.getLocalFullTime(value);
        return moment().isBefore(date) ? date : <span style={{ color: 'red' }}>{date}</span>;
    };

    const renderRegion = value => t(`regionTypes.${value}`);

    const footerButton = () => {
        return (
            <>
                <Core.Icon
                    className={css(styles.footerIcon)}
                    color={colors.white}
                    type={ICONS.download}
                />
                {t('export')}
            </>
        );
    }

    const footer = () => (
        <div className={css(styles.footer)}>
            <Core.Button
                isDisabled={isLoadingReport}
                isLoading={isLoadingReport}
                onPress={onDownload}
                label={footerButton()}
            />
        </div>
    );

    const onDownload = () => {
        dispatch(downloadRegistrationsReport());
    }

    const getPaginationForTable = () => getPagination(pagination);

    const onChangeTable = (pagination, filters, sorter) => {
        dispatch(getRegistrations(getTableProps(pagination, filters, sorter)));
    };

    return (
        <Core.Table
            columns={tableColumns()}
            data={data}
            isLoading={isLoading}
            pagination={getPaginationForTable()}
            footer={footer}
            className={css(styles.table)}
            onChange={onChangeTable}
        />
    );
};

const styles = StyleSheet.create({
    passive: {
        color: colors.yellow
    },
    detractor: {
        color: colors.red
    },
    promoter: {
        color: colors.green
    },
    table: {
        '& .ant-table-footer': {
            position: 'initial',
            border: 'none',
            padding: 0
        }
    },
    footer: {
        position: 'absolute',
        margin: `${spacing.s3}px 0`
    },
    footerIcon: {
        marginRight: spacing.s0
    },
    message: {
        ...fonts.mdBold,
        marginTop: spacing.s2
    },
    companyName: {
        ...fonts.smBold
    },

});