import React from 'react'
import { useSelector } from 'react-redux';
import { InlineDashboardCards } from '../../common';
import { colors, css, spacing, StyleSheet } from '../../../../../styles';
import { getObjectValue, ICONS, useTranslation } from '../../../../core';
import * as dashboardSelectors from '../../../selectors';

export const CancellationStats = () => {
    const { t } = useTranslation();
    const data = useSelector(dashboardSelectors.getSubscriptionCancellationsStats);

    const items = () => [{
        count: getObjectValue(data, 'current.totalCostUSD', 0),
        prevCount: getObjectValue(data, 'previous.totalCostUSD', 0),
        text: t('dashboard.cancellations.totalCost', { currency: 'USD' }),
        iconName: ICONS.dollar,
        iconColor: colors.green,
        iconBackground: colors.greenOpacity,
        isReverse: true,
        renderValue: renderUSD
    }, {
        count: getObjectValue(data, 'current.totalCostEUR', 0),
        prevCount: getObjectValue(data, 'previous.totalCostEUR', 0),
        text: t('dashboard.cancellations.totalCost', { currency: 'EUR' }),
        iconName: ICONS.dollar,
        iconColor: colors.yellow,
        iconBackground: colors.yellowOpacity,
        isReverse: true,
        renderValue: renderEUR
    }, {
        count: getObjectValue(data, 'current.totalCostGBP', 0),
        prevCount: getObjectValue(data, 'previous.totalCostGBP', 0),
        text: t('dashboard.cancellations.totalCost', { currency: 'GBP' }),
        iconName: ICONS.dollar,
        iconColor: colors.primary,
        iconBackground: colors.primaryLight,
        isReverse: true,
        renderValue: renderGBP
    }, {
        count: getObjectValue(data, 'current.cancellationsCount', 0),
        prevCount: getObjectValue(data, 'previous.cancellationsCount', 0),
        text: t('dashboard.cancellations.cancellationsCount'),
        iconName: ICONS.close,
        iconColor: colors.red,
        iconBackground: colors.redOpacity,
        isReverse: true
    }];

    const renderUSD = value => `$${value}`;
    const renderEUR = value => `€${value}`;
    const renderGBP = value => `£${value}`;

    return (
        <div className={css(styles.row)}>
            <InlineDashboardCards
                items={items()}
            />
        </div>
    )
};

const styles = StyleSheet.create({
    row: {
        width: 'fit-content',
        height: spacing.s14*2
    }
});