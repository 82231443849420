import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../services';
import { COMPANY_REGIONS } from '../../../companies/constants';
import { mapObject } from '../../utils';
import Select from '../Select';

export const RegionFilter = ({ onChange }) => {
    const { t } = useTranslation();

    const regionTypes = () => {
        const regions = mapObject(COMPANY_REGIONS, (value, key) => ({
            name: t(`regionTypes.${value}`),
            id: COMPANY_REGIONS[key]
        }))
        return [{
            name: t('regionTypes.all'),
            id: null
        }, ...regions];
    }

    return (
        <Select
            data={regionTypes()}
            onChange={onChange}
            defaultValue={null}
        />
    );
};

RegionFilter.propTypes = {
    onChange: PropTypes.func.isRequired
};