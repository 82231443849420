import { SORT_DIRECTIONS } from '../constants';

const getTableProps = (pagination, filters, sorter) => {
    const sortParams = sorter && sorter.columnKey ? {
        sort: sorter.columnKey,
        asc: sorter.order === SORT_DIRECTIONS.ascend
    } : {};
    return {
        page: pagination.current || 1,
        ...sortParams
    };
};

export default getTableProps;