import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import { StyleSheet, css, colors, common, spacing } from '../../../../styles';
import { components as Core } from '../../../core';

class Layout extends React.PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    get spinner() {
        return (
            <div className={css(styles.spinnerContainer)}>
                <Core.Spinner isBig={true} />
            </div>
        );
    }

    render() {
        const { children, isLoading } = this.props;
        return (
            <div className={css(common.flexCol, styles.mainContainer)}>
                <Header />
                <div className={css(styles.innerContainer)}>
                    {isLoading ? this.spinner : children}
                </div>
                <Footer />
            </div>
        );
    }
}

export default Layout;

const styles = StyleSheet.create({
    mainContainer: {
        minHeight: '100vh'
    },
    innerContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: colors.background,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s7,
        paddingRight: spacing.s7,
        '@media (max-width: 576px)': {
            paddingLeft: spacing.s3,
            paddingRight: spacing.s3
        }
    },
    spinnerContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});