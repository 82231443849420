export default [
    "#A1CDA8",
    "#758577",
    "#F17300",
    "#3E7CB1",
    "#b30e50",
    "#A481B4",
    "#a9708b",
    "#FDB781",
    "#43c2c1",
    "#7cb5ec",
    "#f45b5b",
    "#90ed7d",
    "#777E9C"
];