import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import List from '@ckeditor/ckeditor5-list/src/list';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';

export default class RichTextEditorBase extends ClassicEditorBase {}

RichTextEditorBase.builtinPlugins = [
    Alignment,
    Autoformat,
    Bold,
    Italic,
    Strikethrough,
    Underline,
    BlockQuote,
    Essentials,
    Paragraph,
    FontSize,
    FontFamily,
    FontColor,
    FontBackgroundColor,
    Heading,
    Indent,
    IndentBlock,
    Link,
    AutoLink,
    List,
    RemoveFormat,
    SourceEditing,
    TextTransformation,
    SpecialCharacters,
    SpecialCharactersEssentials,
    HorizontalLine
];

RichTextEditorBase.defaultConfig = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'blockQuote',
            '|',
            'alignment',
            'fontBackgroundColor',
            'horizontalLine',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'specialCharacters',
            '|',
            'removeFormat',
            'sourceEditing',
            '|',
            'heading',
        ]
    },
    fontSize: {
        options: [9, 10, 11, 12, 'default', 14, 15],
        supportAllValues: true
    },
    link: {
        addTargetToExternalLinks: true
    },
    language: 'en',
    typing: {
        transformations: {
            remove: [
                'oneHalf',
                'oneThird',
                'twoThirds',
                'oneForth',
                'threeQuarters'
            ]
        }
    }
};