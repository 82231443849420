import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { components as Core, useTranslation, COMPANY_THEME } from '../../../core';
import { css, common, StyleSheet, spacing, colors, fonts } from '../../../../styles';

const VARIANTS = {
    [COMPANY_THEME.error]: [
        'rgb(237, 76, 103)',
        'rgb(255, 97, 55)',
        'rgb(243, 71, 34)',
        'rgb(231, 76, 60)',
        'rgb(227, 88, 50)',
        'rgb(201, 78, 44)'
    ],
    [COMPANY_THEME.warning]: [
        'rgb(255, 218, 121)',
        'rgb(255, 200, 55)',
        'rgb(241, 196, 15)',
        'rgb(230, 181, 48)',
        'rgb(255, 221, 2)',
        'rgb(254, 177, 0)'
    ],
    [COMPANY_THEME.success]: [
        'rgb(85, 239, 196)',
        'rgb(105, 231, 160)',
        'rgb(94, 204, 142)',
        'rgb(82, 179, 124)',
        'rgb(76, 209, 55)',
        'rgb(0, 148, 50)'
    ]
};

const PaletteColorPicker = ({ themeColors, onChange }) => {
    const { t } = useTranslation();

    const changeColor = groupName => color => onChange(prevState => ({
        ...prevState, [groupName]: `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
    }));

    const renderColor = color => (
        <div key={color} className={css([common.flexRowStart, styles.mb1])}>
            <p className={css(styles.header)}>{t(`companies.customizeUI.${color}`)}</p>
            <Core.ColorPicker
                color={themeColors[color]}
                onChange={changeColor(color)}
                presetColors={VARIANTS[color]}
            />
        </div>
    );

    return (
        <>
            {_.map(Object.keys(COMPANY_THEME), key => renderColor(COMPANY_THEME[key]))}
        </>
    );
}
PaletteColorPicker.propTypes = {
    themeColors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default PaletteColorPicker;

const styles = StyleSheet.create({
    header: {
        color: colors.primary,
        ...fonts.smBold,
        width: spacing.s10*2
    },
    mb1: {
        marginBottom: spacing.s1
    }
});