import React from 'react';
import PropTypes from 'prop-types';
import { Companies, AllIntegrations, UsersTab, Feedback } from '../../../companies';
import { components as Core, translate } from '../../../core';
import { Dashboard, UsageReport } from '../../../dashboard';

const TABS = {
    companies: 'companies',
    integrations: 'integrations',
    users: 'users',
    feedback: 'feedback',
    dashboard: 'dashboard',
    usageReport: 'usageReport',
};

class ContentLayout extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired
    };

    get tabs() {
        const { i18n } = this.props;
        return [{
            tabProps: {
                key: TABS.companies,
                tab: i18n.t('screens.companies')
            },
            component: this.renderCompanies
        }, {
            tabProps: {
                key: TABS.integrations,
                tab: i18n.t('screens.integrations')
            },
            component: this.renderIntegrations
        }, {
            tabProps: {
                key: TABS.users,
                tab: i18n.t('screens.users')
            },
            component: this.renderUsers
        }, {
            tabProps: {
                key: TABS.feedback,
                tab: i18n.t('screens.feedback')
            },
            component: this.renderFeedback
        }, {
            tabProps: {
                key: TABS.dashboard,
                tab: i18n.t('screens.dashboard')
            },
            component: this.renderDashboard
        }, {
            tabProps: {
                key: TABS.usageReport,
                tab: i18n.t('screens.usageReport')
            },
            component: this.renderUsageReport
        }];
    }

    get renderCompanies() {
        return (
            <Companies />
        );
    }

    get renderIntegrations() {
        return (
            <AllIntegrations />
        );
    }

    get renderUsers() {
        return (
            <UsersTab />
        );
    }

    get renderFeedback() {
        return (
            <Feedback />
        );
    }

    get renderDashboard() {
        return (
            <Dashboard />
        );
    }

    get renderUsageReport() {
        return (
            <UsageReport />
        );
    }

    render() {
        return (
            <Core.Tabs
                tabs={this.tabs}
                defaultActiveKey={TABS.companies}
            />
        );
    }
}
export default translate()(ContentLayout);