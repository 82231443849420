import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { css, marginsTop, spacing, StyleSheet } from '../../../../styles';
import { components as Core, PERIODS } from '../../../core';
import { CancellationChart } from './CancellationChart';
import { CancellationTable } from './CancellationTable';
import { changeFiltersDateRange, changeRegionFilter, getCancellations, getCancellationsChartData, getCancellationsOverview } from '../../actions';
import { CancellationStats } from './CancellationStats';

export const CancellationDashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        loadData()
    }, []);

    const loadData = () => {
        dispatch(getCancellationsOverview());
        dispatch(getCancellations());
        dispatch(getCancellationsChartData());
    }

    const onDateFilterChange = (startDate, endDate) => {
        dispatch(changeFiltersDateRange(startDate, endDate));
        loadData();
    };

    const onRegionFilterChange = value => {
        dispatch(changeRegionFilter(value));
        loadData();
    }
    return (
        <>
            <Row type="flex" justify="end" gutter={spacing.s3} className={css(styles.top)}>
                <Col span={5}>
                    <Core.RegionFilter
                        onChange={onRegionFilterChange}
                    />
                </Col>
                <Col span={5}>
                    <Core.DateFilter
                        onChange={onDateFilterChange}
                        defaultPeriod={PERIODS.last7Days}
                    />
                </Col>
            </Row>
            <CancellationStats />
            <Row className={css(marginsTop.s3)}>
                <Col span={14}>
                    <CancellationTable />
                </Col>
                <Col span={10}>
                    <CancellationChart />
                </Col>
            </Row>
        </>
    )
};

const styles = StyleSheet.create({
    top: {
        margin: `${spacing.s3}px 0`,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: spacing.s1
    }
});