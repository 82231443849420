import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { components as Core, translate, constants as coreConstants, getTableProps, getPagination } from '../../../core';
import { valueBuilder } from '../../services';
import { colors, css, StyleSheet } from "../../../../styles";

export const COLUMNS = {
    creationDate: 'creationDate',
    text: 'htmlBody',
    isRead: 'isRead'
};

class UserNotifications extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        userId: PropTypes.string.isRequired,
        notifications: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired
    };

    static defaultProps = {
        notifications: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.props.actions.getUserNotifications(this.props.userId);
    }

    get tableColumns() {
        const { i18n } = this.props;
        return [{
            title: i18n.t("users.notifications.creationDate"),
            key: COLUMNS.creationDate,
            dataIndex: COLUMNS.creationDate,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("users.notifications.text"),
            key: COLUMNS.text,
            dataIndex: COLUMNS.text,
            render: this.renderText
        }, {
            title: i18n.t("users.notifications.read"),
            key: COLUMNS.isRead,
            dataIndex: COLUMNS.isRead,
            render: this.renderIsRead,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }];
    }

    get pagination() {
        return getPagination(this.props.pagination);
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.actions.getUserNotifications(
            this.props.userId,
            getTableProps(pagination, filters, sorter)
        );
    };

    renderText = value => {
        const text = (new DOMParser).parseFromString(value, "text/html").documentElement.textContent || "...";
        return <p>{text && text.length > 70 ? `${text.substring(0, 67)}...` : text}</p>;
    };

    renderIsRead = value => {
        const { i18n } = this.props;
        const text = value ? i18n.t('users.notifications.read') : i18n.t('users.notifications.unRead');
        return <p className={css(value ? styles.read : styles.unread)}>{text}</p>
    };

    render() {
        const { isLoading, notifications } = this.props;
        return (
            <div>
                <Core.Table
                    columns={this.tableColumns}
                    data={notifications}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.onChangeTable}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    notifications: selectors.getNotificationsByUser(state, ownProps.userId),
    pagination: selectors.getNotificationsPagination(state, ownProps.userId),
    isLoading: selectors.isGettingUserNotifications(state, ownProps.userId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserNotifications));

const styles = StyleSheet.create({
    read: {
        color: colors.green
    },
    unread: {
        color: colors.red
    }
});