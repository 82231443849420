import { createSelector } from 'reselect';
import moment from 'moment';
import { NAME } from './constants';
import { isLoading } from "../core/selectors";
import * as actionTypes from './actionTypes';
import { getObjectValue, DATE_FORMATS } from '../core';

const getFiltersStore = state => state[NAME].filters;
export const getFilters = state => {
    const { startDate, endDate, ...rest } = getFiltersStore(state);
    return {
        startDate: moment(startDate).startOf('day').format(DATE_FORMATS.UTC),
        endDate: moment(endDate).endOf('day').format(DATE_FORMATS.UTC),
        ...rest
    };
}

export const getOverview = state => state[NAME].overview;
export const isLoadingOverview = state => isLoading(state, actionTypes.GET_OVERVIEW_STATS.NAME);

const getRegistrationsStore = state => state[NAME].registrations;
const getRegistrationsIds = state => getObjectValue(getRegistrationsStore(state), 'ids', []);
const getRegistrationsItems = state => getObjectValue(getRegistrationsStore(state), 'items', []);

export const getRegistrations = createSelector(getRegistrationsItems, getRegistrationsIds, (items, ids) => ids.map(id => items[id]));
export const getPagination = createSelector(getRegistrationsStore, store => getObjectValue(store, 'pagination', {}));
export const getRegistrationsChartData = createSelector(getRegistrationsStore, store => getObjectValue(store, 'chartData', []))
export const isLoadingRegistrationsData = state => isLoading(state, actionTypes.GET_NEW_COMPANIES.NAME);
export const isLoadingDownloadingReport = state => isLoading(state, actionTypes.DOWNLOAD_REPORT.NAME);
export const isLoadingRegistrationsChartData = state => isLoading(state, actionTypes.GET_NEW_COMPANIES_CHART_DATA.NAME);

// Usage Report
export const getUsageReportStore = state => state[NAME].usageReport;
const getUsageReportIds = state => getObjectValue(getUsageReportStore(state), 'ids', []);
const getUsageReportItems = state => getObjectValue(getUsageReportStore(state), 'items', []);

export const getUsageReport = createSelector(getUsageReportItems, getUsageReportIds, (items, ids) => ids.map(id => items[id]));
export const getUsageReportPagination = createSelector(getUsageReportStore, store => getObjectValue(store, 'pagination', {}));
export const isLoadingUsageReportData = state => isLoading(state, actionTypes.GET_USAGE_REPORT_DATA.NAME);