const colors = {
    primary: '#35a8e0',
    primaryTransparent: 'rgba(53, 168, 224, 0.57)',
    primaryDark: '#3a84f8',
    primaryLight: '#e1f2fb',
    primaryBackground: 'rgba(57, 168, 224, 0.1)',

    white: '#ffffff',
    black: '#000',
    blackTransparent: 'rgba(0, 0, 0, 0.85)',
    blackOpacity: 'rgba(39, 43, 45, 0.5)',
    background: '#f5f6fa',
    backgroundLight: 'rgb(249,249,251)',
    shadow: '#ebebeb',
    transparent: 'rgba(0,0,0,0)',
    grey: '#acb5ba',
    greyLight: '#e2e2e2',
    greyOpacity: 'rgba(172, 181, 186, 0.5)',
    greyLightOpacity: 'rgba(172, 181, 186, 0.1)',
    red: '#ff6137',
    redOpacity: 'rgba(255, 97, 55, 0.2)',
    green: '#69e7a0',
    greenOpacity: 'rgba(105, 230, 160, 0.2)',
    yellow: '#ffc837',
    yellowOpacity: 'rgba(255, 200, 55, 0.2)',
    greenSee: '#16a085',
    greenSeeOpacity: 'rgba(22, 160, 133,0.2)',
    orange: 'rgba(255, 165, 2,1.0)',
    orangeOpacity: 'rgba(255, 165, 2,0.1)',

    tableHeader: '#f1f3f6',
    tableHeaderText: '#788195',
    tableBodyText: '#6a6c6a',
    textColor: '#9b9b9b',
    textShadow: 'rgba(0, 0, 0, 0.04)',
    border: '#d9d9d9',
    inputAddon: '#fafafa',
    inputAddonText: 'rgba(0, 0, 0, 0.65)',
};

export default colors;