import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactCountdownClock from 'react-countdown-clock';
import * as selectors from '../../selectors';
import { components as Core, translate } from '../../../core';
import { css, common, StyleSheet, spacing, fonts, colors } from '../../../../styles';
import * as actions from "../../actions";

class DeleteCompanyModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        isDeleting: PropTypes.bool,
        onClose: PropTypes.func.isRequired
    };

    static defaultProps = {
        isDeleting: false
    };

    onDeleteCompany = () => {
        this.props.actions.deleteCompany(this.props.companyId);
    };

    onCancel = () => {
        this.props.onClose();
    };

    onCompleteClock = () => {
        const { isDeleting } = this.props;
        if (!isDeleting) {
            this.onCancel();
        }
    };

    render() {
        const { i18n, isDeleting } = this.props;
        return (
            <Core.Modal
                isVisible={true}
                onClose={this.onCancel}
                closable={false}>
                <div className={css(common.flexColCenter)}>
                    <ReactCountdownClock
                        seconds={15}
                        color={colors.primary}
                        size={150}
                        alpha={0.5}
                        fontSize={`${fonts.lgNormal.fontSize}px`}
                        font={fonts.mdNormal.fontFamily}
                        onComplete={this.onCompleteClock}
                    />
                    <p className={css(styles.text)}>{i18n.t('companies.deleteCompanyConfirm')}</p>
                    <div className={css(common.flexRowCenter)}>
                        <Core.Button
                            label={i18n.t('cancel')}
                            onPress={this.onCancel}
                            className={styles.leftButton}
                        />
                        <Core.Button
                            label={i18n.t('companies.deleteCompany')}
                            isLoading={isDeleting}
                            onPress={this.onDeleteCompany}
                            className={styles.rightButton}
                        />
                    </div>
                </div>
            </Core.Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    company: selectors.getCompany(state, ownProps.companyId),
    isUpdatingCompany: selectors.isUpdatingCompany(state, ownProps.companyId),
    isDeleting: selectors.isDeletingCompany(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DeleteCompanyModal));

const styles = StyleSheet.create({
    trialExpireButton: {
        marginRight: spacing.s3
    },
    text: {
        ...fonts.mdNormal,
        marginBottom: spacing.s3,
        marginTop: spacing.s3
    },
    leftButton: {
        marginRight: spacing.s1
    },
    rightButton: {
        marginLeft: spacing.s1
    }
});
