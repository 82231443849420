import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import { StyleSheet, css, spacing, fonts, colors } from '../../../../styles';

const INPUT_TYPES = {
    text: 'text',
    password: 'password'
};

class Input extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        isPassword: PropTypes.bool,
        onPressEnter: PropTypes.func,
        className: PropTypes.object,
        placeholder: PropTypes.string,
        hasMargins: PropTypes.bool,
        hasCommonStyles: PropTypes.bool
    };

    static defaultProps = {
        label: undefined,
        isPassword: false,
        onPressEnter: null,
        className: undefined,
        placeholder: undefined,
        hasMargins: true,
        hasCommonStyles: true
    };

    get type() {
        return this.props.isPassword ? INPUT_TYPES.password : INPUT_TYPES.text;
    }

    onChange = event => {
        this.props.onChange(event.target.value);
    };

    render() {
        const { value, label, onPressEnter, className, placeholder, hasMargins, hasCommonStyles } = this.props;
        return (
            <div className={css(styles.container, hasMargins ? styles.containerWithMargins : undefined, className)}>
                {label ?
                    <p className={css(styles.label)}>{label}</p> : null
                }
                <AntdInput
                    type={this.type}
                    value={value}
                    onChange={this.onChange}
                    onPressEnter={onPressEnter}
                    className={css(styles.input, hasCommonStyles ? styles.commonInput : undefined)}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default Input;

const styles = StyleSheet.create({
    container: {
        width: '100%'
    },
    containerWithMargins: {
        marginBottom: spacing.s3,
        marginTop: spacing.s1
    },
    label: {
        ...fonts.xlNormal,
        color: colors.primary,
        marginBottom: spacing.s3,
        marginLeft: spacing.s4
    },
    input: {
        ...fonts.xlLight,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s4,
        paddingRight: spacing.s4,
        height: 'auto !important',
        borderRadius: spacing.s4,
        border: 'none',
        color: colors.primary,
        backgroundColor: colors.white
    },
    commonInput: {
        ...fonts.mdLight,
        border: `1px solid ${colors.border}`,
        color: colors.black
    }
});