const weights = {
    light: {
        fontFamily: 'Ubuntu,sans-serif',
        fontWeight: 300,
        margin: 0
    },
    normal: {
        fontFamily: 'Ubuntu,sans-serif',
        fontWeight: 400,
        margin: 0
    },
    bold: {
        fontFamily: 'Ubuntu,sans-serif',
        fontWeight: 700,
        margin: 0
    }
};

const sizes = {
    xxs: {
        fontSize: 12,
        lineHeight: '14px'
    },
    xs: {
        fontSize: 13,
        lineHeight: '16px'
    },
    sm: {
        fontSize: 14,
        lineHeight: '20px'
    },
    md: {
        fontSize: 16,
        lineHeight: '24px'
    },
    lg: {
        fontSize: 20,
        lineHeight: '24px'
    },
    xl: {
        fontSize: 24,
        lineHeight: '30px'
    },
    xxl: {
        fontSize: 28,
        lineHeight: '34px'
    },
    xxxl: {
        fontSize: 48,
        lineHeight: '60px'
    }
};

const fonts = {
    xxsLight: { ...weights.light, ...sizes.xxs },
    xxsNormal: { ...weights.normal, ...sizes.xxs },
    xxsBold: { ...weights.bold, ...sizes.xxs },

    xsLight: { ...weights.light, ...sizes.xs },
    xsNormal: { ...weights.normal, ...sizes.xs },
    xsBold: { ...weights.bold, ...sizes.xs },

    smLight: { ...weights.light, ...sizes.sm },
    smNormal: { ...weights.normal, ...sizes.sm },
    smBold: { ...weights.bold, ...sizes.sm },

    mdLight: { ...weights.light, ...sizes.md },
    mdNormal: { ...weights.normal, ...sizes.md },
    mdBold: { ...weights.bold, ...sizes.md },

    lgLight: { ...weights.light, ...sizes.lg },
    lgNormal: { ...weights.normal, ...sizes.lg },
    lgBold: { ...weights.bold, ...sizes.lg },

    xlLight: { ...weights.light, ...sizes.xl },
    xlNormal: { ...weights.normal, ...sizes.xl },
    xlBold: { ...weights.bold, ...sizes.xl },

    xxlLight: { ...weights.light, ...sizes.xxl },
    xxlNormal: { ...weights.normal, ...sizes.xxl },
    xxlBold: { ...weights.bold, ...sizes.xxl },

    xxxlLight: { ...weights.light, ...sizes.xxxl },
    xxxlNormal: { ...weights.normal, ...sizes.xxxl },
    xxxlBold: { ...weights.bold, ...sizes.xxxl }
};

export default fonts;