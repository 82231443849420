import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from "moment";
import { Col, Row } from "antd";
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { translate, components as Core, DATE_FORMATS } from '../../../core';
import { colors, css, fonts, spacing, StyleSheet } from "../../../../styles";
import TicketListReport from './TicketListReport';

class SentMessages extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        companyId: PropTypes.string.isRequired,
        sentMessagesByYear: PropTypes.object,
        isLoading: PropTypes.bool,
        companyLimit: PropTypes.number
    };

    static defaultProps = {
        sentMessagesByYear: null,
        isLoading: false,
        companyLimit: null
    };

    constructor(props) {
        super(props);
        this.props.actions.getSentMessages(this.props.companyId);
        this.state = {
            year: moment().format(DATE_FORMATS.YEAR)
        }
    }

    get content() {
        const { i18n, sentMessagesByYear, companyLimit, companyId } = this.props;
        return sentMessagesByYear ? (
            <div>
                <Row className={css(styles.row)} type='flex'>
                    {_.map(_.get(sentMessagesByYear, 'ticketsCountByMonth', []), this.renderMonth)}
                </Row>
                {companyLimit && (
                    <Row className={css(styles.totalRow)} type='flex'>
                        <p className={css(styles.title)}>{i18n.t('companies.ticketsLimit')}: </p>
                        <p className={css(styles.value)}>{companyLimit}</p>
                    </Row>
                )}
                <Row className={css(styles.totalRow)} type='flex'>
                    <p className={css(styles.title)}>{i18n.t('companies.totalTicketsCount')}: </p>
                    <p className={css(styles.value)}>{_.get(sentMessagesByYear, 'totalTicketsCount')}</p>
                </Row>
                <Row className={css(styles.totalRow)} type='flex'>
                    <p className={css(styles.title)}>{i18n.t('companies.totalTicketsWithResponsesCount')}: </p>
                    <p className={css(styles.value)}>{_.get(sentMessagesByYear, 'totalTicketsWithResponses')}</p>
                </Row>
                <Row className={css(styles.totalRow)} type='flex'>
                    <p className={css(styles.title)}>{i18n.t('companies.ticketsDuringTrialPeriod')}: </p>
                    <p className={css(styles.value)}>{_.get(sentMessagesByYear, 'trialTiketsCountWithResponce')}</p>
                </Row>
                <TicketListReport companyId={companyId} />
            </div>
        ) : null
    }

    formatDateInterval = item => `${moment(item.intervalFrom).format(DATE_FORMATS.DAY_MONTH)} - ${moment(item.intervalTo).format(DATE_FORMATS.DAY_MONTH)}`;

    renderMonth = item => {
        const { companyLimit } = this.props;
        return (
            <Col md={2} className={css(styles.column, (companyLimit && item.ticketsWithResponsesCount > companyLimit) ? styles.overLimitedTicketsCount : '')} key={item.intervalFrom}>
                <p className={css(styles.title)}>{this.formatDateInterval(item)}</p>
                <p>{item.ticketsCount}/{item.ticketsWithResponsesCount}</p>
            </Col>
        )
    };

    onChangeYear = year => {
        this.setState({ year });
        this.props.actions.getSentMessages(this.props.companyId, year);
    };

    render() {
        const { year } = this.state;
        const { isLoading } = this.props;
        return isLoading ? <Core.Spinner /> : (
            <div>
                <Core.PickerYear onChange={this.onChangeYear} year={year} />
                {this.content}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    integrations: selectors.getIntegrationsByCompany(state, ownProps.companyId),
    isLoading: selectors.isGettingSentMessages(state, ownProps.companyId),
    sentMessagesByYear: selectors.getCompaniesSentMessages(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SentMessages));

const styles = StyleSheet.create({
    row: {
        marginTop: spacing.s2,
        marginBottom: spacing.s2,
    },
    column: {
        backgroundColor: colors.white,
        padding: spacing.s1,
        margin: spacing.s0,
        boxShadow: `0px 0px ${spacing.s2}px ${spacing.s0}px ${colors.shadow}`,
        borderRadius: spacing.s0
    },
    overLimitedTicketsCount: {
        boxShadow: `0px 0px ${spacing.s1}px 0px ${colors.red}`
    },
    title: {
        ...fonts.mdBold
    },
    value: {
        ...fonts.mdLight,
        marginLeft: spacing.s0
    },
    totalRow: {
        alignItems: 'center'
    }
});