/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import spacing from "./spacing";

export const spacingBuilder = field => {
    const result = {};
    for (const key in spacing) {
        result[key] = {
            [field]: spacing[key]
        };
    }
    return result;
};