import React, { useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { Chart } from "../../../core/components";
import { StyleSheet, fonts, colors, chartColors, css, spacing } from '../../../../styles';
import { toLocalTime, translate } from "../../../core";
import FeedbackTitle from "./FeedbackTitle";
import * as selectors from "../../selectors";

const FeedbackChart = ({ i18n, isLoading, stats }) => {
    const options = {
        chartArea: {
            top: '8%',
            left: '8%',
            width: '85%',
            height: '70%'
        },
        curveType: "function",
        hAxis: {
            textStyle: {
                color: colors.grey,
                fontSize: fonts.xxsNormal.fontSize
            }
        },
        vAxis: {
            minValue: 0,
            textStyle: {
                color: colors.black,
                fontSize: fonts.xxsNormal.fontSize
            },
            gridlines: {
                color: colors.greyLight
            }
        },
        colors: _.uniqBy(_.shuffle(chartColors), item => item),
        legend: {
            position: 'bottom',
            textStyle: {
                color: colors.black
            }
        },
        pointSize: 4,
        backgroundColor: colors.white,
        tooltip: {
            textStyle: {
                color: colors.black,
                fontSize: fonts.xsNormal.fontSize
            }
        }
    };

    const data = useCallback(() => [
        [i18n.t('period'), i18n.t('feedback.chart.promoters'), i18n.t('feedback.chart.detractors'), i18n.t('feedback.chart.passives')],
        ...(_.map(stats, item => [toLocalTime.toLocalDate(item.date), item.promotersCount, item.detractorsCount, item.passivesCount])),
    ], [i18n, stats]);

    const title = (
        <FeedbackTitle title={i18n.t('feedback.timeComparison')} />
    );

    return (
        <div className={css(styles.chartContainer)}>
            <Chart
                options={options}
                data={data()}
                chartType="LineChart"
                titleComponent={title}
                chartClassName={styles.chart}
                isLoading={isLoading}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    isLoading: selectors.isLoadingPromoterScoreStats(state),
    stats: selectors.getPromoterScoresStats(state)
});

export default connect(mapStateToProps)(translate()(FeedbackChart));

FeedbackChart.propTypes = {
    i18n: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    stats: PropTypes.array
};

FeedbackChart.defaultProps = {
    isLoading: false,
    stats: []
};

const styles = StyleSheet.create({
    chartContainer: {
        display: 'flex',
        flex: 1,
        height: 614,
        flexDirection: 'column',
        width: `100%`,
        marginBottom: spacing.s8,
        '& svg>g>g:last-child': {
            pointerEvents: 'none'
        },
        '& .google-visualization-tooltip > path': {
            fill: colors.white,
            stroke: colors.grey
        }
    },
    chart: {
        backgroundColor: colors.white,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
        overflow: 'hidden',
        height: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: spacing.s3,
        marginTop: spacing.s3
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
        textAlign: 'center'
    }
});