import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components as Core, shuffleArray, toLocalTime, useTranslation } from '../../../../../../core';
import * as selectors from '../../../../../selectors';
import { getRegistrationsChartData } from '../../../../../actions';
import { StyleSheet, css, colors, spacing, fonts, chartColors } from '../../../../../../../styles';

export const RegistrationsChart = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegistrationsChartData());
    }, []);

    const isLoading = useSelector(selectors.isLoadingRegistrationsChartData);
    const data = useSelector(selectors.getRegistrationsChartData);

    const options = {
        chartArea: {
            top: '8%',
            left: '8%',
            width: '85%',
            height: '70%'
        },
        curveType: "function",
        hAxis: {
            textStyle: {
                color: colors.grey,
                fontSize: fonts.xxsNormal.fontSize
            }
        },
        vAxis: {
            minValue: 0,
            textStyle: {
                color: colors.black,
                fontSize: fonts.xxsNormal.fontSize
            },
            gridlines: {
                color: colors.greyLight
            }
        },
        colors: [...new Set(shuffleArray(chartColors))],
        legend: {
            position: 'bottom',
            textStyle: {
                color: colors.black
            }
        },
        pointSize: 4,
        backgroundColor: colors.white,
        tooltip: {
            textStyle: {
                color: colors.black,
                fontSize: fonts.xsNormal.fontSize
            }
        }
    };

    const getData = () => [
        [t('period'), t('registrations')],
        ...(data.map(item => [toLocalTime.toLocalDate(item.date), item.count])),
    ];

    return (
        <div className={css(styles.chartContainer)}>
            <Core.Chart
                options={options}
                data={getData()}
                chartType="LineChart"
                chartClassName={styles.chart}
                isLoading={isLoading}
            />
        </div>
    )
};

const styles = StyleSheet.create({
    chartContainer: {
        paddingLeft: spacing.s4,
        display: 'flex',
        flex: 1,
        height: 614,
        flexDirection: 'column',
        width: `100%`,
        marginBottom: spacing.s8,
        '& svg>g>g:last-child': {
            pointerEvents: 'none'
        },
        '& .google-visualization-tooltip > path': {
            fill: colors.white,
            stroke: colors.grey
        }
    },
    chart: {
        backgroundColor: colors.white,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
        overflow: 'hidden',
        height: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: spacing.s3,
        marginTop: spacing.s3
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
        textAlign: 'center'
    }
});