import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { components as Core, constants as coreConstants, getPagination, getTableProps, translate } from "../../../core";
import * as selectors from "../../selectors";
import * as actions from "../../actions";
import valueBuilder from "../../services/valueBuilder";
import UserDetailsModal from "../UserDetailsModal/UserDetailsModal";
import UserActions from "./UserActions";
import NewNotificationModal from "./NewNotificationModal";
import UserFullNameCell from "../Users/UserFullNameCell";
import ResetNpsForUsersModal from './ResetNpsForUsersModal';
import { css, spacing, StyleSheet } from "../../../../styles";

export const COLUMNS = {
    fullName: 'fullName',
    email: 'email',
    lastUserAction: 'lastUserAction',
    nextReviewDate: 'nextReviewDate',
    actions: 'actions'
};

class UsersTab extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        users: PropTypes.array,
        pagination: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isSendingNotification: PropTypes.bool,
        isResettingNPSForUsers: PropTypes.bool
    };

    static defaultProps = {
        users: [],
        isLoading: false,
        isSendingNotification: false,
        isResettingNPSForUsers: false
    };

    constructor(props) {
        super(props);
        this.state = {
            modalUserId: null,
            selectedRowKeys: [],
            searchText: '',
            isVisibleNotification: false,
            isVisibleResetNpsModal: false,
        };
        this.props.actions.getAllUsers();
    }

    get tableColumns() {
        const { i18n } = this.props;
        return [{
            title: i18n.t("users.fullName"),
            key: COLUMNS.fullName,
            render: this.renderFullName
        }, {
            title: i18n.t("users.email"),
            key: COLUMNS.email,
            dataIndex: COLUMNS.email,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("users.lastUserAction"),
            key: COLUMNS.lastUserAction,
            dataIndex: COLUMNS.lastUserAction,
            render: valueBuilder.getLocalFullTime
        }, {
            title: i18n.t("users.nextReviewDate"),
            key: COLUMNS.nextReviewDate,
            dataIndex: COLUMNS.nextReviewDate,
            render: valueBuilder.getLocalFullTime
        }, {
            title: i18n.t("companies.actions"),
            key: COLUMNS.action,
            render: this.renderActionColumn
        }];
    }

    get pagination() {
        return getPagination(this.props.pagination);
    }

    get rowSelection() {
        const { selectedRowKeys } = this.state;
        return {
            selectedRowKeys,
            onChange: this.onChangeSelectedRows
        };
    }

    get rightContent() {
        const { i18n, isSendingNotification, isResettingNPSForUsers } = this.props;
        const { selectedRowKeys } = this.state;
        return (
            <div className={css(styles.headerButtons)}>
                {selectedRowKeys.length ?
                    <Core.Button
                        label={i18n.t('users.sendNps')}
                        onPress={this.onToggleResetNpsModal}
                        isLoading={isResettingNPSForUsers}
                        className={styles.resetNpsButton}
                    /> : null
                }
                <Core.Button
                    label={i18n.t('users.newNotification')}
                    onPress={this.onChangeVisibilityNotification}
                    isLoading={isSendingNotification}
                />
            </div>
        );
    }

    onChangeVisibilityNotification = () => {
        this.setState(prevState => ({ isVisibleNotification: !prevState.isVisibleNotification }))
    };

    onToggleResetNpsModal = () => {
        this.setState(prevState => ({ isVisibleResetNpsModal: !prevState.isVisibleResetNpsModal }))
    };

    onChangeSelectedRows = (selectedRowKeys, selectedRows) => {
        const allRows = _.concat(this.state.selectedRows || [], selectedRows);
        const newRows = _.uniqBy(_.filter(allRows, user => _.includes(selectedRowKeys, user.id)), 'id');
        this.setState({ selectedRowKeys, selectedRows: newRows });
    };

    renderFullName = user => <UserFullNameCell user={user} />;

    renderActionColumn = user => <UserActions user={user} />;

    onChangeTable = (pagination, filters, sorter) => {
        this.setState({ pagination, filters, sorter }, this.onLoadUsers);
    };

    onOpenUserDetailsModal = user => {
        if (user.id !== this.state.modalUserId) {
            this.setState({ modalUserId: user.id });
        }
    };

    onCloseUserDetailsModal = () => {
        this.setState({ modalUserId: null });
    };

    onChangeSearchText = searchText => this.setState({ searchText });

    onSearch = () => {
        this.onLoadUsers(1);
    };

    onLoadUsers = (page = null) => {
        const { pagination, filters, sorter } = this.state;
        this.props.actions.getAllUsers({
            ...getTableProps(page ? { current: page } : pagination, filters, sorter),
            term: this.state.searchText
        });
    };

    render() {
        const { i18n, users, isLoading } = this.props;
        const { modalUserId, searchText, isVisibleNotification, selectedRows, isVisibleResetNpsModal } = this.state;
        return (
            <>
                <Core.Table
                    rowSelection={this.rowSelection}
                    title={i18n.t('screens.users')}
                    columns={this.tableColumns}
                    data={users}
                    pagination={this.pagination}
                    isLoading={isLoading}
                    onChange={this.onChangeTable}
                    onPressRow={this.onOpenUserDetailsModal}
                    hasSearch={true}
                    searchText={searchText}
                    onSearch={this.onSearch}
                    onChangeSearchText={this.onChangeSearchText}
                    rightContent={this.rightContent}
                />
                {modalUserId && (
                    <UserDetailsModal
                        userId={modalUserId}
                        onClose={this.onCloseUserDetailsModal}
                        isUserFromAll={true}
                    />
                )
                }
                {isVisibleNotification && (
                    <NewNotificationModal
                        onClose={this.onChangeVisibilityNotification}
                        selectedUsers={selectedRows}
                        onChangeSelectedUsers={this.onChangeSelectedRows}
                    />
                )}
                {isVisibleResetNpsModal ?
                    <ResetNpsForUsersModal
                        onClose={this.onToggleResetNpsModal}
                        selectedUsers={selectedRows}
                        onChangeSelectedUsers={this.onChangeSelectedRows}
                    /> : null
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    users: selectors.getAllUsers(state),
    pagination: selectors.getAllUsersPagination(state),
    isLoading: selectors.isGettingAllUsers(state),
    isSendingNotification: selectors.isSendingNotificationForAll(state) || selectors.isSendingNotificationForUsers(state),
    isResettingNPSForUsers: selectors.isResettingNPSForUsers(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UsersTab));

const styles = StyleSheet.create({
    headerButtons: {
        display: 'flex'
    },
    resetNpsButton: {
        marginRight: spacing.s2
    }
})
