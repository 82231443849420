import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, components as Core } from '../../../core';
import { common, css, marginsRight, paddings } from '../../../../styles';

export const ButtonWithPopover = ({ label, onPress, isLoading }) => {
    const { t } = useTranslation();
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);

    const togglePopover = () => setIsVisiblePopup(prev => !prev);

    const handlePress = value => () => {
        onPress(value);
        setIsVisiblePopup(false);
    }

    const getPopoverContent = () => (
        <div className={css([common.flexRowEnd, paddings.s3])}>
            <Core.Button
                onPress={handlePress(true)}
                label={t('integrations.notifyAboutBlocking')}
                className={marginsRight.s2}
            />
            <Core.Button
                onPress={handlePress(false)}
                label={t('integrations.skipNotifyAboutBlocking')}
            />
        </div>
    )

    return (
        <Core.Popover
            content={getPopoverContent()}
            visible={isVisiblePopup}
            onVisibleChange={setIsVisiblePopup}>
            <>
                <Core.Button
                    label={label}
                    onPress={togglePopover}
                    isLoading={isLoading}
                />
            </>
        </Core.Popover>
    );
};

ButtonWithPopover.propTypes = {
    label: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    isLoading: PropTypes.bool
};

ButtonWithPopover.defaultProps = {
    onPress: null,
    isLoading: false,
};