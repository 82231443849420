import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { components as Core, translate } from "../../../core";
import * as selectors from "../../selectors";
import * as actions from "../../actions";
import { USER_STATUSES } from "../../constants";
import loginAsUser from "../../services/loginAsUser";
import { spacing, StyleSheet, css } from "../../../../styles";

class UserActions extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        isLogining: PropTypes.bool,
        userLogin: PropTypes.object,
        actions: PropTypes.object.isRequired,
        isUnblockingUser: PropTypes.bool
    };

    static defaultProps = {
        isLogining: false,
        userLogin: null,
        isUnblockingUser: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isLogining && !this.props.isLogining && this.props.userLogin) {
            loginAsUser(this.props.userLogin);
        }
    }

    componentWillUnmount() {
        this.props.actions.clearLoginAsUser();
    }

    loginAsUser = user => {
        this.props.actions.loginAsUser(user.id);
    };

    unblockUser = user => {
        this.props.actions.unblockUser(user.id);
    };

    render() {
        const { user, isLogining, isUnblockingUser } = this.props;
        return (
            <>
                <Core.TableAction
                    onPress={this.loginAsUser}
                    text={this.props.i18n.t("users.loginAsUser")}
                    data={user}
                    isButton={true}
                    isLoading={isLogining}
                />
                {user.status === USER_STATUSES.blocked ?
                    <span className={css(styles.unblockButton)}>
                        <Core.TableAction
                            onPress={this.unblockUser}
                            text={this.props.i18n.t("users.unblockUser")}
                            data={user}
                            isButton={true}
                            isLoading={isUnblockingUser}
                        />
                    </span> : null
                }
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    userLogin: selectors.getUserLogin(state, ownProps.user.id),
    isLogining: selectors.isLoginingAsUser(state, ownProps.user.id),
    isUnblockingUser: selectors.isUnblockingUser(state, ownProps.user.id)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserActions));


const styles = StyleSheet.create({
    unblockButton: {
        paddingLeft: spacing.s3
    }
});