import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { images } from '../../../../assets';
import { StyleSheet, css, colors, common, spacing, fonts } from '../../../../styles';
import { components as Core, translate } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

class Login extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        isSigningIn: PropTypes.bool
    };

    static defaultProps = {
        isSigningIn: false
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    onChangeEmail = email => this.setState({ email });

    onChangePassword = password => this.setState({ password });

    onSignIn = () => {
        this.props.actions.signIn(this.state.email, this.state.password);
    };

    render() {
        const { i18n, isSigningIn } = this.props;
        return (
            <div className={css(styles.container)}>
                <Row type="flex" justify="space-between" className={css(styles.innerContainer)}>
                    <Col xs={24} sm={24} md={12} className={css(styles.leftContainer)}>
                        <div className={css(common.flexColCenter)}>
                            <div className={css(common.flexColCenter, styles.childContainer, styles.leftChildContainer)}>
                                <div className={css(styles.logoContainer)}>
                                    <Core.Logo hasRevertedColors={true} />
                                </div>
                                <div>
                                    <img src={images.LoginForeground} className={css(styles.foregroundImage)} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} className={css(common.flexColCenter, styles.rightContainer)}>
                        <div className={css(common.flexColCenter, styles.childContainer, styles.rightChildContainer)}>
                            <p className={css(styles.title)}>
                                {i18n.t('adminLogIn.part1')}
                                <span>{i18n.t('adminLogIn.part2')}</span>
                            </p>
                            <Core.Input
                                label={i18n.t('email')}
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                className={styles.input}
                                hasCommonStyles={false}
                            />
                            <Core.Input
                                label={i18n.t('password')}
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                isPassword={true}
                                onPressEnter={this.onSignIn}
                                className={styles.input}
                                hasCommonStyles={false}
                            />
                            <Core.Button
                                label={i18n.t('signIn')}
                                onPress={this.onSignIn}
                                isLoading={isSigningIn}
                                isDisabled={isSigningIn}
                                hasFullWidth={true}
                                className={styles.signInButton}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isSigningIn: selectors.isSigningIn(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Login));

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        minHeight: '100vh'
    },
    innerContainer: {
        flex: 1
    },
    childContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s9,
        paddingBottom: spacing.s9,
        width: '100%',
        height: '100%'
    },
    leftChildContainer: {
        maxWidth: 600
    },
    rightChildContainer: {
        maxWidth: 400,
        justifyContent: 'center',
        marginBottom: spacing.s13
    },
    leftContainer: {
        backgroundImage: 'url("./assets/loginBackground.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: colors.primary
    },
    logoContainer: {
        alignSelf: 'flex-start',
        marginBottom: spacing.s9,
        maxWidth: '100%'
    },
    foregroundImage: {
        maxWidth: '100%',
        width: 'auto'
    },
    rightContainer: {
        backgroundColor: colors.background
    },
    title: {
        ...fonts.xxxlBold,
        color: colors.primary,
        marginBottom: spacing.s11,
        '& span': {
            ...fonts.xxxlLight
        }
    },
    signInButton: {
        ...fonts.xlNormal,
        marginTop: spacing.s11
    },
    input: {
        marginBottom: spacing.s5
    }
});