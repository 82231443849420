import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components as Core, getPagination, useTranslation, usePrevious, getTableProps, constants as coreConstants } from '../../../core';
import { valueBuilder } from '../../../companies';
import { colors, common, css, fonts, paddingsRight, StyleSheet } from '../../../../styles';
import * as dashboardSelectors from '../../selectors';
import { getUsageReportData } from '../../actions';
import { useUsageReportFilters } from './useUsageReportFilters';
import { CompanySubscriptionPeriodTypes, SubscriptonUsageTypes } from '../../constants';

const COLUMNS = {
    companyEmail: 'companyEmail',
    lastSubPeriodStartDate: 'lastSubPeriodStartDate',
    ticketsLimit: 'ticketsLimit',
    currentAndExpectedUsage: 'currentAndExpectedUsage',
    overusage: 'overusage'
};

export const UsageReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isLoading = useSelector(dashboardSelectors.isLoadingUsageReportData);
    const data = useSelector(dashboardSelectors.getUsageReport);
    const pagination = useSelector(dashboardSelectors.getUsageReportPagination);

    const [searchText, setSearchText] = useState('');
    const [period, onChangeSubscriptionPeriod] = useState(CompanySubscriptionPeriodTypes.all);
    const [subscriptionUsageType, onChangeSubscriptionUsage] = useState(SubscriptonUsageTypes.usageBased);
    const [region, onChangeRegionType] = useState(null);
    const prevRegion = usePrevious(region);
    const prevPeriod = usePrevious(period);
    const prevSubscriptionUsageType = usePrevious(subscriptionUsageType);

    const { getFilters } = useUsageReportFilters({ onChangeSubscriptionPeriod, onChangeSubscriptionUsage, onChangeRegionType });

    useEffect(() => {
        dispatch(getUsageReportData({
            period, subscriptionUsageType, region
        }))
    }, []);

    useEffect(() => {
        if ((prevPeriod !== undefined && period !== prevPeriod) ||
            (prevSubscriptionUsageType !== undefined && subscriptionUsageType !== prevSubscriptionUsageType) ||
            (prevRegion !== undefined && region !== prevRegion)) {
            dispatch(getUsageReportData({
                ...pagination,
                period,
                subscriptionUsageType,
                region
            }));
        }
    }, [period, subscriptionUsageType, region]);

    const getTableColumns = () => {
        return ([{
            title: t("usageReport.companyEmail"),
            key: COLUMNS.companyEmail,
            dataIndex: COLUMNS.companyEmail
        }, {
            title: t("usageReport.lastSubPeriodStartDate"),
            key: COLUMNS.lastSubPeriodStartDate,
            dataIndex: COLUMNS.lastSubPeriodStartDate,
            render: valueBuilder.getDate,
        }, {
            title: t("usageReport.currentAndExpectedUsage"),
            key: COLUMNS.currentAndExpectedUsage,
            render: renderCurrentAndExpectedUsage
        }, {
            title: t("usageReport.overusage"),
            key: COLUMNS.overusage,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS,
            render: renderOverusage
        }, {
            title: t("usageReport.ticketsLimit"),
            dataIndex: COLUMNS.ticketsLimit,
            key: COLUMNS.ticketsLimit
        }]);
    };

    const getTextColor = item => {
        const { currentUsage, expectedUsage } = item;

        const diff = (currentUsage / expectedUsage) * 100;

        if (expectedUsage && diff >= 95 && diff <= 105) return styles.yellowColor
        if (expectedUsage && diff > 105) return styles.redText
        return styles.text;
    };

    const renderCurrentAndExpectedUsage = item => {
        const { currentUsage, expectedUsage } = item;

        const usedPercentage = (currentUsage / expectedUsage) * 100;

        return (
            <p className={css([styles.text, common.flexRowStart])}>
                <p className={css(paddingsRight.s1)}>{`${currentUsage} / ${expectedUsage}`}</p>
                {expectedUsage && (
                    <p className={css(getTextColor(item))}>{`(${usedPercentage.toFixed(2)}%)`}</p>
                )}
            </p>
        );
    }

    const renderOverusage = item => {
        const { overusage, expectedUsage } = item;
        return expectedUsage ? (
            <p className={css([styles.text, getTextColor(item)])}>{overusage}</p>
        ) : null;
    };

    const getPaginationForTable = () => getPagination(pagination);

    const onSearch = () => {
        dispatch(getUsageReportData({
            ...pagination,
            period,
            subscriptionUsageType,
            region,
            term: searchText
        }));
    };

    const onChangeTable = (pagination, filters, sorter) => {
        dispatch(getUsageReportData({
            ...getTableProps(pagination, filters, sorter),
            period,
            subscriptionUsageType,
            region,
            term: searchText
        }))
    };

    return (
        <div>
            <Core.Table
                columns={getTableColumns()}
                data={data}
                isLoading={isLoading}
                pagination={getPaginationForTable()}
                hasSearch={true}
                searchText={searchText}
                onSearch={onSearch}
                onChangeSearchText={setSearchText}
                hasCustomFilters
                customFilters={getFilters()}
                onChange={onChangeTable}
            />
        </div>
    );
};
const styles = StyleSheet.create({
    text: {
        ...fonts.xsBold
    },
    redText: {
        color: colors.red
    },
    greenText: {
        color: colors.green
    },
    yellowColor: {
        color: colors.yellow
    }
});