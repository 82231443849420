import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as companySelectors, actions as companyActions } from '../../companies';

const { getSubscriptionPlans } = companyActions;

export const useSubscription = () => {
    const dispatch = useDispatch();
    const subscriptionPlans = useSelector(companySelectors.getSubscriptionPlans);

    useEffect(() => {
        if (!subscriptionPlans || !subscriptionPlans.length) {
            dispatch(getSubscriptionPlans());
        }
    }, []);

    const getSubscriptionPlan = useCallback(subscriptionPlanId => {
        if (!subscriptionPlans || !subscriptionPlans.length) return null;
        const subscriptionPlan = subscriptionPlans.find(item => item.id === subscriptionPlanId);
        if (!subscriptionPlan) return null;
        return ({
            text: `${subscriptionPlan.name}  |  ${subscriptionPlan.price}`,
            value: subscriptionPlan.id
        });
    }, [subscriptionPlans]);

    return {
        getSubscriptionPlan
    };
};
