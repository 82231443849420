/* eslint-disable no-prototype-builtins */
import { actionTypes as coreActionTypes, getObjectValue, omitObjectKey } from '../core';
import * as actionTypes from './actionTypes';
import { USER_STATUSES } from "./constants";

const initialState = {
    companies: {
        items: {},
        ids: [],
        pagination: {}
    },
    users: {
        items: {},
        companies: {},
        logins: {
            items: {}
        }
    },
    activities: {
        items: {},
        users: {}
    },
    integrations: {
        items: {},
        companies: {}
    },
    allIntegrations: {
        items: {},
        ids: [],
        pagination: {}
    },
    integrationLogs: {},
    allUsers: {
        items: {},
        ids: [],
        pagination: {}
    },
    notifications: {
        items: {},
        users: {}
    },
    sentMessages: {
        companies: {}
    },
    setupSettings: {
        companies: {
            labels: [],
            rules: [],
            templates: [],
            autoResponses: [],
            customFields: []
        }
    },
    promoterScores: {
        items: {},
        ids: [],
        pagination: {},
        stats: [],
        missedNpsCount: null,
        commentsByUser: {}
    },
    adminSettings: {},
    removedThreads: {},
    importedThreads: {},
    subscriptionPlans: {
        items: {},
        ids: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANIES.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.UPDATE_COMPANY.SUCCESS: {
            const { company } = action.payload;
            return state.companies.items.hasOwnProperty(company.id) ? {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [company.id]: {
                            ...state.companies.items[company.id],
                            ...company
                        }
                    }
                }
            } : state;
        }
        case actionTypes.COMPANY_CUSTOMIZE_UI.SUCCESS:
        case actionTypes.GET_COMPANY_CUSTOMIZATION_UI.SUCCESS: {
            const { companyId, data } = action.payload;
            return state.companies.items.hasOwnProperty(companyId) ? {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [companyId]: {
                            ...state.companies.items[companyId],
                            ...data
                        }
                    }
                }
            } : state;
        }
        case actionTypes.DELETE_COMPANY.SUCCESS: {
            const { companyId } = action.payload;
            return state.companies.items.hasOwnProperty(companyId) ? {
                ...state,
                companies: {
                    ...state.companies,
                    items: omitObjectKey(state.companies.items, [companyId]),
                    ids: state.companies.ids.filter(id => id !== companyId)
                }
            } : state;
        }
        case actionTypes.GET_USERS.SUCCESS: {
            const { items, ids, companyId, pagination } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    items,
                    companies: {
                        [companyId]: {
                            ids,
                            pagination
                        }
                    }
                }
            };
        }
        case actionTypes.LOGIN_AS_USER.SUCCESS: {
            const { userId, userData } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    logins: {
                        ...state.users.logins,
                        items: {
                            [userId]: userData
                        }
                    }
                }
            };
        }
        case actionTypes.CLEAR_LOGIN_AS_USER: {
            return {
                ...state,
                users: {
                    ...state.users,
                    logins: {
                        items: {}
                    }
                }
            };
        }
        case actionTypes.UNBLOCK_USER.SUCCESS: {
            const { userId } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    items: state.users.items.hasOwnProperty(userId) ? {
                        ...state.users.items,
                        [userId]: {
                            ...state.users.items[userId],
                            status: USER_STATUSES.active
                        }
                    } : state.users.items
                },
                allUsers: {
                    ...state.allUsers,
                    items: state.allUsers.items.hasOwnProperty(userId) ? {
                        ...state.allUsers.items,
                        [userId]: {
                            ...state.allUsers.items[userId],
                            status: USER_STATUSES.active
                        }
                    } : state.allUsers.items
                }
            };
        }
        case actionTypes.GET_USER_ACTIVITIES.SUCCESS: {
            const { items, ids, userId, pagination } = action.payload;
            return {
                ...state,
                activities: {
                    ...state.activities,
                    items,
                    users: {
                        [userId]: {
                            ids,
                            pagination
                        }
                    }
                }
            };
        }
        case actionTypes.GET_INTEGRATIONS.SUCCESS: {
            const { items, ids, companyId, pagination } = action.payload;
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    items,
                    companies: {
                        [companyId]: {
                            ids,
                            pagination
                        }
                    }
                }
            };
        }
        case actionTypes.GET_ALL_INTEGRATIONS.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                allIntegrations: {
                    ...state.allIntegrations,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.UNBLOCK_MARKET_SYNC_INTEGRATION.SUCCESS:
        case actionTypes.BLOCK_MARKET_SYNC_INTEGRATION.SUCCESS:
        case actionTypes.UNBLOCK_INTEGRATION.SUCCESS:
        case actionTypes.TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION.SUCCESS:
        case actionTypes.BLOCK_INTEGRATION.SUCCESS: {
            const { integration } = action.payload;
            return {
                ...state,
                ...(state.integrations.items.hasOwnProperty(integration.id) ? {
                    integrations: {
                        ...state.integrations,
                        items: {
                            ...state.integrations.items,
                            [integration.id]: integration
                        }
                    }
                } : {}),
                ...(state.allIntegrations.items.hasOwnProperty(integration.id) ? {
                    allIntegrations: {
                        ...state.allIntegrations,
                        items: {
                            ...state.allIntegrations.items,
                            [integration.id]: {
                                ...state.allIntegrations.items[integration.id],
                                isBlocked: getObjectValue(integration, 'isBlocked', false),
                                blockedMarketSync: getObjectValue(integration, 'blockedMarketSync', false),
                                failMessage: getObjectValue(integration, 'failMessage', ''),
                                needRelogin: getObjectValue(integration, 'needRelogin', false),
                                isExceededLimitBlocked: getObjectValue(integration, 'isExceededLimitBlocked', false)
                            }
                        }
                    }
                } : {}),
            };
        }
        case actionTypes.RELOGIN_INTEGRATION.SUCCESS: {
            const { integrationId } = action.payload;
            return {
                ...state,
                ...(state.integrations.items.hasOwnProperty(integrationId) ? {
                    integrations: {
                        ...state.integrations,
                        items: {
                            ...state.integrations.items,
                            [integrationId]: {
                                ...state.integrations.items[integrationId],
                                needRelogin: !state.integrations.items[integrationId].needRelogin
                            }
                        }
                    }
                } : {}),
                ...(state.allIntegrations.items.hasOwnProperty(integrationId) ? {
                    allIntegrations: {
                        ...state.allIntegrations,
                        items: {
                            ...state.allIntegrations.items,
                            [integrationId]: {
                                ...state.allIntegrations.items[integrationId],
                                needRelogin: !state.allIntegrations.items[integrationId].needRelogin
                            }
                        }
                    }
                } : {}),
            };
        }
        case actionTypes.UPDATE_INTEGRATION.SUCCESS: {
            const { integration } = action.payload;
            return {
                ...state,
                ...(state.integrations.items.hasOwnProperty(integration.id) ? {
                    integrations: {
                        ...state.integrations,
                        items: {
                            ...state.integrations.items,
                            [integration.id]: {
                                ...state.integrations.items[integration.id],
                                ...integration
                            }
                        }
                    }
                } : {}),
                ...(state.allIntegrations.items.hasOwnProperty(integration.id) ? {
                    allIntegrations: {
                        ...state.allIntegrations,
                        items: {
                            ...state.allIntegrations.items,
                            [integration.id]: {
                                ...state.allIntegrations.items[integration.id],
                                ...integration
                            }
                        }
                    }
                } : {}),
            };
        }
        case actionTypes.MIGRATE_TO_AMAZON_SP.SUCCESS:
        case actionTypes.MIGRATE_TO_NATIVE_OUTLOOK.SUCCESS: {
            const { integrationId, newData } = action.payload;
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    items: state.integrations.items.hasOwnProperty(integrationId) ? {
                        ...state.integrations.items,
                        [integrationId]: {
                            ...state.integrations.items[integrationId],
                            ...newData
                        }
                    } : state.integrations.items
                },
                allIntegrations: {
                    ...state.allIntegrations,
                    items: state.allIntegrations.items.hasOwnProperty(integrationId) ? {
                        ...state.allIntegrations.items,
                        [integrationId]: {
                            ...state.allIntegrations.items[integrationId],
                            ...newData
                        }
                    } : state.allIntegrations.items
                }
            };
        }
        case actionTypes.GET_INTEGRATION_LOGS.SUCCESS: {
            const { integrationId, pagination, items, ids } = action.payload;
            return {
                ...state,
                integrationLogs: {
                    [integrationId]: {
                        pagination,
                        items,
                        ids
                    }
                }
            };
        }
        case actionTypes.GET_ALL_USERS.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                allUsers: {
                    ...state.allUsers,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.GET_USER_NOTIFICATIONS.SUCCESS: {
            const { items, ids, userId, pagination } = action.payload;
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    items,
                    users: {
                        [userId]: {
                            ids,
                            pagination
                        }
                    }
                }
            };
        }
        case actionTypes.GET_SENT_MESSAGES.SUCCESS: {
            const { companyId, data } = action.payload;
            return {
                ...state,
                sentMessages: {
                    ...state.sentMessages,
                    companies: {
                        [companyId]: {
                            ...data
                        }
                    }
                }
            };
        }
        case actionTypes.GET_SETUP_SETTINGS.SUCCESS: {
            const { companyId, data } = action.payload;
            return {
                ...state,
                setupSettings: {
                    ...state.setupSettings,
                    companies: {
                        [companyId]: {
                            ...state.setupSettings.companies[companyId],
                            labels: data.labelSettings,
                            rules: data.ruleSettings,
                            templates: data.templateSettings,
                            autoResponses: data.autoresponceSettings,
                            customFields: data.customFieldSettings
                        }
                    }

                }
            }
        }
        case actionTypes.SETUP_MANUAL_SUBSCRIPTION.SUCCESS: {
            const company = action.payload.data;
            return state.companies.items.hasOwnProperty(company.id) ? {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [company.id]: {
                            ...state.companies.items[company.id],
                            ...company
                        }
                    }
                }
            } : state;
        }
        case actionTypes.REMOVE_SUBSCRIPTION.SUCCESS: {
            const company = action.payload.data;
            return state.companies.items.hasOwnProperty(company.id) ? {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [company.id]: {
                            ...state.companies.items[company.id],
                            ...company
                        }
                    }
                }
            } : state;
        }
        case actionTypes.GET_PROMOTER_SCORES.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                promoterScores: {
                    ...state.promoterScores,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.GET_PROMOTER_SCORE_STATS.SUCCESS: {
            return {
                ...state,
                promoterScores: {
                    ...state.promoterScores,
                    stats: action.payload
                }
            };
        }
        case actionTypes.GET_MISSED_NPS_COUNT.SUCCESS: {
            return {
                ...state,
                promoterScores: {
                    ...state.promoterScores,
                    missedNpsCount: action.payload
                }
            };
        }

        case actionTypes.GET_NPS_USER_STATS.SUCCESS: {
            return {
                ...state,
                promoterScores: {
                    ...state.promoterScores,
                    commentsByUser: action.payload.userData
                }

            };
        }

        case actionTypes.GET_ADMIN_SETTINGS.SUCCESS: {
            return {
                ...state,
                adminSettings: action.payload
            };
        }
        case actionTypes.SAVE_REPEAT_SURVEY.SUCCESS: {
            return {
                ...state,
                adminSettings: {
                    ...state.adminSettings,
                    npsRepeatSurvey: action.payload.value
                }
            };
        }
        case actionTypes.UPDATE_COMPANY_FEATURE.SUCCESS: {
            const { companyId, featureValue } = action.payload;
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [companyId]: {
                            ...state.companies.items[companyId],
                            companyFeatures: featureValue
                        }
                    }
                }
            };
        }
        case actionTypes.GET_COMPANY_REMOVED_THREADS.SUCCESS: {
            const { companyId, pagination, items, ids } = action.payload;
            return {
                ...state,
                removedThreads: {
                    [companyId]: {
                        pagination,
                        items,
                        ids
                    }
                }
            };
        }
        case actionTypes.GET_REMOVE_THREAD_LOG.SUCCESS: {
            const { companyId, threadId, removeLog } = action.payload;
            return {
                ...state,
                removedThreads: {
                    ...state.removedThreads,
                    [companyId]: {
                        ...state.removedThreads[companyId],
                        items: {
                            ...state.removedThreads[companyId].items,
                            [threadId]: {
                                ...state.removedThreads[companyId].items[threadId],
                                removeLog
                            }
                        }
                    }
                }
            };
        }
        case actionTypes.RESTORE_THREAD.SUCCESS: {
            const { companyId, threadId } = action.payload;
            return {
                ...state,
                removedThreads: {
                    ...state.removedThreads,
                    [companyId]: {
                        ...state.removedThreads[companyId],
                        items: omitObjectKey(state.removedThreads[companyId].items, threadId),
                        ids: state.removedThreads[companyId].ids.filter(id => id !== threadId)
                    }
                }
            };
        }
        case actionTypes.IMPORT_TREADS_FROM_JSON.SUCCESS: {
            const { companyId, errors, badThreads, importedSuccessfullyCount, totalCount } = action.payload;
            return {
                ...state,
                importedThreads: {
                    ...state.importedThreads,
                    [companyId]: {
                        ...state.importedThreads[companyId],
                        errors,
                        badThreads,
                        importedSuccessfullyCount,
                        totalCount
                    }
                }
            };
        }
        case actionTypes.GET_SUBSCRIPTION_PLANS.SUCCESS: {
            const { items, ids } = action.payload;
            return {
                ...state,
                subscriptionPlans: {
                    ...state.subscriptionPlans,
                    items,
                    ids
                }
            };
        }
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};