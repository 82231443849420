import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { StyleSheet, css, spacing, colors, fonts } from '../../../../styles';

class LabeledCheckBox extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        className: PropTypes.object,
        isDisabled: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        isDisabled: false,
        className: undefined,
        onChange: null
    };

    onChange = event => {
        if (this.props.onChange) {
            this.props.onChange(event.target.checked);
        }
    };

    render() {
        const { value, label, className, isDisabled } = this.props;
        return (
            <div className={css(styles.container, className)}>
                <span className={css(styles.label)}>{label}</span>
                <Checkbox
                    checked={value}
                    addonBefore={label}
                    disabled={isDisabled}
                    onChange={this.onChange}
                    className={css(styles.checkBox)}
                />
            </div>
        )
    }
}

export default LabeledCheckBox;

const styles = StyleSheet.create({
    container: {
        marginBottom: spacing.s2,
        width: '100%',
        display: 'flex'
    },
    label: {
        ...fonts.smNormal,
        lineHeight: `${fonts.smNormal.fontSize}px`,
        color: colors.inputAddonText,
        backgroundColor: colors.inputAddon,
        borderBottomLeftRadius: spacing.s0,
        borderTopLeftRadius: spacing.s0,
        border: `1px solid ${colors.border}`,
        padding: '8px 11px',
        whiteSpace: 'nowrap'
    },
    checkBox: {
        alignSelf: 'center',
        '& .ant-checkbox': {
            marginLeft: `${spacing.s2}px !important`
        },
        '& .ant-checkbox-inner': {
            borderColor: `${colors.border} !important`
        }
    }
});