import moment from 'moment';
import * as actionTypes from './actionTypes';
import * as api from './api';
import { Toast, normalize, downloadFile, handleErrors, MARKETPLACES_ENUM } from '../core';

export const getCompanies = params => dispatch => {
    dispatch({ type: actionTypes.GET_COMPANIES.REQUEST });
    return api.getCompanies(params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_COMPANIES.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data)
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_COMPANIES.ERROR });
        });
};

export const updateCompany = company => dispatch => {
    dispatch({ type: actionTypes.UPDATE_COMPANY.REQUEST, requestId: company.id });
    return api.updateCompany(company)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.UPDATE_COMPANY.SUCCESS,
                payload: {
                    company: response.data
                },
                requestId: company.id
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UPDATE_COMPANY.ERROR, requestId: company.id });
        });
};

export const deleteCompany = companyId => dispatch => {
    dispatch({ type: actionTypes.DELETE_COMPANY.REQUEST, requestId: companyId });
    return api.deleteCompany(companyId)
        .then(() => {
            Toast.success();
            dispatch({
                type: actionTypes.DELETE_COMPANY.SUCCESS,
                payload: {
                    companyId
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.DELETE_COMPANY.ERROR, requestId: companyId });
        });
};

export const getCompanyCustomizationUI = companyId => dispatch => {
    dispatch({ type: actionTypes.GET_COMPANY_CUSTOMIZATION_UI.REQUEST, requestId: companyId });
    return api.getCompanyCustomizationUi(companyId)
        .then(responce => {
            dispatch({
                type: actionTypes.GET_COMPANY_CUSTOMIZATION_UI.SUCCESS,
                payload: {
                    companyId,
                    data: responce.data
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_COMPANY_CUSTOMIZATION_UI.ERROR, requestId: companyId });
        });
};

export const companyCustomizeUI = data => dispatch => {
    dispatch({ type: actionTypes.COMPANY_CUSTOMIZE_UI.REQUEST, requestId: data.companyId });
    return api.companyCustomizeUi(data)
        .then(responce => {
            Toast.success();
            dispatch({
                type: actionTypes.COMPANY_CUSTOMIZE_UI.SUCCESS,
                payload: {
                    companyId: data.companyId,
                    data: responce.data
                },
                requestId: data.companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.COMPANY_CUSTOMIZE_UI.ERROR, requestId: data.companyId });
        });
};

export const getUsers = (companyId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_USERS.REQUEST, requestId: companyId });
    return api.getUsers(companyId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_USERS.SUCCESS,
                payload: {
                    companyId,
                    pagination,
                    ...normalize.normalizeArray(data)
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_USERS.ERROR, requestId: companyId });
        });
};

export const loginAsUser = userId => dispatch => {
    dispatch({ type: actionTypes.LOGIN_AS_USER.REQUEST, requestId: userId });
    return api.loginAsUser(userId)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.LOGIN_AS_USER.SUCCESS,
                payload: {
                    userId,
                    userData: response.data
                },
                requestId: userId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.LOGIN_AS_USER.ERROR, requestId: userId });
        });
};

export const clearLoginAsUser = () => dispatch => {
    dispatch({ type: actionTypes.CLEAR_LOGIN_AS_USER });
};

export const unblockUser = userId => dispatch => {
    dispatch({ type: actionTypes.UNBLOCK_USER.REQUEST, requestId: userId });
    return api.unblockUser(userId)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.UNBLOCK_USER.SUCCESS,
                payload: { userId },
                requestId: userId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UNBLOCK_USER.ERROR, requestId: userId });
        });
};

export const getUserActivities = (userId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_USER_ACTIVITIES.REQUEST, requestId: userId });
    return api.getUserActivities(userId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_USER_ACTIVITIES.SUCCESS,
                payload: {
                    userId,
                    pagination,
                    ...normalize.normalizeArray(data)
                },
                requestId: userId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_USER_ACTIVITIES.ERROR, requestId: userId });
        });
};

export const getIntegrations = (companyId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_INTEGRATIONS.REQUEST, requestId: companyId });
    return api.getIntegrations(companyId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_INTEGRATIONS.SUCCESS,
                payload: {
                    companyId,
                    pagination,
                    ...normalize.normalizeArray(data)
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_INTEGRATIONS.ERROR, requestId: companyId });
        });
};

export const getAllIntegrations = (params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_ALL_INTEGRATIONS.REQUEST });
    return api.getAllIntegrations(params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_ALL_INTEGRATIONS.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data || [])
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_ALL_INTEGRATIONS.ERROR });
        });
}

export const unblockIntegration = (companyId, integrationId) => dispatch => {
    dispatch({ type: actionTypes.UNBLOCK_INTEGRATION.REQUEST, requestId: integrationId });
    return api.unblockIntegration({ companyId, integrationId })
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.UNBLOCK_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UNBLOCK_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const blockIntegration = data => dispatch => {
    const { integrationId } = data;
    dispatch({ type: actionTypes.BLOCK_INTEGRATION.REQUEST, requestId: integrationId });
    return api.blockIntegration(data)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.BLOCK_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.BLOCK_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const unblockMarketSyncIntegration = (companyId, integrationId) => dispatch => {
    dispatch({ type: actionTypes.UNBLOCK_MARKET_SYNC_INTEGRATION.REQUEST, requestId: integrationId });
    return api.unblockMarketSyncIntegration({ companyId, integrationId })
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.UNBLOCK_MARKET_SYNC_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UNBLOCK_MARKET_SYNC_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const blockMarketSyncIntegration = data => dispatch => {
    const { integrationId } = data;
    dispatch({ type: actionTypes.BLOCK_MARKET_SYNC_INTEGRATION.REQUEST, requestId: integrationId });
    return api.blockMarketSyncIntegration(data)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.BLOCK_MARKET_SYNC_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.BLOCK_MARKET_SYNC_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const toggleExceededLimitBlockedIntegration = (companyId, integrationId) => dispatch => {
    dispatch({ type: actionTypes.TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION.REQUEST, requestId: integrationId });
    return api.toggleExceededLimitBlockedIntegration(companyId, integrationId)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const reloginIntegration = (companyId, integrationId) => dispatch => {
    dispatch({ type: actionTypes.RELOGIN_INTEGRATION.REQUEST, requestId: integrationId });
    return api.reloginIntegration(companyId, integrationId)
        .then(() => {
            Toast.success();
            dispatch({
                type: actionTypes.RELOGIN_INTEGRATION.SUCCESS,
                payload: {
                    integrationId
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.RELOGIN_INTEGRATION.ERROR, requestId: integrationId });
        });
};

export const updateIntegration = integration => dispatch => {
    dispatch({ type: actionTypes.UPDATE_INTEGRATION.REQUEST, requestId: integration.id });
    return api.updateIntegration(integration)
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.UPDATE_INTEGRATION.SUCCESS,
                payload: {
                    integration: response.data
                },
                requestId: integration.id
            });
        })
        .catch(error => {
            handleErrors(error);
            dispatch({ type: actionTypes.UPDATE_INTEGRATION.ERROR, requestId: integration.id });
        });
};

export const setPrioritySync = (integrationId, companyId) => dispatch => {
    dispatch({ type: actionTypes.SET_PRIORITY_SYNC.REQUEST, requestId: integrationId });
    return api.setPrioritySync(integrationId, companyId)
        .then(() => {
            Toast.success();
            dispatch({
                type: actionTypes.SET_PRIORITY_SYNC.SUCCESS,
                requestId: integrationId
            });
            dispatch(getIntegrations(companyId));
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.SET_PRIORITY_SYNC.ERROR, requestId: integrationId });
        });
};

export const migrateToAmazonSP = (integrationId, companyId) => dispatch => {
    dispatch({ type: actionTypes.MIGRATE_TO_AMAZON_SP.REQUEST, requestId: integrationId });
    return api.migrateToAmazonSP(integrationId, companyId)
        .then(() => {
            Toast.success();
            const newData = {
                marketplace: MARKETPLACES_ENUM.AmazonSP,
                isBlocked: true,
                needRelogin: true,
                shopRegion: 0,
                shopCountry: 0
            }
            dispatch({
                type: actionTypes.MIGRATE_TO_AMAZON_SP.SUCCESS,
                payload: {
                    integrationId,
                    newData
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.MIGRATE_TO_AMAZON_SP.ERROR, requestId: integrationId });
        });
};

export const migrateToNativeOutlook = (integrationId, companyId) => dispatch => {
    dispatch({ type: actionTypes.MIGRATE_TO_NATIVE_OUTLOOK.REQUEST, requestId: integrationId });
    return api.migrateToNativeOutlook(integrationId, companyId)
        .then(() => {
            Toast.success();
            const newData = {
                marketplace: MARKETPLACES_ENUM.Outlook,
                needRelogin: true,
                isBlocked: true
            }
            dispatch({
                type: actionTypes.MIGRATE_TO_NATIVE_OUTLOOK.SUCCESS,
                payload: {
                    integrationId,
                    newData
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.MIGRATE_TO_NATIVE_OUTLOOK.ERROR, requestId: integrationId });
        });
};

export const getIntegrationLogs = (companyId, integrationId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_INTEGRATION_LOGS.REQUEST, requestId: integrationId });
    return api.getIntegrationLogs(companyId, integrationId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_INTEGRATION_LOGS.SUCCESS,
                payload: {
                    integrationId,
                    pagination,
                    ...normalize.normalizeArray(data || [])
                },
                requestId: integrationId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_INTEGRATION_LOGS.ERROR, requestId: integrationId });
        });
};

export const getAllUsers = (params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_ALL_USERS.REQUEST });
    return api.getAllUsers(params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_ALL_USERS.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data)
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_ALL_USERS.ERROR });
        });
};

export const getUserNotifications = (userId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_USER_NOTIFICATIONS.REQUEST, requestId: userId });
    return api.getUserNotifications(userId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_USER_NOTIFICATIONS.SUCCESS,
                payload: {
                    userId,
                    pagination,
                    ...normalize.normalizeArray(data || [])
                },
                requestId: userId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_USER_NOTIFICATIONS.ERROR, requestId: userId });
        });
};

export const createNotificationForAll = (htmlBody, expiryDate) => dispatch => {
    dispatch({ type: actionTypes.CREATE_NOTIFICATION_FOR_ALL.REQUEST });
    return api.createNotificationForAll({ htmlBody, expiryDate })
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.CREATE_NOTIFICATION_FOR_ALL.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.CREATE_NOTIFICATION_FOR_ALL.ERROR });
        });
};

export const createNotificationForUsers = (htmlBody, expiryDate, userIds) => dispatch => {
    dispatch({ type: actionTypes.CREATE_NOTIFICATION_FOR_USERS.REQUEST });
    return api.createNotificationForUsers({ htmlBody, userIds, expiryDate })
        .then(response => {
            Toast.success();
            dispatch({
                type: actionTypes.CREATE_NOTIFICATION_FOR_USERS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.CREATE_NOTIFICATION_FOR_USERS.ERROR });
        });
};

export const getSentMessages = (companyId, year) => dispatch => {
    dispatch({ type: actionTypes.GET_SENT_MESSAGES.REQUEST, requestId: companyId });
    return api.getSentMessages(companyId, { yearOfDisplaying: year })
        .then(response => {
            dispatch({
                type: actionTypes.GET_SENT_MESSAGES.SUCCESS,
                payload: {
                    companyId,
                    data: response.data
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_SENT_MESSAGES.ERROR, requestId: companyId });
        });
};

export const downloadTicketListReport = (companyId, params) => dispatch => {
    dispatch({ type: actionTypes.DOWNLOAD_TICKETS_LIST_REPORT.REQUEST, requestId: companyId });
    return api.downloadTicketListReport(companyId, params)
        .then(response => {
            dispatch({ type: actionTypes.DOWNLOAD_TICKETS_LIST_REPORT.SUCCESS, requestId: companyId });
            downloadFile(response.data, `TicketList_${moment().utc()}.csv`)
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.DOWNLOAD_TICKETS_LIST_REPORT.ERROR, requestId: companyId });
        });
};

export const getSetupSettings = companyId => dispatch => {
    dispatch({ type: actionTypes.GET_SETUP_SETTINGS.REQUEST, requestId: companyId });
    return api.getSetupSettings(companyId)
        .then(responce => {
            dispatch({
                type: actionTypes.GET_SETUP_SETTINGS.SUCCESS,
                payload: {
                    companyId,
                    data: responce.data
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_SETUP_SETTINGS.ERROR, requestId: companyId });
        });
};


export const saveSetupSettings = (companyId, data) => dispatch => {
    dispatch({ type: actionTypes.UPDATE_SETUP_SETTING.REQUEST, requestId: companyId });
    return api.updateSetupSetting(companyId, data)
        .then(responce => {
            dispatch({
                type: actionTypes.UPDATE_SETUP_SETTING.SUCCESS,
                requestId: companyId
            });
            Toast.success();
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UPDATE_SETUP_SETTING.ERROR, requestId: companyId });
        });
};

export const setupManualSubsription = (companyId, data) => dispatch => {
    dispatch({ type: actionTypes.SETUP_MANUAL_SUBSCRIPTION.REQUEST, requestId: companyId });
    return api.setupManualSubsription(companyId, data)
        .then(response => {
            dispatch({
                type: actionTypes.SETUP_MANUAL_SUBSCRIPTION.SUCCESS,
                payload: response,
                requestId: companyId
            });
            Toast.success();
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.SETUP_MANUAL_SUBSCRIPTION.ERROR, requestId: companyId });
        });
}

export const removeSubsription = companyId => dispatch => {
    dispatch({ type: actionTypes.REMOVE_SUBSCRIPTION.REQUEST, requestId: companyId });
    return api.removeSubsription(companyId)
        .then(response => {
            dispatch({
                type: actionTypes.REMOVE_SUBSCRIPTION.SUCCESS,
                payload: response,
                requestId: companyId
            });
            Toast.success();
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.REMOVE_SUBSCRIPTION.ERROR, requestId: companyId });
        });
}

// nps
export const getPromoterScores = params => dispatch => {
    dispatch({ type: actionTypes.GET_PROMOTER_SCORES.REQUEST });
    return api.getPromoterScores(params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_PROMOTER_SCORES.SUCCESS,
                payload: {
                    pagination,
                    ...normalize.normalizeArray(data)
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_PROMOTER_SCORES.ERROR });
        });
};

export const getPromoterScoreStats = params => dispatch => {
    dispatch({ type: actionTypes.GET_PROMOTER_SCORE_STATS.REQUEST });
    return api.getPromoterScoreStats(params)
        .then(response => {
            dispatch({
                type: actionTypes.GET_PROMOTER_SCORE_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_PROMOTER_SCORES.ERROR });
        });
};

export const downloadNPSReport = params => dispatch => {
    dispatch({ type: actionTypes.DOWNLOAD_NPS_REPORT.REQUEST });
    return api.downloadNPSReport(params)
        .then(response => {
            dispatch({ type: actionTypes.DOWNLOAD_NPS_REPORT.SUCCESS });
            downloadFile(response.data, `NPS_Report_${moment().utc()}.csv`)
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.DOWNLOAD_NPS_REPORT.ERROR });
        });
};

export const getMissedNpsCount = () => dispatch => {
    dispatch({ type: actionTypes.GET_MISSED_NPS_COUNT.REQUEST });
    return api.getMissedNpsCount()
        .then(response => {
            dispatch({
                type: actionTypes.GET_MISSED_NPS_COUNT.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_MISSED_NPS_COUNT.ERROR });
        });
};

export const getNpsUserStats = userId => dispatch => {
    dispatch({ type: actionTypes.GET_NPS_USER_STATS.REQUEST, requestId: userId });
    return api.getNpsUserStats(userId)
        .then(response => {
            dispatch({
                type: actionTypes.GET_NPS_USER_STATS.SUCCESS,
                payload: {
                    userId,
                    userData: response.data
                },
                requestId: userId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_NPS_USER_STATS.ERROR });
        });
};

export const resetNextReviewDateForUser = userIds => dispatch => {
    dispatch({ type: actionTypes.RESET_NEXT_REVIEW_DATE_FOR_USERS.REQUEST });
    return api.resetNextReviewDateForUser(userIds)
        .then(() => {
            Toast.success();
            dispatch({ type: actionTypes.RESET_NEXT_REVIEW_DATE_FOR_USERS.SUCCESS });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.RESET_NEXT_REVIEW_DATE_FOR_USERS.ERROR });
        });
};

export const getAdminSettings = () => dispatch => {
    dispatch({ type: actionTypes.GET_ADMIN_SETTINGS.REQUEST });
    return api.getAdminSettings()
        .then(response => {
            dispatch({
                type: actionTypes.GET_ADMIN_SETTINGS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_ADMIN_SETTINGS.ERROR });
        });
};

export const saveRepeatSurvey = value => dispatch => {
    dispatch({ type: actionTypes.SAVE_REPEAT_SURVEY.REQUEST });
    return api.saveRepeatSurvey(value)
        .then(() => {
            dispatch({
                type: actionTypes.SAVE_REPEAT_SURVEY.SUCCESS,
                payload: { value }
            });
            Toast.success();
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.SAVE_REPEAT_SURVEY.ERROR });
        });
};

export const updateCompanyFeature = (companyId, featureValue) => dispatch => {
    dispatch({ type: actionTypes.UPDATE_COMPANY_FEATURE.REQUEST });
    return api.updateCompanyFeature(companyId, featureValue)
        .then(() => {
            dispatch({
                type: actionTypes.UPDATE_COMPANY_FEATURE.SUCCESS,
                payload: { companyId, featureValue }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.UPDATE_COMPANY_FEATURE.ERROR });
        });
};

export const getCompanyRemovedThreads = (companyId, params = {}) => dispatch => {
    dispatch({ type: actionTypes.GET_COMPANY_REMOVED_THREADS.REQUEST, requestId: companyId });
    return api.getCompanyRemovedThreads(companyId, params)
        .then(response => {
            const { data, ...pagination } = response.data;
            dispatch({
                type: actionTypes.GET_COMPANY_REMOVED_THREADS.SUCCESS,
                payload: {
                    companyId,
                    pagination,
                    ...normalize.normalizeArray(data || [])
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_COMPANY_REMOVED_THREADS.ERROR, requestId: companyId });
        });
};

export const getRemoveThreadLog = (companyId, threadId) => dispatch => {
    dispatch({ type: actionTypes.GET_REMOVE_THREAD_LOG.REQUEST, requestId: threadId });
    return api.getRemoveThreadLog(companyId, threadId)
        .then(response => {
            dispatch({
                type: actionTypes.GET_REMOVE_THREAD_LOG.SUCCESS,
                payload: {
                    companyId,
                    threadId,
                    removeLog: response.data
                },
                requestId: threadId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_REMOVE_THREAD_LOG.ERROR, requestId: threadId });
        });
};

export const restoreThread = (companyId, threadId) => dispatch => {
    dispatch({ type: actionTypes.RESTORE_THREAD.REQUEST, requestId: threadId });
    return api.restoreThread(companyId, threadId)
        .then(() => {
            dispatch({
                type: actionTypes.RESTORE_THREAD.SUCCESS,
                payload: {
                    companyId,
                    threadId
                },
                requestId: threadId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.RESTORE_THREAD.ERROR, requestId: threadId });
        });
};

export const importThreadsFromJson = (companyId, base64) => dispatch => {
    dispatch({ type: actionTypes.IMPORT_TREADS_FROM_JSON.REQUEST, requestId: companyId });
    return api.importThreadsFromJson(companyId, { base64 })
        .then(response => {
            dispatch({
                type: actionTypes.IMPORT_TREADS_FROM_JSON.SUCCESS,
                payload: {
                    companyId,
                    errors: response.data.errors,
                    badThreads: response.data.badThreads,
                    importedSuccessfullyCount: response.data.importedSuccessfullyCount,
                    totalCount: response.data.totalCount
                },
                requestId: companyId
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.IMPORT_TREADS_FROM_JSON.ERROR, requestId: companyId });
        });
};

export const getSubscriptionPlans = () => dispatch => {
    dispatch({ type: actionTypes.GET_SUBSCRIPTION_PLANS.REQUEST });
    return api.getSubscriptionPlans()
        .then(response => {
            dispatch({
                type: actionTypes.GET_SUBSCRIPTION_PLANS.SUCCESS,
                payload: {
                    ...normalize.normalizeArray(response.data || [])
                }
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.GET_SUBSCRIPTION_PLANS.ERROR });
        });
};