import _ from 'lodash';
import Toast from './Toast';
import { ERROR_TYPES } from '../constants';

const handleErrors = info => {
    if (info.message === ERROR_TYPES.REFRESH_TOKEN ||
        info.message === ERROR_TYPES._404) return;
    const errorMessage = _.get(info, 'response.data.shortMessage') || _.get(info, 'response.data');
    if (_.isString(errorMessage)) {
        let croppedMessage = errorMessage;
        if (_.includes(errorMessage, '--->')) {
            croppedMessage = errorMessage.slice(errorMessage.lastIndexOf('--->') + 4, errorMessage.length).replaceAll("(See inner exception for details.)", "");
        }
        Toast.error({ message: croppedMessage });
    } else {
        Toast.error();
    }
};

export default handleErrors;