import React from "react";
import { Chart as GoogleChart } from "react-google-charts";
import PropTypes from "prop-types";
import { fonts, colors, css } from '../../../../styles';
import Spinner from "../Spinner";
import translate from "../../services/translate";

const Chart = ({ chartType, data, options, titleComponent, chartClassName, isLoading, i18n }) => {
    const chartOptions = {
        animation: {
            duration: 1000,
            easing: "in",
            startup: true
        },
        backgroundColor: colors.white,
        tooltip: {
            textStyle: {
                color: colors.black,
                fontSize: fonts.xsNormal.fontSize
            }
        },
        ...options
    };

    return data.length > 1 ? (
        <>
            {titleComponent}
            {isLoading ? <Spinner /> : (
                <div className={css(chartClassName)}>
                    <GoogleChart
                        chartType={chartType}
                        options={chartOptions}
                        data={data}
                        width="100%"
                        height="100%"
                    />
                </div>
            )}
        </>
    ) : i18n.t('noData');
};

export default translate()(Chart);

Chart.propTypes = {
    chartType: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    titleComponent: PropTypes.element.isRequired,
    chartClassName: PropTypes.object.isRequired,
    isLoading: PropTypes.bool
};

Chart.defaultProps = {
    isLoading: false
};