import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
    components as Core,
    constants as coreConstants,
    getPagination,
    ICONS,
    translate
} from "../../../core";
import { valueBuilder } from "../../services";
import { fonts, css, StyleSheet, spacing, colors } from "../../../../styles";
import MessageModal from "./MessageModal";
import FeedbackTitle from "./FeedbackTitle";
import { Button, Icon } from "../../../core/components";
import * as selectors from "../../selectors";
import * as actions from "../../actions";
import Rating from "./Rating";


const COLUMNS = {
    companyName: 'companyName',
    userEmail: 'userEmail',
    createdDate: 'createdDate',
    rating: 'rating',
    comment: 'comment',
    promoterType: 'promoterType'
};

class PromoterScoresTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onChangeTable: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        scores: PropTypes.array,
        isLoading: PropTypes.bool,
        isLoadingReport: PropTypes.bool,
        pagination: PropTypes.object.isRequired,
    };

    static defaultProps = {
        scores: [],
        isLoading: false,
        isLoadingReport: false,
    };

    state = {
        currentUser: undefined,
    };

    get tableColumns() {
        const { i18n } = this.props;
        return [{
            title: i18n.t("feedback.table.companyName"),
            key: COLUMNS.companyName,
            dataIndex: COLUMNS.companyName
        }, {
            title: i18n.t("feedback.table.email"),
            key: COLUMNS.userEmail,
            dataIndex: COLUMNS.userEmail
        }, {
            title: i18n.t("feedback.table.date"),
            key: COLUMNS.createdDate,
            dataIndex: COLUMNS.createdDate,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("feedback.table.comment"),
            key: COLUMNS.comment,
            dataIndex: COLUMNS.comment,
            align: 'center',
            render: this.renderComment
        }, {
            title: i18n.t("feedback.table.rating"),
            key: COLUMNS.rating,
            dataIndex: COLUMNS.rating,
            render: this.renderRating,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }];
    }

    get pagination() {
        return getPagination(this.props.pagination);
    }

    get footerButton() {
        const { i18n } = this.props;
        return (
            <>
                <Icon className={css(styles.footerIcon)} color={colors.white} type={ICONS.download} />
                {i18n.t('export')}
            </>
        );
    }

    renderRating = rating => (<Rating rating={rating} />)


    renderComment = (comment = '') => (
        <Icon
            color={comment && comment.length ? colors.black : colors.grey}
            type={comment && comment.length ? ICONS.check : ICONS.close}
        />
    );

    onShowMessage = currentUser => {
        if (!this.state.currentUser) {
            this.setState({ currentUser });
        }
        this.props.actions.getNpsUserStats(currentUser.userId)
    };

    onClose = () => {
        this.setState({ currentUser: null });
    };

    footer = () => {
        const { isLoadingReport } = this.props;
        return (
            <div className={css(styles.footer)}>
                <Button
                    isDisabled={isLoadingReport}
                    isLoading={isLoadingReport}
                    onPress={this.props.onDownload}
                    label={this.footerButton}
                />
            </div>
        )
    };

    render() {
        const { isLoading, scores, i18n } = this.props;
        const { currentUser } = this.state;
        return (
            <div>
                <FeedbackTitle title={i18n.t('feedback.latestReview')} />
                <Core.Table
                    columns={this.tableColumns}
                    data={scores}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.props.onChangeTable}
                    onPressRow={this.onShowMessage}
                    footer={this.footer}
                    className={css(styles.table)}
                />
                {!_.isNil(currentUser) &&(
                    <MessageModal onClose={this.onClose} currentUser={this.state.currentUser}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    scores: selectors.getPromoterScores(state),
    pagination: selectors.getPromoterScoresPagination(state),
    isLoading: selectors.isLoadingPromoterScores(state),
    isLoadingReport: selectors.isDownloadingNPSReport(state),
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PromoterScoresTable));

const styles = StyleSheet.create({
    passive: {
        color: colors.yellow
    },
    detractor: {
        color: colors.red
    },
    promoter: {
        color: colors.green
    },
    table: {
        '& .ant-table-footer': {
            position: 'initial',
            border: 'none',
            padding: 0
        }
    },
    footer: {
        position: 'absolute',
        margin: `${spacing.s3}px 0`
    },
    footerIcon: {
        marginRight: spacing.s0
    },
    message: {
        ...fonts.mdBold,
        marginTop: spacing.s2
    },
    companyName: {
        ...fonts.smBold
    },

});