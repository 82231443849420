import React from 'react';
import PropTypes from 'prop-types';
import { Spin as AntdSpinner } from 'antd';
import { css } from "../../../../styles";

const SPINNER_SIZES = {
    large: 'large',
    default: 'default'
};

class Spinner extends React.PureComponent {
    static propTypes = {
        isBig: PropTypes.bool,
        className: PropTypes.object
    };

    static defaultProps = {
        isBig: false,
        className: null
    };

    get size() {
        return this.props.isBig ? SPINNER_SIZES.large : SPINNER_SIZES.default;
    }

    render() {
        const { className } = this.props;
        return (
            <AntdSpinner className={css(className)} size={this.size} />
        );
    }
}

export default Spinner;