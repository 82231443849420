import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';
import _ from 'lodash';
import { colors, spacing, StyleSheet, css, fonts } from "../../../../styles";
import { translate } from "../../services";

class Select extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array.isRequired,
        mainClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        className: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        keyProp: PropTypes.string,
        valueProp: PropTypes.string,
        labelProp: PropTypes.string,
        hasMargins: PropTypes.bool,
        dropdownClassName: PropTypes.object,
        label: PropTypes.string,
        labelCustomRender: PropTypes.func,
        hasRoundBorder: PropTypes.bool
    };

    static defaultProps = {
        mainClassName: undefined,
        className: undefined,
        keyProp: 'id',
        valueProp: 'id',
        labelProp: 'name',
        hasMargins: false,
        dropdownClassName: undefined,
        label: undefined,
        labelCustomRender: null,
        hasRoundBorder: false
    };

    renderItem = item => {
        const { keyProp, valueProp, labelProp, labelCustomRender } = this.props;
        return (
            <AntdSelect.Option key={item[keyProp]} value={item[valueProp]}>
                {labelCustomRender ? labelCustomRender(item) : item[labelProp]}
            </AntdSelect.Option>
        );
    };

    render() {
        const { data, className, hasMargins, dropdownClassName, mainClassName, label, hasRoundBorder, ...props } = this.props;
        return (
            <div className={css([
                styles.container, hasMargins ? styles.marginContainer : undefined,
                hasRoundBorder ? styles.roundBorder : undefined,
                mainClassName]
            )}>
                {label ?
                    <p className={css(styles.label)}>{label}</p> : null
                }
                <AntdSelect
                    {...props}
                    className={css(className)}
                    dropdownClassName={css([styles.dropdown, dropdownClassName])}>
                    {_.map(data, this.renderItem)}
                </AntdSelect>
            </div>
        );
    }
}

export default (translate()(Select));

const styles = StyleSheet.create({
    container: {
        '& .ant-select': {
            width: '100%',
            ...fonts.smNormal
        },
        '& .ant-select-selection, & .ant-select-selection:hover, & .ant-select-selection:active, & .ant-select-selection:focus': {
            minHeight: `${spacing.s8}px !important`,
            height: `${spacing.s8}px !important`,
            backgroundColor: colors.backgroundLight,
            borderColor: colors.greyLight,
            boxShadow: 'none',
            ':hover': {
                border: `1px solid ${colors.greyOpacity}`
            },
            ':focus': {
                outline: '0 !important',
                borderColor: colors.greyOpacity,
                boxShadow: 'none !important',
                color: colors.black
            }
        },
        '& .ant-select-selection': {
            paddingTop: spacing.s0,
            paddingBottom: spacing.s0,
            height: 'auto !important',
            border: `1px solid ${colors.greyLight}`,
            backgroundColor: colors.white
        },
        '& .ant-select-selection__placeholder': {
            color: colors.greyOpacity
        },
        '& .ant-select-selection__rendered': {
            marginLeft: spacing.s3,
            color: colors.text
        },
        '& .ant-select-selection-selected-value': {
            ...fonts.smNormal,
            paddingTop: 7,
            paddingBottom: 7,
            color: `${colors.blackOpacity} !important`,
            lineHeight: `${spacing.s3}px`
        },
        '& .ant-select-arrow-icon': {
            color: colors.blackOpacity
        },
        '& .ant-select-selection--multiple .ant-select-selection__choice__content': {
            ...fonts.xsNormal,
            paddingTop: 4,
            color: colors.primary
        },
        '& .ant-select-selection--multiple .ant-select-selection__choice': {
            backgroundColor: `${colors.transparent} !important`,
            marginBottom: 2
        },
        '& .ant-select-selection--multiple .ant-select-search--inline': {
            marginBottom: 0
        },
        '& .ant-select-selection__clear': {
            background: colors.white,
            borderRadius: `${spacing.s2}px`,
            color: colors.grey
        },
        '& .ant-select-selection__choice__remove': {
            color: colors.greyLight
        },
        '& .ant-empty': {
            color: colors.text
        },
        '& .ant-select-selection__choice': {
            borderRadius: `${spacing.s2}px !important`,
            borderColor: `${colors.primary} !important`,
            backgroundColor: `${colors.white} !important`,
            color: `${colors.primary} !important`
        }
    },
    roundBorder: {
        '& .ant-select-selection': {
            borderRadius: `${spacing.s9}px !important`
        }
    },
    marginContainer: {
        marginBottom: spacing.s3
    },
    dropdown: {
        backgroundColor: colors.white,
        '& .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)': {
            backgroundColor: colors.transparent
        },
        '& .ant-select-dropdown-menu-item': {
            ...fonts.xsNormal,
            paddingTop: spacing.s2,
            paddingBottom: spacing.s2,
            paddingLeft: spacing.s4,
            paddingRight: spacing.s7,
            color: `${colors.blackOpacity} !important`,
            ':hover': {
                backgroundColor: colors.primaryBackground
            }
        },
        '& .ant-select-dropdown-menu-item-selected': {
            ...fonts.xsNormal,
            backgroundColor: colors.primaryBackground
        }
    },
    label: {
        ...fonts.smNormal,
        color: colors.black,
        marginBottom: spacing.s1
    }
});