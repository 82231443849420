import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AntdInputNumber } from 'antd';
import { StyleSheet, css, spacing, fonts, colors } from '../../../../styles';

class LabeledInputNumber extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.number.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.object
    };

    static defaultProps = {
        min: undefined,
        max: undefined,
        label: undefined,
        className: undefined
    };

    onChange = value => {
        this.props.onChange(value);
    };

    render() {
        const { value, min, max, label, className } = this.props;
        return (
            <div className={css(styles.container, className)}>
                <span className={css(styles.label)}>{label}</span>
                <AntdInputNumber
                    value={value}
                    min={min}
                    max={max}
                    onChange={this.onChange}
                    className={css(styles.input)}
                />
            </div>
        );
    }
}

export default LabeledInputNumber;

const styles = StyleSheet.create({
    container: {
        marginBottom: spacing.s2,
        width: '100%',
        display: 'flex'
    },
    label: {
        ...fonts.smNormal,
        lineHeight: `${fonts.smNormal.fontSize}px`,
        color: colors.inputAddonText,
        backgroundColor: colors.inputAddon,
        borderBottomLeftRadius: spacing.s0,
        borderTopLeftRadius: spacing.s0,
        border: `1px solid ${colors.border}`,
        padding: '8px 11px',
        whiteSpace: 'nowrap'
    },
    input: {
        width: '100%',
        borderColor: `${colors.border} !important`,
        '& .ant-input-number-handler-wrap': {
            borderLeftColor: `${colors.border} !important`
        },
        '& .ant-input-number-handler-down': {
            borderTopColor: `${colors.border} !important`
        }
    }
});