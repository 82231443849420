import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import Popover from '../Popover';
import { colors, StyleSheet, css, spacing, common } from '../../../../styles';

const componentColors = [
    'rgb(57, 168, 224)',
    'rgb(251, 82, 82)',
    'rgb(252, 161, 32)',
    'rgb(74, 210, 149)',
    'rgb(18, 115, 235)',
    'rgb(128, 128, 241)',
    'rgb(234, 6, 177)',
    'rgb(255, 121, 63)'
];

const ColorPicker = ({ color, onChange, presetColors }) => {
    const [isVisible, setVisibility] = useState(false);

    return (
        <Popover
            content={
                <div className={css(styles.picker)}>
                    <SketchPicker
                        color={color}
                        onChangeComplete={onChange}
                        presetColors={presetColors || componentColors}
                    />
                </div>
            }
            visible={isVisible}
            onVisibleChange={() => setVisibility(!isVisible)}
            placement="bottom">
            <div
                className={css(common.pointer, styles.input)}
                onClick={() => setVisibility(!isVisible)}
                style={{ backgroundColor: color }}
            />
        </Popover>
    );
};

ColorPicker.propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    presetColors: PropTypes.array
};

ColorPicker.defaultProps = {
    presetColors: null
};

export default ColorPicker;

const styles = StyleSheet.create({
    input: {
        width: spacing.s14 * 2,
        height: spacing.s9,
        border: `2px solid ${colors.background}`,
        borderRadius: spacing.s1
    },
    picker: {
        height: 200,
        transition: 'all .5s'
    }
});