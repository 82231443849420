import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { colors, spacing, css, StyleSheet } from "../../../../styles";
import { Tooltip } from "../../../core/components";
import { useTranslation } from "../../../core";

const ADMIN_PERMISSION_VALUE = 16;

const UserFullNameCell = ({ user }) => {
    const { i18n } = useTranslation()
    return (
        <span className={css(styles.userFullNameCell)}>
            {user.fullName}
            {user.permissions && !!_.find(user.permissions, p => p.value === ADMIN_PERMISSION_VALUE) ?
                <Tooltip title={i18n.t('users.admin')}>
                    <p className={css(styles.adminIcon)}>A</p>
                </Tooltip> : null
            }
        </span>
    );
}

UserFullNameCell.propTypes = {
    user: PropTypes.object.isRequired
};

export default UserFullNameCell;

const styles = StyleSheet.create({
    userFullNameCell: {
        display: 'flex'
    },
    adminIcon: {
        marginLeft: spacing.s1,
        color: colors.red
    }
})