import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';
import { StyleSheet, css, spacing, colors, fonts } from '../../../../styles';

const MODAL_WIDTH = 520;

class Modal extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        title: PropTypes.string,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        modalWidth: PropTypes.number
    };

    static defaultProps = {
        children: null,
        title: undefined,
        isVisible: false,
        modalWidth: MODAL_WIDTH
    };

    render() {
        const { children, isVisible, title, onClose, modalWidth, ...props } = this.props;
        return (
            <AntdModal
                visible={isVisible}
                maskClosable={false}
                wrapClassName={css(styles.modalContainer)}
                width={modalWidth}
                title={title}
                onCancel={onClose}
                footer={null}
                {...props}>
                {children}
            </AntdModal>
        );
    }
}

export default Modal;

const styles = StyleSheet.create({
    modalContainer: {
        '.ant-modal': {
            backgroundColor: colors.white,
        },
        '& .ant-modal': {
            '@media (max-width: 767px)': {
                width: `${MODAL_WIDTH}px !important`
            },
            '@media (max-width: 540px)': {
                width: '100% !important'
            },
        },
        '& .ant-modal-header': {
            padding: spacing.s3
        },
        '& .ant-modal-title': {
            ...fonts.lgNormal,
            margin: spacing.s4
        },
        '& .ant-modal-body': {
            paddingTop: spacing.s5,
            paddingBottom: spacing.s5,
            paddingLeft: spacing.s7,
            paddingRight: spacing.s7,
            '& .ant-btn-primary[disabled]': {
                backgroundColor: colors.background
            }
        }
    }
});