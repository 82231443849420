export const NAME = 'dashboard';

export const CompanySubscriptionPeriodTypes = {
    monthly: 0,
    yearly: 1,
    all: 2
}

export const SubscriptonUsageTypes = {
    all: 0,
    usageBased: 1,
    unlimited: 2
};