import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { images } from '../../../../assets';
import { StyleSheet, css, spacing } from '../../../../styles';
import { ROUTES } from '../../constants';

class Logo extends React.PureComponent {
    static propTypes = {
        hasRevertedColors: PropTypes.bool
    };

    static defaultProps = {
        hasRevertedColors: false
    };

    render() {
        const { hasRevertedColors } = this.props;
        return (
            <Link to={ROUTES.home()}>
                <img
                    src={hasRevertedColors ? images.LogoWhiteSvg : images.LogoBlueSvg}
                    className={css(styles.logo)}
                />
            </Link>
        );
    }
}

export default Logo;

const styles = StyleSheet.create({
    logo: {
        maxHeight: spacing.s9
    }
});