import moment from 'moment';
import { actionTypes as coreActionTypes } from '../core';
import * as actionTypes from './actionTypes';

const initialState = {
    filters: {
        region: null,
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    },
    overview: {
        current: {
            registrations: 0,
            registrationsUk: 0,
            registrationsDE: 0,
            conversions: 0,
            churnedUsers: 0,
            payingUsers: 0
        },
        previous: {
            registrations: 0,
            registrationsUk: 0,
            registrationsDE: 0,
            conversions: 0,
            churnedUsers: 0,
            payingUsers: 0
        }
    },
    registrations: {
        items: {},
        ids: [],
        pagination: {},
        chartData: []
    },
    usageReport: {
        items: {},
        ids: [],
        pagination: {},
    },
    subscriptionCancellations: {
        stats: {},
        items: {},
        ids: [],
        pagination: {},
        chartData: []
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CAHNGE_FILTERS_DATE_RANGE: {
            const { startDate, endDate } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    startDate,
                    endDate
                }
            };
        }
        case actionTypes.CHANGE_REGION_FILTER: {
            const { region } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    region
                }
            };
        }
        case actionTypes.GET_OVERVIEW_STATS.SUCCESS: {
            return {
                ...state,
                overview: action.payload
            };
        }
        case actionTypes.GET_NEW_COMPANIES.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                registrations: {
                    ...state.registrations,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.GET_NEW_COMPANIES_CHART_DATA.SUCCESS: {
            return {
                ...state,
                registrations: {
                    ...state.registrations,
                    chartData: action.payload
                }
            };
        }
        case actionTypes.GET_USAGE_REPORT_DATA.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                usageReport: {
                    ...state.registrations,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.GET_CANCELLATION_CHART_DATA.SUCCESS: {
            return {
                ...state,
                subscriptionCancellations: {
                    ...state.subscriptionCancellations,
                    chartData: action.payload
                }
            };
        }
        case actionTypes.GET_CANCELLATIONS.SUCCESS: {
            const { items, ids, pagination } = action.payload;
            return {
                ...state,
                subscriptionCancellations: {
                    ...state.subscriptionCancellations,
                    items,
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.GET_CANCELLATIONS_OVERVIEW.SUCCESS: {
            return {
                ...state,
                subscriptionCancellations: {
                    ...state.subscriptionCancellations,
                    stats: action.payload
                }
            };
        }
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};