import * as actionTypes from './actionTypes';
import * as api from './api';
import { actionTypes as coreActionTypes, Toast } from '../core';
import * as selectors from './selectors';

export const signIn = (email, password) => dispatch => {
    dispatch({ type: actionTypes.SIGN_IN.REQUEST });
    return api.signIn({ email, password })
        .then(response => {
            dispatch({
                type: actionTypes.SIGN_IN.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionTypes.SIGN_IN.ERROR });
        });
};

export const refreshToken = () => (dispatch, getState) => {
    const refreshToken = selectors.getRefreshToken(getState());
    dispatch({ type: actionTypes.REFRESH_TOKEN.REQUEST });
    return api.refreshToken({ refreshToken })
        .then(response => {
            dispatch({
                type: actionTypes.REFRESH_TOKEN.SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({ type: actionTypes.REFRESH_TOKEN.ERROR });
            dispatch(signOut());
        });
};

export const signOut = () => dispatch => {
    dispatch({ type: actionTypes.SIGN_OUT });
    dispatch({ type: coreActionTypes.RESET });
};