import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { colors, css, spacing, StyleSheet } from '../../../../styles';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ startDate, endDate, onChange, hasMargin, className, ...props }) => {
    const onDataChange = (date, dateString) => {
        if (dateString && dateString.length === 2) {
            onChange(date[0], date[1]);
        }
    };

    return (
        <>
            <RangePicker
                {...props}
                onChange={onDataChange}
                value={[startDate, endDate]}
                className={css(styles.dataPicker, hasMargin ? styles.margin : undefined, className)}
                separator="—"
                dropdownClassName={css(styles.dropdown)}
            />
        </>
    );
};

DateRangePicker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    hasMargin: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

DateRangePicker.defaultProps = {
    startDate: null,
    endDate: null,
    hasMargin: true,
    className: undefined
};

export default DateRangePicker;

const styles = StyleSheet.create({
    dataPicker: {
        '& .ant-calendar-picker-input': {
            backgroundColor: colors.transparent,
            height: `${spacing.s9}px !important`,
            display: 'flex',
            alignItems: 'baseline',
            color: colors.text,
            '& .ant-calendar-range-picker-separator, .ant-calendar-picker-icon': {
                color: colors.greyLight
            }
        }
    },
    dropdown: {
        color: colors.text,
        '& .ant-calendar-range': {
            backgroundColor: colors.white,
            borderColor: colors.greyLight
        },
        '& .ant-calendar-input-wrap, .ant-calendar-body': {
            borderColor: colors.greyLight
        },
        '& .ant-calendar-input, .ant-calendar-range-middle, .ant-calendar-month-select, .ant-calendar-year-select, .ant-calendar-cell:not(.ant-calendar-last-month-cell):not(.ant-calendar-next-month-btn-day):not(.ant-calendar-in-range-cell):not(.ant-calendar-selected-day) .ant-calendar-date': {
            color: colors.text,
            backgroundColor: colors.transparent
        }
    },
    margin: {
        marginBottom: `${spacing.s3}px !important`
    }
});