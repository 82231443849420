import { useCallback } from 'react'
import { chartColors, colors, fonts } from '../../../styles';
import { shuffleArray } from '../utils';

export const useChartOptions = () => {
    const getLineChart = useCallback(() => ({
        chartArea: {
            top: '8%',
            left: '8%',
            width: '85%',
            height: '70%'
        },
        curveType: "function",
        hAxis: {
            textStyle: {
                color: colors.grey,
                fontSize: fonts.xxsNormal.fontSize
            }
        },
        vAxis: {
            minValue: 0,
            textStyle: {
                color: colors.black,
                fontSize: fonts.xxsNormal.fontSize
            },
            gridlines: {
                color: colors.greyLight
            }
        },
        colors: [...new Set(shuffleArray(chartColors))],
        legend: {
            position: 'bottom',
            textStyle: {
                color: colors.black
            }
        },
        pointSize: 4,
        backgroundColor: colors.white,
        tooltip: {
            textStyle: {
                color: colors.black,
                fontSize: fonts.xsNormal.fontSize
            }
        }
    }), []);

    return {
        getLineChart
    };
};
