import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Resizer from 'react-image-file-resizer';
import FileInput from '../FileInput';
import ImageCropperModal from '../ImageCropperModal';
import InlineButton from '../InlineButton';
import PopConfirm from '../PopConfirm';
import { useTranslation, fileToBase64 } from "../../services";
import { css, StyleSheet, common } from '../../../../styles';

const ImageUpload = ({ imageUrl, onDelete, onChange, isLoading, smallPreview, width, height }) => {
    const { t } = useTranslation();
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const onChangePhoto = file => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            'PNG',
            100,
            0,
            file => {
                onChange(file, selectedPhoto.fileName);
                setSelectedPhoto(null);
            }
        );
    };

    const onShowPhotoModal = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const iconfile = await fileToBase64(e.target.files[0]);
            const fileName = e.target.files[0].name;
            setSelectedPhoto({
                file: iconfile,
                fileName
            });
        }
    };

    return (
        <>
            <div className={css(common.flexRow)}>
                {imageUrl ?
                    <div className={css(styles.changeIcon)}>
                        <img
                            src={imageUrl}
                            className={css(smallPreview ? styles.smallImage : styles.image)}
                        />
                        <PopConfirm
                            title={t('areYouSure')}
                            onConfirm={onDelete}>
                            <span>
                                <InlineButton
                                    label={t('deletePicture')}
                                />
                            </span>
                        </PopConfirm>
                    </div> : null
                }
                <FileInput
                    onChange={onShowPhotoModal}
                    isLoading={isLoading}
                />
            </div>
            {selectedPhoto ?
                <ImageCropperModal
                    source={selectedPhoto.file}
                    onClose={() => setSelectedPhoto(null)}
                    onConfirm={onChangePhoto}
                /> : null
            }
        </>
    );
};


export default ImageUpload;

ImageUpload.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    imageUrl: PropTypes.string,
    smallPreview: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

ImageUpload.defaultProps = {
    imageUrl: undefined,
    isLoading: false,
    smallPreview: false,
    width: 128,
    height: 128
};

const styles = StyleSheet.create({
    changeIcon: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    image: {
        maxHeight: '150px'
    },
    smallImage: {
        maxHeight: '50px'
    }
});
