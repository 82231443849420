import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from "antd";
import { components as Core, PERIODS } from '../../../core';
import { changeFiltersDateRange, changeRegionFilter, getRegistrations, getRegistrationsChartData, getOverview } from '../../actions';
import { StyleSheet, css, fonts, spacing, colors } from '../../../../styles';
import { Overview } from './boards';
import { RegistrationsBoard } from './boards/RegistrationsBoard';

export const Dashboard = () => {
    const dispatch = useDispatch();

    const loadData = () => {
        dispatch(getOverview());
        dispatch(getRegistrations());
        dispatch(getRegistrationsChartData());
    }

    const onDateFilterChange = (startDate, endDate) => {
        dispatch(changeFiltersDateRange(startDate, endDate));
        loadData();
    };

    const onRegionFilterChange = value => {
        dispatch(changeRegionFilter(value));
        loadData();
    }

    return (
        <>
            <Row type="flex" justify="end" gutter={spacing.s3} className={css(styles.top)}>
                <Col span={5}>
                    <Core.RegionFilter
                        onChange={onRegionFilterChange}
                    />
                </Col>
                <Col span={5}>
                    <Core.DateFilter
                        onChange={onDateFilterChange}
                        defaultPeriod={PERIODS.last7Days}
                    />
                </Col>
            </Row>
            <Overview />
            <RegistrationsBoard />
        </>
    );
};

const styles = StyleSheet.create({
    top: {
        margin: `${spacing.s3}px 0`,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: spacing.s1
    },
    title: {
        ...fonts.xlNormal,
        color: colors.blackTransparent,
    },
    leftTop: {
        flex: 'none',
        marginRight: spacing.s2,
        flexWrap: 'nowrap'
    },
    rightTop: {
        flex: 2
    },
    repeatSurveyCol: {
        marginLeft: 'auto'
    },
    mainContainer: {
        flexWrap: 'nowrap',
        alignItems: 'stretch'
    },
    searchInput: {
        marginBottom: spacing.s1,
    }
});