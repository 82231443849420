import React from "react";
import PropTypes from 'prop-types';
import { StyleSheet, css, colors, spacing, fonts } from "../../../../../styles";
import { components as Core, ICONS } from '../../../../core';

const calculatePercent = (value1, value2) => {
    if (!value2) return 100;
    return Math.round((((value1/value2) * 100) + Number.EPSILON) * 100) / 100;
};

export const Percentage = React.memo(({ value1, value2, isReverse }) => {
    const getIncreaseColor = () => isReverse ? 'red' : 'green';
    const getDecreaseColor = () => isReverse ? 'green' : 'red';
    const value = calculatePercent(value1, value2);
    let color = 'grey';
    if (value >= 100) color = getIncreaseColor();
    else if (value > 0) color = getDecreaseColor();


    return (
        <p className={css(styles[`${color}Count`])}>
            {value > 0 ? (
                <Core.Icon
                    icon={value >= 100 ? ICONS.arrowUp : ICONS.arrowDown}
                    color={colors[color]}
                    size={spacing.s2}
                />
            ) : null}
            {`${value}%`}
        </p>
    );
});

Percentage.propTypes = {
    value1: PropTypes.number.isRequired,
    value2: PropTypes.number.isRequired,
    isReverse: PropTypes.bool
};

Percentage.defaultProps = {
    isReverse: false
};

export default Percentage;

const styles = StyleSheet.create({
    greyCount: {
        ...fonts.xsBold,
        color: colors.grey
    },
    greenCount: {
        ...fonts.xsBold,
        color: colors.green
    },
    redCount: {
        ...fonts.xsBold,
        color: colors.red
    }
});
