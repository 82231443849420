import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { components as Core, translate } from '../../../core';
import AccountSettingsTable from './AccountSettingsTable';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

const COLLAPSE_ITEMS = {
    labels: 'labels',
    rules: 'rules',
    templates: 'templates',
    autoResponses: 'autoResponses',
    customFields: 'customFields'
};

class AccountSetupModal extends React.PureComponent {
    static propTypes = {
        companyId: PropTypes.string.isRequired,
        labels: PropTypes.array,
        rules: PropTypes.array,
        templates: PropTypes.array,
        autoResponses: PropTypes.array,
        customFields: PropTypes.array,
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        isLoadingSetupSettings: PropTypes.bool,
        isLoadingSaveSettings: PropTypes.bool
    }

    static defaultProps = {
        labels: [],
        rules: [],
        templates: [],
        autoResponses: [],
        customFields: [],
        isLoadingSetupSettings: false,
        isLoadingSaveSettings: false
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedLabels: [],
            selectedRules: [],
            selectedTemplates: [],
            selectedAutoResponses: [],
            selectedCustomFields: []
        }

        this.props.actions.getSetupSettings(this.props.companyId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoadingSaveSettings && !this.props.isLoadingSaveSettings) {
            this.props.onClose();
        }
    }

    get collapseData() {
        const { i18n } = this.props;
        return [{
            key: COLLAPSE_ITEMS.labels,
            title: i18n.t('setupSettings.labels'),
            content: this.renderLabelsTable
        }, {
            key: COLLAPSE_ITEMS.rules,
            title: i18n.t('setupSettings.rules'),
            content: this.renderRulesTable
        }, {
            key: COLLAPSE_ITEMS.templates,
            title: i18n.t('setupSettings.templates'),
            content: this.renderTemplatesTable
        }, {
            key: COLLAPSE_ITEMS.autoResponses,
            title: i18n.t('setupSettings.autoResponses'),
            content: this.renderAutoResponsesTable
        }, {
            key: COLLAPSE_ITEMS.customFields,
            title: i18n.t('setupSettings.customFields'),
            content: this.renderCustomFieldsTable
        }];
    }

    get renderFooter() {
        const { i18n, isLoadingSaveSettings } = this.props;
        return (
            <div>
                <Core.Button
                    label={i18n.t('setupSettings.cancel')}
                    onPress={this.props.onClose}
                />
                <Core.Button
                    label={i18n.t('setupSettings.save')}
                    onPress={this.onSaveSetupSettings}
                    isLoading={isLoadingSaveSettings}
                />
            </div>
        );
    }

    get renderLabelsTable() {
        const { isLoadingSetupSettings, labels } = this.props;

        return (
            <AccountSettingsTable
                isLoading={isLoadingSetupSettings}
                onChange={this.onChangeLabels}
                selected={this.state.selectedLabels}
                data={labels} />
        );
    }

    get renderRulesTable() {
        const { isLoadingSetupSettings, rules } = this.props;
        return (
            <AccountSettingsTable
                isLoading={isLoadingSetupSettings}
                onChange={this.onChangeRules}
                selected={this.state.selectedRules}
                data={rules} />
        );
    }

    get renderTemplatesTable() {
        const { isLoadingSetupSettings, templates } = this.props;
        return (
            <AccountSettingsTable
                isLoading={isLoadingSetupSettings}
                onChange={this.onChangeTemplates}
                selected={this.state.selectedTemplates}
                data={templates} />
        );
    }

    get renderAutoResponsesTable() {
        const { isLoadingSetupSettings, autoResponses } = this.props;
        return (
            <AccountSettingsTable
                isLoading={isLoadingSetupSettings}
                onChange={this.onChangeAutoResponses}
                selected={this.state.selectedAutoResponses}
                data={autoResponses} />
        );
    }

    get renderCustomFieldsTable() {
        const { isLoadingSetupSettings, customFields } = this.props;
        return (
            <AccountSettingsTable
                isLoading={isLoadingSetupSettings}
                onChange={this.onChangeCustomFields}
                selected={this.state.selectedCustomFields}
                data={customFields} />
        );
    }

    onSaveSetupSettings = () => {
        const { selectedLabels, selectedRules, selectedTemplates, selectedCustomFields, selectedAutoResponses } = this.state;
        this.props.actions.saveSetupSettings(this.props.companyId, { labelSettings: selectedLabels, ruleSettings: selectedRules, templateSettings: selectedTemplates, customFieldSettings: selectedCustomFields, autoresponceSettings: selectedAutoResponses });
    }

    onChangeLabels = labels => this.setState({ selectedLabels: labels });

    onChangeRules = rules => this.setState({ selectedRules: rules });

    onChangeTemplates = templates => this.setState({ selectedTemplates: templates });

    onChangeAutoResponses = autoResponses => this.setState({ selectedAutoResponses: autoResponses });

    onChangeCustomFields = customFields => this.setState({ selectedCustomFields: customFields });

    render() {
        const { onClose, i18n } = this.props;
        return (
            <Core.Modal
                title={i18n.t('companies.accountSetup')}
                isVisible={true}
                onClose={onClose}
                footer={this.renderFooter}>
                <Core.Collapse data={this.collapseData} />
            </Core.Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    labels: selectors.getCompaniesSetupSettingsLabels(state, ownProps.companyId),
    rules: selectors.getCompaniesSetupSettingsRules(state, ownProps.companyId),
    templates: selectors.getCompaniesSetupSettingsTemplates(state, ownProps.companyId),
    autoResponses: selectors.getCompaniesSetupSettingsAutoResponses(state, ownProps.companyId),
    customFields: selectors.getCompaniesSetupSettingsCustomFields(state, ownProps.companyId),
    isLoadingSetupSettings: selectors.isLoadingSetupSettings(state, ownProps.companyId),
    isLoadingSaveSettings: selectors.isLoadingSaveSetupSettings(state, ownProps.companyId)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});``

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AccountSetupModal));