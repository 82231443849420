import { Col, Row } from 'antd';
import React from 'react';
import { RegistrationsTable } from './RegistrationsTable';
import { RegistrationsChart } from './RegistrationsChart';
import { marginsTop, css } from '../../../../../../styles';

export const RegistrationsBoard = () => {
    return (
        <Row className={css(marginsTop.s4)}>
            <Col span={12}>
                <RegistrationsTable />
            </Col>
            <Col span={12}>
                <RegistrationsChart />
            </Col>
        </Row>
    );
};