import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "../../../core/components";
import { translate } from "../../../core";
import * as selectors from "../../selectors";
import CommentItem from "./CommentItem";

const MessageModal = React.memo(({ onClose, comments, currentUser }) => {
    const commentsRender = () => {
        if (comments.length > 0)
        { return comments.map(comment => (
            <CommentItem
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
            />
        ))
        }
    };

    return (
        <Modal
            onClose={onClose}
            isVisible={true}>
            <div>
                {commentsRender()}
            </div>
        </Modal>
    );
});

const mapStateToProps = state => ({
    comments: selectors.getCommentsNps(state),
});


export default connect(mapStateToProps)(translate()(MessageModal));

MessageModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    comments: PropTypes.array,
    currentUser: PropTypes.object
}

MessageModal.defaultProps = {
    comments: [],
    currentUser: {}
};
