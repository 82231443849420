import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import translate from '../../services/translate';

class PopConfirm extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        children: PropTypes.element.isRequired,
        title: PropTypes.string,
        placement: PropTypes.string
    };

    static defaultProps = {
        onCancel: null,
        title: null,
        placement: "top",
    };

    onConfirm = () => {
        this.props.onConfirm();
    };

    onClick = e => { e.stopPropagation() };

    render() {
        const { onCancel, children, i18n, title, placement } = this.props;
        return (
            <div onClick={this.onClick}>
                <Popconfirm
                    onCancel={onCancel}
                    placement={placement}
                    onConfirm={this.onConfirm}
                    title={title ? title : i18n.t('delete.title')}
                    okText={i18n.t('delete.yes')}
                    cancelText={i18n.t('delete.no')}>
                    {children}
                </Popconfirm>
            </div>
        );
    }
}

export default translate()(PopConfirm);