import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
import { constants as coreConstants } from "../modules/core";
import { actions as authActions, selectors as authSelectors, api as authApi } from "../modules/auth";

export default function configAxios(store) {
    axios.defaults.baseURL = coreConstants.BASE_URL;
    axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    axios.defaults.headers.common.Pragma = 'no-cache';

    axios.interceptors.request.use(async config => {
        const accessToken = authSelectors.getAccessToken(store.getState());
        const expiryDate = authSelectors.getTokenExpiryDate(store.getState());
        if (!accessToken) {
            return updateAuthorizationHeader(config);
        }
        if (config.url !== authApi.endpoints.signIn() && expiryDate && moment().isAfter(expiryDate)) {
            await store.dispatch(authActions.refreshToken());
            const newAccessToken = authSelectors.getAccessToken(store.getState());
            return !newAccessToken ? {
                ...updateAuthorizationHeader(config),
                cancelToken: new axios.CancelToken(cancel => cancel(coreConstants.ERROR_TYPES.REFRESH_TOKEN))
            } : updateAuthorizationHeader(config, newAccessToken);
        }
        return updateAuthorizationHeader(config, accessToken);
    }, error => Promise.reject(error));

    axios.interceptors.response.use(response => response,
        error => {
            if (error && _.get(error, "response.status") === 401) {
                store.dispatch(authActions.signOut());
                throw error;
            }
            if (error && _.get(error, "response.status") === 404) {
                throw new Error(coreConstants.ERROR_TYPES._404);
            }
            throw error;
        }
    )
}

const updateAuthorizationHeader = (config, accessToken = '') => {
    const c = config;
    c.headers.common.Authorization = accessToken ? `bearer ${accessToken}` : '';
    return c;
};