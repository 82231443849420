import React, { useState, useCallback, useRef } from 'react';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import { useTranslation } from '../../services';
import { BUTTON_TYPES } from '../../constants';

function getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve) => {
        canvas.toBlob(blob => {
            resolve(blob);
        }, 'image/png', 1);
    });
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const ImageCropperModal = ({ source, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const img = useRef();
    const [crop, setCrop] = useState({ aspect: 1, width: 100, height: 100 });

    const onConfirmFunc = useCallback(async () => {
        const base64 = await getCroppedImg(img.current, crop);
        onConfirm(base64);
    }, [onConfirm, img, source, crop]);

    const onLoad = e => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 1));
    }

    return (
        <Modal
            title={t('upload')}
            isVisible={true}
            onClose={onClose}
            footer={
                <>
                    <Button
                        type={BUTTON_TYPES.blueOutline}
                        label={t('cancel')}
                        onPress={onClose}
                    />
                    <Button
                        label={t('continue')}
                        onPress={onConfirmFunc}
                    />
                </>
            }>
            <ReactCrop
                crop={crop}
                onChange={setCrop}>
                <img
                    ref={img}
                    src={source}
                    onLoad={onLoad}
                />
            </ReactCrop>
        </Modal>
    );
};

ImageCropperModal.propTypes = {
    source: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default ImageCropperModal;