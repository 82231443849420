import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate, components as Core } from '../../../core';
import { StyleSheet, css, common, spacing, colors } from '../../../../styles';
import { actions as authActions } from "../../../auth";
import RemoveCustomerDataModal from './RemoveCustomerDataModal';
import CreateNewEmailAccountModal from './CreateNewEmailAccountModal';

class Header extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isVisibleEncryptModal: false,
            isVisibleCreateEmailModal: false,
        }
    }

    onLogout = () => {
        this.props.actions.signOut();
    };

    onRemoveCustomerData = () => this.setState({ isVisibleEncryptModal: true });

    onCreateEmail = () => this.setState({ isVisibleCreateEmailModal: true });

    onCloseEncryptModal = () => this.setState({ isVisibleEncryptModal: false });

    onCloseCreateEmailModal = () => this.setState({ isVisibleCreateEmailModal: false });

    render() {
        const { i18n } = this.props;
        const { isVisibleEncryptModal, isVisibleCreateEmailModal } = this.state;
        return (
            <div className={css(common.flexRow, styles.container)}>
                <Core.Logo />
                <div className={css(common.flexRowEnd)}>
                    <p className={css(styles.createEmailButton)} onClick={this.onCreateEmail}>{i18n.t('createNewEmail.createEmail')}</p>
                    <p className={css(styles.removeCustomerDataButton)} onClick={this.onRemoveCustomerData}>{i18n.t('encrypt.encrypt')}</p>
                    <p className={css(styles.logout)} onClick={this.onLogout}>{i18n.t('logout')}</p>
                </div>
                {isVisibleEncryptModal ? <RemoveCustomerDataModal onClose={this.onCloseEncryptModal} /> : null}
                {isVisibleCreateEmailModal ? <CreateNewEmailAccountModal onClose={this.onCloseCreateEmailModal} /> : null}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(authActions, dispatch)
});

export default connect(null, mapDispatchToProps)(translate()(Header));

const styles = StyleSheet.create({
    container: {
        flex: 0,
        backgroundColor: colors.white,
        boxShadow: `0px 1px ${spacing.s0}px ${colors.shadow}`,
        marginBottom: spacing.s0,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    logo: {
        maxHeight: spacing.s9
    },
    logout: {
        color: colors.primary
    },
    removeCustomerDataButton: {
        marginRight: spacing.s7,
        color: colors.primary
    },
    createEmailButton: {
        marginRight: spacing.s7,
        color: colors.primary,
        maxWidth: spacing.s14*2
    }
});