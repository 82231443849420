/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import _ from "lodash";
import { colors, css, fonts, StyleSheet } from '../../../../styles';
import { components as Core, translate, ICONS, constants as coreConstants, getPagination, MARKETPLACES_ENUM } from '../../../core';
import valueBuilder from '../../services/valueBuilder';

export const COLUMNS = {
    marketplace: 'marketplace',
    displayName: 'displayName',
    email: 'email',
    marketplaceMessagesLastSync: 'marketplaceMessagesLastSync',
    marketplaceOrdersLastSync: 'marketplaceOrdersLastSync',
    isBlocked: 'isBlocked',
    needRelogin: 'needRelogin',
    prioritySync: 'prioritySync',
    marketplaceReturnsLastSync: 'marketplaceReturnsLastSync'
};

class IntegrationsTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        integrations: PropTypes.array,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired,
        onChangeTable: PropTypes.func.isRequired,
        setPrioritySync: PropTypes.func,
        onPressRow: PropTypes.func,
        title: PropTypes.string,
        customFilters: PropTypes.element,
        searchText: PropTypes.string.isRequired,
        onChangeSearchText: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired
    };

    static defaultProps = {
        integrations: [],
        isLoading: false,
        title: undefined,
        setPrioritySync: null,
        onPressRow: null,
        customFilters: null
    };

    constructor(props) {
        super(props);
        const { i18n } = this.props;
        this.tableColumns = [{
            title: i18n.t("integrations.companyEmail"),
            key: COLUMNS.email,
            dataIndex: COLUMNS.email,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.marketplace"),
            key: COLUMNS.marketplace,
            dataIndex: COLUMNS.marketplace,
            render: valueBuilder.getMarketplace
        }, {
            title: i18n.t("integrations.name"),
            key: COLUMNS.displayName,
            dataIndex: COLUMNS.displayName
        }, {
            title: i18n.t("integrations.marketplaceMessagesLastSync"),
            key: COLUMNS.marketplaceMessagesLastSync,
            dataIndex: COLUMNS.marketplaceMessagesLastSync,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.marketplaceOrdersLastSync"),
            key: COLUMNS.marketplaceOrdersLastSync,
            render: this.renderOrderLastSync,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.marketplaceReturnsLastSync"),
            key: COLUMNS.marketplaceReturnsLastSync,
            dataIndex: COLUMNS.marketplaceReturnsLastSync,
            render: valueBuilder.getLocalFullTime,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.status"),
            key: COLUMNS.isBlocked,
            dataIndex: COLUMNS.isBlocked,
            render: this.renderStatus,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.needRelogin"),
            key: COLUMNS.needRelogin,
            dataIndex: COLUMNS.needRelogin,
            render: this.renderReloginStatus,
            sorter: true,
            sortDirections: coreConstants.BOTH_SORT_DIRECTIONS
        }, {
            title: i18n.t("integrations.prioritySync"),
            key: COLUMNS.prioritySync,
            render: this.renderPrioritySync
        }];
    }

    get renderRightContent() {
        const { pagination, i18n } = this.props;
        return typeof pagination.maxSyncTime === "number" && (
            <p className={css(styles.syncTime)}>
                {i18n.t('integrations.syncTime')} - <b>{pagination.maxSyncTime}</b> {i18n.t('integrations.min')}
            </p>
        );
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.onChangeTable(pagination, filters, sorter);
    };

    get pagination() {
        return getPagination(this.props.pagination);
    }

    renderOrderLastSync = record => {
        const { i18n } = this.props;
        if (record.marketplace === MARKETPLACES_ENUM.AmazonSP) return null;
        return record.blockedMarketSync ? (
            <div className={css(styles.alignCenter)}>
                <Core.Tooltip title={i18n.t('integrations.isMarketLockedTooltip')}>
                    <Core.Icon type={ICONS.lock} color={colors.red} />
                </Core.Tooltip>
            </div>
        ) : valueBuilder.getLocalFullTime(record.marketplaceOrdersLastSync);
    }

    renderStatus = isBlocked => {
        const { i18n } = this.props;
        return isBlocked ? (
            <Core.Tooltip title={i18n.t('integrations.isLockedTooltip')}>
                <Core.Icon type={ICONS.lock} color={colors.red} />
            </Core.Tooltip>
        ) : (
            <Core.Tooltip title={i18n.t('integrations.isUnlockedTooltip')}>
                <Core.Icon type={ICONS.unlock} color={colors.green} />
            </Core.Tooltip>
        );
    };

    renderReloginStatus = needRelogin => {
        const { i18n } = this.props;
        return needRelogin ?
            <Core.Tooltip title={i18n.t('integrations.needReloginTooltip')}>
                <Core.Icon type={ICONS.lock} color={colors.red} />
            </Core.Tooltip> :
            <Core.Tooltip title={i18n.t('integrations.isUnlockedTooltip')}>
                <Core.Icon type={ICONS.unlock} color={colors.green} />
            </Core.Tooltip>;
    };

    setPrioritySync = integration => {
        this.props.setPrioritySync(integration);
    }

    renderPrioritySync = integration => {
        return !_.get(integration, 'prioritySync') ? (
            <Core.TableAction
                text={this.props.i18n.t("integrations.prioritySync")}
                data={integration}
                onPress={this.setPrioritySync}
                isButton={true}
            />
        ) : null
    };    

    render() {
        const { isLoading, integrations, title, searchText, onSearch, onChangeSearchText } = this.props;
        return (
            <div>
                <Core.Table
                    title={title}
                    columns={this.tableColumns}
                    data={integrations}
                    isLoading={isLoading}
                    pagination={this.pagination}
                    onChange={this.onChangeTable}
                    onPressRow={this.props.onPressRow}
                    rightContent={this.renderRightContent}
                    customFilters={this.props.customFilters}
                    hasCustomFilters={this.props.customFilters !== null}
                    hasSearch={true}
                    searchText={searchText}
                    onSearch={onSearch}
                    onChangeSearchText={onChangeSearchText}
                />
            </div>
        );
    }
}

export default (translate()(IntegrationsTable));

const styles = StyleSheet.create({
    syncTime: {
        ...fonts.lgNormal,
        color: colors.primary
    },
    alignCenter: {
        textAlign: 'center'
    }
});